<h1>{{facilityId?'Edit':'Add'}} {{facility&&facility.type==1?'Gas Plant':''}}{{facility&&facility.type==2?'Power Plant':''}}{{facility&&facility.type==3?'Pipeline':''}}{{facility&&facility.type==4?'Customer':''}}</h1>
<div *ngIf="facility && facility.type==1">
    <form #gasPlantForm="ngForm" name="gasPlantForm" novalidate>
        <mat-expansion-panel [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ADMIN INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="gasplantForm">
                <div class="row">
                    <div class="right textLabel">
                        Owner <br />
                        <select class="textbox stateSelect" [(ngModel)]="gasplant.ownerId" name="ownerId" #ownerId="ngModel">
                            <option value="">Admin</option>
                            <option *ngFor="let corp of corps" value="{{corp.uid}}">{{corp.firstName+' ('+corp.companyName+') '+ (corp.country?'from '+corp.country:'') }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Admin Private <br />
                        <nb-toggle [(ngModel)]="gasplant.isAdminPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                    <div class="left textLabel">
                        Owner Private <br />
                        <nb-toggle [(ngModel)]="gasplant.isOwnerPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="gasplantForm">                
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div *ngIf="!isView">
                            <input type="textbox" class="textbox" placeholder="Facility Name" [(ngModel)]="gasplant.basic.facilityName" name="facilityName" #facilityName="ngModel" required maxlength="100" />
                            <div *ngIf="facilityName.invalid && (facilityName.dirty || facilityName.touched)" class="textbox alert alert-danger">
                                <div *ngIf="facilityName.errors.required">
                                    Facility Name is required
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isView">{{gasplant.basic.facilityName}}</div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <input type="textbox" class="textbox" placeholder="Project Investors/Developers" [(ngModel)]="gasplant.basic.projectInvestors" name="projectInvestors" #projectInvestors="ngModel" maxlength="100" />
                        <div *ngIf="projectInvestors.invalid && (projectInvestors.dirty || projectInvestors.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectInvestors.errors.required">
                                Project Investors/Developers is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <input type="textbox" class="textbox" placeholder="Project Financier" [(ngModel)]="gasplant.basic.projectFinancier" name="projectFinancier" #projectFinancier="ngModel" maxlength="100" />
                        <div *ngIf="projectFinancier.invalid && (projectFinancier.dirty || projectFinancier.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectFinancier.errors.required">
                                Project Financier is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <select class="textbox stateSelect" [(ngModel)]="gasplant.basic.classification" name="classification" #classification="ngModel">
                            <option *ngFor="let a of gasPlantLookups['Gas Plant Classification']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="classification.invalid && (classification.dirty || classification.touched)" class="textbox alert alert-danger">
                            <div *ngIf="classification.errors.required">
                                Classification is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <input type="textbox" class="textbox" placeholder="Owner" [(ngModel)]="gasplant.basic.owner" name="owner" #owner="ngModel" maxlength="100" />
                        <div *ngIf="owner.invalid && (owner.dirty || owner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="owner.errors.required">
                                Owner is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <input type="textbox" class="textbox" placeholder="Date of Transfer to Owner" [nbDatepicker]="dp3" [(ngModel)]="dateTransferOwnerDt" name="dateTransferOwner" #dateTransferOwner="ngModel"  />
                        <nb-datepicker #dp3 format='M/D/yyyy'></nb-datepicker>
                        <div *ngIf="dateTransferOwner.invalid && (dateTransferOwner.dirty || dateTransferOwner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="dateTransferOwner.errors.required">
                                Date of Transfer to Owner is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <input type="textbox" class="textbox" placeholder="LinkedIn Account" [(ngModel)]="gasplant.basic.linkedInAccount" name="linkedInAccount" #linkedInAccount="ngModel" maxlength="250" pattern="(((http(s?)://)?)((([www])*\.)?)|[linkedin])[linkedin/~\-]+\.[a-zA-Z0-9/~\-_,&=\?\.;]+[^\.,\s<]" />
                        <div *ngIf="linkedInAccount.invalid && (linkedInAccount.dirty || linkedInAccount.touched)" class="textbox alert alert-danger">
                            <div *ngIf="linkedInAccount.errors.required">
                                LinkedIn Account is required
                            </div>
                            <div *ngIf="linkedInAccount.errors.pattern">
                                Please enter valid LinkedIn Url!
                            </div>
                        </div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <input type="textbox" class="textbox" placeholder="Company Website" [(ngModel)]="gasplant.basic.companyWebsite" name="companyWebsite" #companyWebsite="ngModel" maxlength="250" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" />
                        <div *ngIf="companyWebsite.invalid && (companyWebsite.dirty || companyWebsite.touched)" class="textbox alert alert-danger">
                            <div *ngIf="companyWebsite.errors.required">
                                Company Website is required
                            </div>
                            <div *ngIf="companyWebsite.errors.pattern">
                                Please enter valid Website Url!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <select class="textbox stateSelect" [(ngModel)]="gasplant.basic.ownerType" name="ownerType" #ownerType="ngModel">
                            <option *ngFor="let a of gasPlantLookups['Gas Plant Owner Type']" value={{a}}>{{a}}</option>
                            
                        </select>
                        <div *ngIf="ownerType.invalid && (ownerType.dirty || ownerType.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="ownerType.errors.required">
                                Owner Type is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <input type="textbox" class="textbox" placeholder="Operator" [(ngModel)]="gasplant.basic.operator" name="operator" #operator="ngModel" maxlength="150" />
                        <div *ngIf="operator.invalid && (operator.dirty || operator.touched)" class="textbox alert alert-danger">
                            <div *ngIf="operator.errors.required">
                                Operator is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <textarea type="textbox" class="textbox" placeholder="Location" [(ngModel)]="gasplant.basic.location" name="location" #location="ngModel" maxlength="500" ></textarea>
                        <div *ngIf="location.invalid && (location.dirty || location.touched)" class="textbox alert alert-danger">
                            <div *ngIf="location.errors.required">
                                Location is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <select class="textbox stateSelect" [(ngModel)]="gasplant.basic.country" name="country" #country="ngModel">
                            <option value="">Select Country</option>
                            <option *ngFor="let a of countries" value="{{a.country}}">{{a.country}}</option>
                        </select>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="country.errors.required">
                                Country is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <input type="textbox" class="textbox" placeholder="GPS Location" [(ngModel)]="gasplant.basic.gpslocation" name="gpslocation" #gpslocation="ngModel" maxlength="150" />
                        <div *ngIf="gpslocation.invalid && (gpslocation.dirty || gpslocation.touched)" class="textbox alert alert-danger">
                            <div *ngIf="gpslocation.errors.required">
                                GPS Location is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <input type="textbox" class="textbox" placeholder="National Regulatory Agency" [(ngModel)]="gasplant.basic.nationalRegulatoryAgency" name="nationalRegulatoryAgency" #nationalRegulatoryAgency="ngModel" maxlength="150" />
                        <div *ngIf="nationalRegulatoryAgency.invalid && (nationalRegulatoryAgency.dirty || nationalRegulatoryAgency.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalRegulatoryAgency.errors.required">
                                National Regulatory Agency is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <input type="textbox" class="textbox" placeholder="National Policy Administrator" [(ngModel)]="gasplant.basic.nationalPolicyAdmin" name="nationalPolicyAdmin" #nationalPolicyAdmin="ngModel" maxlength="150" />
                        <div *ngIf="nationalPolicyAdmin.invalid && (nationalPolicyAdmin.dirty || nationalPolicyAdmin.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalPolicyAdmin.errors.required">
                                National Policy Administrator is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Gas Plant Status <br />
                        <select class="textbox stateSelect" [(ngModel)]="gasplant.basic.gasPlantStatus" name="gasPlantStatus" #gasPlantStatus="ngModel">
                            <option *ngFor="let a of gasPlantLookups['Gas Plant Status']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="gasPlantStatus.invalid && (gasPlantStatus.dirty || gasPlantStatus.touched)" class="textbox alert alert-danger">
                            <div *ngIf="gasPlantStatus.errors.required">
                                Gas Plant Status is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="right textLabel">
                        Region <br />
                        <select class="textbox stateSelect" [(ngModel)]="gasplant.basic.region" name="region" #region="ngModel">
                            <option *ngFor="let a of gasPlantLookups['Gas Plant Region']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="region.invalid && (region.dirty || region.touched)" class="textbox alert alert-danger">
                            <div *ngIf="region.errors.required">
                                Region is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>



        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ADDITIONAL INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Capacity (MMscf)" [(ngModel)]="gasplant.additional.capacity" name="capacity" #capacity="ngModel" />
                    <div *ngIf="capacity.invalid && (capacity.dirty || capacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacity.errors.required">
                            Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    No. of Processing Trainings <br />
                    <input type="number" class="textbox" placeholder="No. of Processing Trainings" [(ngModel)]="gasplant.additional.nosProcessingTraining" name="nosProcessingTraining" #nosProcessingTraining="ngModel" />
                    <div *ngIf="nosProcessingTraining.invalid && (nosProcessingTraining.dirty || nosProcessingTraining.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nosProcessingTraining.errors.required">
                            No. of Processing Trainings is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Capacity of Trainings (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Capacity of Trainings" [(ngModel)]="gasplant.additional.capacityOfTraining" name="capacityOfTraining" #capacityOfTraining="ngModel" />
                    <div *ngIf="capacityOfTraining.invalid && (capacityOfTraining.dirty || capacityOfTraining.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacityOfTraining.errors.required">
                            Capacity of Trainings is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Gas Supply Fields <br />
                    <input type="textbox" class="textbox" placeholder="Gas Supply Fields" [(ngModel)]="gasplant.additional.supplyFields" name="supplyFields" #supplyFields="ngModel" maxlength="150" />
                    <div *ngIf="supplyFields.invalid && (supplyFields.dirty || supplyFields.touched)" class="textbox alert alert-danger">
                        <div *ngIf="supplyFields.errors.required">
                            Gas Supply Fields is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    OMLs <br />
                    <input type="textbox" class="textbox" placeholder="OMLs" [(ngModel)]="gasplant.additional.oml" name="oml" #oml="ngModel" maxlength="150" />
                    <div *ngIf="oml.invalid && (oml.dirty || oml.touched)" class="textbox alert alert-danger">
                        <div *ngIf="oml.errors.required">
                            OMLs is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Gas Reserves Estimates(TCF) <br />
                    <input type="number" class="textbox" placeholder="Gas Reserves Estimates" [(ngModel)]="gasplant.additional.gasReserveEstimates" name="gasReserveEstimates" #gasReserveEstimates="ngModel" />
                    <div *ngIf="gasReserveEstimates.invalid && (gasReserveEstimates.dirty || gasReserveEstimates.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasReserveEstimates.errors.required">
                            Gas Reserves Estimates is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Supply Inlet Pressure (barg) <br />
                    <input type="number" class="textbox" placeholder="Supply Inlet Pressure" [(ngModel)]="gasplant.additional.supplyInletPressure" name="supplyInletPressure" #supplyInletPressure="ngModel" />
                    <div *ngIf="supplyInletPressure.invalid && (supplyInletPressure.dirty || supplyInletPressure.touched)" class="textbox alert alert-danger">
                        <div *ngIf="supplyInletPressure.errors.required">
                            Supply Inlet Pressure is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Delivery Pressure into Pipeline (barg) <br />
                    <input type="number" class="textbox" placeholder="Delivery Pressure into Pipeline" [(ngModel)]="gasplant.additional.deliveryPressurePipeline" name="deliveryPressurePipeline" #deliveryPressurePipeline="ngModel" />
                    <div *ngIf="deliveryPressurePipeline.invalid && (deliveryPressurePipeline.dirty || deliveryPressurePipeline.touched)" class="textbox alert alert-danger">
                        <div *ngIf="deliveryPressurePipeline.errors.required">
                            Delivery Pressure Pipeline is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <input type="textbox" class="textbox" placeholder="EPC Contractor" [(ngModel)]="gasplant.additional.epcContractor" name="epcContractor" #epcContractor="ngModel" maxlength="50" />
                    <div *ngIf="epcContractor.invalid && (epcContractor.dirty || epcContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="epcContractor.errors.required">
                            EPC Contractor is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Construction Start Date  <br />
                    <input type="textbox" class="textbox" placeholder="Construction Start Date" [nbDatepicker]="dp4" [(ngModel)]="constructionStartDt" name="constructionStart" #constructionStart="ngModel"  />
                    <nb-datepicker #dp4 format='M/D/yyyy'></nb-datepicker>           
                    <div *ngIf="constructionStart.invalid && (constructionStart.dirty || constructionStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="constructionStart.errors.required">
                            Construction Start Date is required
                        </div>
                    </div>    
                </div>

                <div class="left textLabel">
                    Construction End Date  <br />
                    <input type="textbox" class="textbox" placeholder="Construction End Date" [nbDatepicker]="dp5" [(ngModel)]="constructionEndDt" name="constructionEnd" #constructionEnd="ngModel"  />
                    <nb-datepicker #dp5 format='M/D/yyyy'></nb-datepicker>    
                    <div *ngIf="constructionEnd.invalid && (constructionEnd.dirty || constructionEnd.touched)" class="textbox alert alert-danger">
                        <div *ngIf="constructionEnd.errors.required">
                            Construction End Date is required
                        </div>
                    </div>            
                </div>

                <div class="right textLabel">
                    Commissioning Date  <br />
                    <input type="textbox" class="textbox" placeholder="Commissioning Date" [nbDatepicker]="dp6" [(ngModel)]="commissionDateDt" name="commissionDate" #commissionDate="ngModel"  />
                    <nb-datepicker #dp6 format='M/D/yyyy'></nb-datepicker>      
                    <div *ngIf="commissionDate.invalid && (commissionDate.dirty || commissionDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="commissionDate.errors.required">
                            Commissioning Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Operation Start Date  <br />
                    <input type="textbox" class="textbox" placeholder="Operation Start Date" [nbDatepicker]="dp7" [(ngModel)]="operationStartDt" name="operationStart" #operationStart="ngModel"  />
                    <nb-datepicker #dp7 format='M/D/yyyy'></nb-datepicker> 
                    <div *ngIf="operationStart.invalid && (operationStart.dirty || operationStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="operationStart.errors.required">
                            Operation Start Date is required
                        </div>
                    </div>               
                </div>
                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <input type="number" class="textbox" placeholder="Estimated Project Cost" [(ngModel)]="gasplant.additional.estimatedProjCost" name="estimatedProjCost" #estimatedProjCost="ngModel" />
                    <div *ngIf="estimatedProjCost.invalid && (estimatedProjCost.dirty || estimatedProjCost.touched)" class="textbox alert alert-danger">
                        <div *ngIf="estimatedProjCost.errors.required">
                            Estimated Project Cost is required
                        </div>
                    </div>
                </div>
                
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <input type="textbox" class="textbox" placeholder="EIA/ESIA Approval Date" [nbDatepicker]="dp8" [(ngModel)]="eiaApprovalDateDt" name="eiaApprovalDate" #eiaApprovalDate="ngModel"  />
                    <nb-datepicker #dp8 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="eiaApprovalDate.invalid && (eiaApprovalDate.dirty || eiaApprovalDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="eiaApprovalDate.errors.required">
                            EIA/ESIA Approval Date is required
                        </div>
                    </div>                 
                </div>

                <div class="right textLabel">
                    O&M Operator  <br />
                    <input type="textbox" class="textbox" placeholder="O&M Operator" [(ngModel)]="gasplant.additional.onmOperator" name="qnmOperator" #qnmOperator="ngModel" maxlength="50" />
                    <div *ngIf="qnmOperator.invalid && (qnmOperator.dirty || qnmOperator.touched)" class="textbox alert alert-danger">
                        <div *ngIf="qnmOperator.errors.required">
                            O&M Operator is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Gas Plant Type <br />
                    <select class="textbox stateSelect" [(ngModel)]="gasplant.additional.gasplantType" name="gasplantType" #gasplantType="ngModel">
                        <option *ngFor="let a of gasPlantLookups['Gas Plant Type']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="gasplantType.invalid && (gasplantType.dirty || gasplantType.touched)" class="textbox selectErr alert alert-danger">
                        <div *ngIf="gasplantType.errors.required">
                            Gas Plant Type is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Gas Plant Output <br />
                    <select class="textbox stateSelect" [(ngModel)]="gasplant.additional.gasplantOutput" name="gasplantOutput" #gasplantOutput="ngModel">
                        <option *ngFor="let a of gasPlantLookups['Gas Plant Output']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="gasplantOutput.invalid && (gasplantOutput.dirty || gasplantOutput.touched)" class="textbox selectErr alert alert-danger">
                        <div *ngIf="gasplantOutput.errors.required">
                            Gas Plant Output is required
                        </div>
                    </div>
                </div>
                
                <div class="left textLabel">
                    Target Market <br />
                    <select class="textbox stateSelect" [(ngModel)]="gasplant.additional.targetMarket" name="targetMarket" #targetMarket="ngModel">
                        <option *ngFor="let a of gasPlantLookups['Gas Plant Target Market']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="targetMarket.invalid && (targetMarket.dirty || targetMarket.touched)" class="textbox selectErr alert alert-danger">
                        <div *ngIf="gasplantOutput.errors.required">
                            Target Market is required
                        </div>
                    </div>
                </div>

                <div class="right textLabel">
                    Export Pipeline  <br />
                    <input type="textbox" class="textbox" placeholder="Export Pipeline" [(ngModel)]="gasplant.additional.exportPipeline" name="exportPipeline" #exportPipeline="ngModel" maxlength="100" />
                    <div *ngIf="exportPipeline.invalid && (exportPipeline.dirty || exportPipeline.touched)" class="textbox alert alert-danger">
                        <div *ngIf="exportPipeline.errors.required">
                            Export Pipeline is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Size of Export Pipeline  <br />
                    <input type="textbox" class="textbox" placeholder="Size of Export Pipeline" [(ngModel)]="gasplant.additional.sizeExportPipeline" name="sizeExportPipeline" #sizeExportPipeline="ngModel" />
                    <div *ngIf="sizeExportPipeline.invalid && (sizeExportPipeline.dirty || sizeExportPipeline.touched)" class="textbox alert alert-danger">
                        <div *ngIf="sizeExportPipeline.errors.required">
                            Size of Export Pipeline is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Gas Plant Processing Technology <br />
                    <select class="textbox stateSelect" [(ngModel)]="gasplant.additional.processingTech" name="processingTech" #processingTech="ngModel">
                        <option *ngFor="let a of gasPlantLookups['Gas Plant Processing Technology']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="processingTech.invalid && (processingTech.dirty || processingTech.touched)" class="textbox selectErr alert alert-danger">
                        <div *ngIf="processingTech.errors.required">
                            Gas Plant Processing Tech is required
                        </div>
                    </div>
                </div>
                
                <div class="left textLabel">
                    Notes  <br />
                    <textarea type="textbox" class="textbox" placeholder="Notes" [(ngModel)]="gasplant.additional.notes" name="notes" #notes="ngModel"></textarea>
                    <div *ngIf="notes.invalid && (notes.dirty || notes.touched)" class="textbox alert alert-danger">
                        <div *ngIf="notes.errors.required">
                            Notes is required
                        </div>
                    </div>
                </div>

                <div class="right textLabel">
                    Profile  <br />
                    <textarea type="textbox" class="textbox" placeholder="Profile" [(ngModel)]="gasplant.additional.profile" name="profile" #profile="ngModel"></textarea>
                    <div *ngIf="profile.invalid && (profile.dirty || profile.touched)" class="textbox alert alert-danger">
                        <div *ngIf="profile.errors.required">
                            Profile is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Passcode <br />
                    <input type="textbox" class="textbox" placeholder="Passcode" [(ngModel)]="gasplant.passcode" name="passcode" #passcode="ngModel" required />
                    <div *ngIf="passcode.invalid && (passcode.dirty || passcode.touched)" class="textbox alert alert-danger">
                        <div *ngIf="passcode.errors.required"> 
                            Passcode is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <div class="textLabel" >
            &nbsp; <br />
            <button type="submit" class="button blueButton" [disabled]="gasPlantForm.invalid || isSubmitted" (click)="saveFacility('1')" value="Save" ><span *ngIf="!isSubmitted">Save</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
            <input type="submit" class="button whiteButton grey" [disabled]="isSubmitted" (click)="navigate()" value="Cancel" />
        </div>
    
    </form>
</div>

<div *ngIf="facility && facility.type==2">
    <form #powerPlantForm="ngForm" name="powerPlantForm" novalidate>
        <mat-expansion-panel [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="powerplantForm">
                <div class="row">
                    <div class="right textLabel">
                        Owner <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.ownerId" name="ownerId" #ownerId="ngModel">
                            <option value="">Admin</option>
                            <option *ngFor="let corp of corps" value="{{corp.uid}}">{{corp.firstName+'('+corp.companyName+')'}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Admin Private <br />
                        <nb-toggle [(ngModel)]="powerplant.isAdminPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                    <div class="left textLabel">
                        Owner Private <br />
                        <nb-toggle [(ngModel)]="powerplant.isOwnerPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="powerplantForm">                
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <input type="textbox" class="textbox" placeholder="Facility Name" [(ngModel)]="powerplant.basic.facilityName" name="facilityName" #facilityName="ngModel" required maxlength="100" />
                        <div *ngIf="facilityName.invalid && (facilityName.dirty || facilityName.touched)" class="textbox alert alert-danger">
                            <div *ngIf="facilityName.errors.required">
                                Facility Name is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <input type="textbox" class="textbox" placeholder="Project Investors/Developers" [(ngModel)]="powerplant.basic.projectInvestors" name="projectInvestors" #projectInvestors="ngModel" maxlength="150" />
                        <div *ngIf="projectInvestors.invalid && (projectInvestors.dirty || projectInvestors.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectInvestors.errors.required">
                                Project Investors/Developers is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <input type="textbox" class="textbox" placeholder="Project Financier" [(ngModel)]="powerplant.basic.projectFinancier" name="projectFinancier" #projectFinancier="ngModel" maxlength="150" />
                        <div *ngIf="projectFinancier.invalid && (projectFinancier.dirty || projectFinancier.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectFinancier.errors.required">
                                Project Financier is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.basic.classification" name="classification" #classification="ngModel">
                            <option *ngFor="let a of powerPlantLookups['Power Plant Classification']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="classification.invalid && (classification.dirty || classification.touched)" class="textbox alert alert-danger">
                            <div *ngIf="classification.errors.required">
                                Classification is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <input type="textbox" class="textbox" placeholder="Owner" [(ngModel)]="powerplant.basic.owner" name="owner" #owner="ngModel" maxlength="150" />
                        <div *ngIf="owner.invalid && (owner.dirty || owner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="owner.errors.required">
                                Owner is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <input type="textbox" class="textbox" placeholder="Date of Transfer to Owner" [nbDatepicker]="dp3" [(ngModel)]="dateTransferOwnerDt" name="dateTransferOwner" #dateTransferOwner="ngModel"  />
                        <nb-datepicker #dp3 format='M/D/yyyy'></nb-datepicker>
                        <div *ngIf="dateTransferOwner.invalid && (dateTransferOwner.dirty || dateTransferOwner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="dateTransferOwner.errors.required">
                                Date of Transfer to Owner is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <input type="textbox" class="textbox" placeholder="LinkedIn Account" [(ngModel)]="powerplant.basic.linkedInAccount" name="linkedInAccount" #linkedInAccount="ngModel" maxlength="250" pattern="(((http(s?)://)?)((([www])*\.)?)|[linkedin])[linkedin/~\-]+\.[a-zA-Z0-9/~\-_,&=\?\.;]+[^\.,\s<]" />
                        <div *ngIf="linkedInAccount.invalid && (linkedInAccount.dirty || linkedInAccount.touched)" class="textbox alert alert-danger">
                            <div *ngIf="linkedInAccount.errors.required">
                                LinkedIn Account is required
                            </div>
                            <div *ngIf="linkedInAccount.errors.pattern">
                                Please enter valid LinkedIn Url!
                            </div>
                        </div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <input type="textbox" class="textbox" placeholder="Company Website" [(ngModel)]="powerplant.basic.companyWebsite" name="companyWebsite" #companyWebsite="ngModel" maxlength="250" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" />
                        <div *ngIf="companyWebsite.invalid && (companyWebsite.dirty || companyWebsite.touched)" class="textbox alert alert-danger">
                            <div *ngIf="companyWebsite.errors.required">
                                Company Website is required
                            </div>
                            <div *ngIf="companyWebsite.errors.pattern">
                                Please enter valid Website Url!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.basic.ownerType" name="ownerType" #ownerType="ngModel">
                            <option *ngFor="let a of powerPlantLookups['Power Plant Owner Type']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="ownerType.invalid && (ownerType.dirty || ownerType.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="ownerType.errors.required">
                                Owner Type is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <input type="textbox" class="textbox" placeholder="Operator" [(ngModel)]="powerplant.basic.operator" name="operator" #operator="ngModel" maxlength="150" />
                        <div *ngIf="operator.invalid && (operator.dirty || operator.touched)" class="textbox alert alert-danger">
                            <div *ngIf="operator.errors.required">
                                Operator Type is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <textarea type="textbox" class="textbox" placeholder="Location" [(ngModel)]="powerplant.basic.location" name="location" #location="ngModel" maxlength="500" ></textarea>
                        <div *ngIf="location.invalid && (location.dirty || location.touched)" class="textbox alert alert-danger">
                            <div *ngIf="location.errors.required">
                                Location Type is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.basic.country" name="country" #country="ngModel">
                            <option value="">Select Country</option>
                            <option *ngFor="let a of countries" value="{{a.country}}">{{a.country}}</option>
                        </select>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="country.errors.required">
                                Country is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <input type="textbox" class="textbox" placeholder="GPS Location" [(ngModel)]="powerplant.basic.gpslocation" name="gpslocation" #gpslocation="ngModel" maxlength="150" />
                        <div *ngIf="gpslocation.invalid && (gpslocation.dirty || gpslocation.touched)" class="textbox alert alert-danger">
                            <div *ngIf="gpslocation.errors.required">
                                GPS Location is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <input type="textbox" class="textbox" placeholder="National Regulatory Agency" [(ngModel)]="powerplant.basic.nationalRegulatoryAgency" name="nationalRegulatoryAgency" #nationalRegulatoryAgency="ngModel" maxlength="150" />
                        <div *ngIf="nationalRegulatoryAgency.invalid && (nationalRegulatoryAgency.dirty || nationalRegulatoryAgency.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalRegulatoryAgency.errors.required">
                                National Regulatory Agency is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <input type="textbox" class="textbox" placeholder="National Policy Administrator" [(ngModel)]="powerplant.basic.nationalPolicyAdmin" name="nationalPolicyAdmin" #nationalPolicyAdmin="ngModel" maxlength="150" />
                        <div *ngIf="nationalPolicyAdmin.invalid && (nationalPolicyAdmin.dirty || nationalPolicyAdmin.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalPolicyAdmin.errors.required">
                                National Policy Administrator is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Type <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.basic.powerPlantType" name="powerPlantType" #powerPlantType="ngModel">
                            <option *ngFor="let a of powerPlantLookups['Power Plant Type']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="powerPlantType.invalid && (powerPlantType.dirty || powerPlantType.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="powerPlantType.errors.required">
                                Type is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Power Plant Status <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.basic.powerPlantStatus" name="powerPlantStatus" #powerPlantStatus="ngModel">
                            <option *ngFor="let a of powerPlantLookups['Power Plant Status']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="powerPlantStatus.invalid && (powerPlantStatus.dirty || powerPlantStatus.touched)" class="textbox alert alert-danger">
                            <div *ngIf="powerPlantStatus.errors.required">
                                Power Plant Status is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Region <br />
                        <select class="textbox stateSelect" [(ngModel)]="powerplant.basic.region" name="region" #region="ngModel">
                            <option *ngFor="let a of powerPlantLookups['Power Plant Region']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="region.invalid && (region.dirty || region.touched)" class="textbox alert alert-danger">
                            <div *ngIf="region.errors.required">
                                Region is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>



        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity (MW) <br />
                    <input type="number" class="textbox" placeholder="Capacity (MW)" [(ngModel)]="powerplant.designSpecs.capacity" name="capacity" #capacity="ngModel" />
                    <div *ngIf="capacity.invalid && (capacity.dirty || capacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacity.errors.required">
                            Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Net Eletrical Out (MW) <br />
                    <input type="number" class="textbox" placeholder="Net Eletrical Out (MW)" [(ngModel)]="powerplant.designSpecs.netElectricalOut" name="netElectricalOut" #netElectricalOut="ngModel" />
                    <div *ngIf="netElectricalOut.invalid && (netElectricalOut.dirty || netElectricalOut.touched)" class="textbox alert alert-danger">
                        <div *ngIf="netElectricalOut.errors.required">
                            Net Eletrical Out is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    No. of Gas Turbines <br />
                    <input type="number" class="textbox" placeholder="No. of Gas Turbines" [(ngModel)]="powerplant.designSpecs.nosOfGasTurbines" name="nosOfGasTurbines" #nosOfGasTurbines="ngModel" />
                    <div *ngIf="nosOfGasTurbines.invalid && (nosOfGasTurbines.dirty || nosOfGasTurbines.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nosOfGasTurbines.errors.required">
                            No. of Gas Turbines is required
                        </div>
                    </div>
                </div>

                <div class="right textLabel">
                    Gas Requirements (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Gas Requirements (MMscf)" [(ngModel)]="powerplant.designSpecs.gasRequirements" name="gasRequirements" #gasRequirements="ngModel" />
                    <div *ngIf="gasRequirements.invalid && (gasRequirements.dirty || gasRequirements.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasRequirements.errors.required">
                            Gas Requirements is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    No. of Steam Turbines <br />
                    <input type="number" class="textbox" placeholder="No. of Steam Turbines" [(ngModel)]="powerplant.designSpecs.nosOfSteamTurbines" name="nosOfSteamTurbines" #nosOfSteamTurbines="ngModel" />
                    <div *ngIf="nosOfSteamTurbines.invalid && (nosOfSteamTurbines.dirty || nosOfSteamTurbines.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nosOfSteamTurbines.errors.required">
                            No. of Steam Turbines is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Capacity of Steam Trubines (MW) <br />
                    <input type="number" class="textbox" placeholder="Capacity of Steam Trubines" [(ngModel)]="powerplant.designSpecs.capacitySteamTurbine" name="capacitySteamTurbine" #capacitySteamTurbine="ngModel" />
                    <div *ngIf="capacitySteamTurbine.invalid && (capacitySteamTurbine.dirty || capacitySteamTurbine.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacitySteamTurbine.errors.required">
                            Capacity of Steam Turbines is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Capacity of Gas Turbines (MW) <br />
                    <input type="number" class="textbox" placeholder="Capacity of Gas Turbines" [(ngModel)]="powerplant.designSpecs.capacityOfGasTurbines" name="capacityOfGasTurbines" #capacityOfGasTurbines="ngModel" />
                    <div *ngIf="capacityOfGasTurbines.invalid && (capacityOfGasTurbines.dirty || capacityOfGasTurbines.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacityOfGasTurbines.errors.required">
                            Capacity of Gas Turbines is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Gas Turbines OEM <br />
                    <input type="textbox" class="textbox" placeholder="Gas Turbines OEM" [(ngModel)]="powerplant.designSpecs.gasTurbinesOEM" name="gasTurbinesOEM" #gasTurbinesOEM="ngModel" maxlength="50" />
                    <div *ngIf="gasTurbinesOEM.invalid && (gasTurbinesOEM.dirty || gasTurbinesOEM.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasTurbinesOEM.errors.required">
                            Gas Turbines ORM is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Steam Turbines OEM <br />
                    <input type="textbox" class="textbox" placeholder="Steam Turbines OEM" [(ngModel)]="powerplant.designSpecs.steamTurbinesOEM" name="steamTurbinesOEM" #steamTurbinesOEM="ngModel" maxlength="50" />
                    <div *ngIf="steamTurbinesOEM.invalid && (steamTurbinesOEM.dirty || steamTurbinesOEM.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steamTurbinesOEM.errors.required">
                            Steam Turbines OEM is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Capacity of Black Start Generator  <br />
                    <input type="number" class="textbox" placeholder="Capacity of Black Start Generator" [(ngModel)]="powerplant.designSpecs.capacityOfBlackStart" name="capacityOfBlackStart" #capacityOfBlackStart="ngModel"  />
                    <div *ngIf="capacityOfBlackStart.invalid && (capacityOfBlackStart.dirty || capacityOfBlackStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacityOfBlackStart.errors.required">
                            Capacity of Black Start is required
                        </div>
                    </div>    
                </div>

                <div class="left textLabel">
                    Black Start Fuel  <br />
                    <input type="textbox" class="textbox" placeholder="Black Start Fuel" [(ngModel)]="powerplant.designSpecs.blackStartFuel" name="blackStartFuel" #blackStartFuel="ngModel" maxlength="150"  />
                    
                    <div *ngIf="blackStartFuel.invalid && (blackStartFuel.dirty || blackStartFuel.touched)" class="textbox alert alert-danger">
                        <div *ngIf="blackStartFuel.errors.required">
                            Black Start Fuel is required
                        </div>
                    </div>            
                </div>

                <div class="right textLabel">
                    Regulatory License Date  <br />
                    <input type="textbox" class="textbox" placeholder="Regulatory License Date" [nbDatepicker]="dp4" [(ngModel)]="regulatoryLicenseDateDt" name="regulatoryLicenseDate" #regulatoryLicenseDate="ngModel"  />
                    <nb-datepicker #dp4 format='M/D/yyyy'></nb-datepicker>       
                    <div *ngIf="regulatoryLicenseDate.invalid && (regulatoryLicenseDate.dirty || regulatoryLicenseDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="regulatoryLicenseDate.errors.required">
                            Regulatory License Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                   Target Customer <br />
                    <select class="textbox stateSelect" [(ngModel)]="powerplant.designSpecs.targetCustomer" name="targetCustomer" #targetCustomer="ngModel">
                        <option *ngFor="let a of powerPlantLookups['Power Plant Target Customer']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="targetCustomer.invalid && (targetCustomer.dirty || targetCustomer.touched)" class="textbox alert alert-danger">
                        <div *ngIf="targetCustomer.errors.required">
                            Target Customer is required
                        </div>
                    </div>               
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT MANAGEMENT
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <input type="textbox" class="textbox" placeholder="EPC Contractor" [(ngModel)]="powerplant.projectManagement.epcContractor" name="epcContractor" #epcContractor="ngModel" maxlength="50" />
                    <div *ngIf="epcContractor.invalid && (epcContractor.dirty || epcContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="epcContractor.errors.required">
                            EPC Contractor is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Project Execution Consultant <br />
                    <input type="textbox" class="textbox" placeholder="Project Execution Consultant" [(ngModel)]="powerplant.projectManagement.projectExecutionConsultant" name="projectExecutionConsultant" #projectExecutionConsultant="ngModel" maxlength="50" />
                    <div *ngIf="projectExecutionConsultant.invalid && (projectExecutionConsultant.dirty || projectExecutionConsultant.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectExecutionConsultant.errors.required">
                            Project Execution Consultant is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <input type="number" class="textbox" placeholder="Estimated Project Cost" [(ngModel)]="powerplant.projectManagement.estimatedProjCost" name="estimatedProjCost" #estimatedProjCost="ngModel" />
                    <div *ngIf="estimatedProjCost.invalid && (estimatedProjCost.dirty || estimatedProjCost.touched)" class="textbox alert alert-danger">
                        <div *ngIf="estimatedProjCost.errors.required">
                            Estimated Project Cost is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Project Start Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project Start Date" [nbDatepicker]="dp5" [(ngModel)]="projectStartDateDt" name="projectStartDate" #projectStartDate="ngModel"  />
                    <nb-datepicker #dp5 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="projectStartDate.invalid && (projectStartDate.dirty || projectStartDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectStartDate.errors.required">
                            Project Start Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row margin">
                <div class="left textLabel">
                    Project End Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project End Date" [nbDatepicker]="dp6" [(ngModel)]="projectEndDateDt" name="projectEndDate" #projectEndDate="ngModel"  />
                    <nb-datepicker #dp6 format='M/D/yyyy'></nb-datepicker>  
                    <div *ngIf="projectEndDate.invalid && (projectEndDate.dirty || projectEndDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectEndDate.errors.required">
                            Project End Date is required
                        </div>
                    </div>        
                </div>
                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project Commissioning Date" [nbDatepicker]="dp7" [(ngModel)]="projectCommissioningDateDt" name="projectCommissioningDate" #projectCommissioningDate="ngModel"  />
                    <nb-datepicker #dp7 format='M/D/yyyy'></nb-datepicker>  
                    <div *ngIf="projectCommissioningDate.invalid && (projectCommissioningDate.dirty || projectCommissioningDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectCommissioningDate.errors.required">
                            Project Commissioning Date is required
                        </div>
                    </div>        
                </div>
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <input type="textbox" class="textbox" placeholder="EIA/ESIA Approval Date" [nbDatepicker]="dp8" [(ngModel)]="eiaApprovalDateDt" name="eiaApprovalDate" #eiaApprovalDate="ngModel"  />
                    <nb-datepicker #dp8 format='M/D/yyyy'></nb-datepicker>    
                    <div *ngIf="eiaApprovalDate.invalid && (eiaApprovalDate.dirty || eiaApprovalDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="eiaApprovalDate.errors.required">
                            EIA/ESIA Approval Date is required
                        </div>
                    </div>        
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMISSIONING
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Certificate of Readiness <br />
                    <input type="textbox" class="textbox" placeholder="Certificate of Readiness" [(ngModel)]="powerplant.commissioning.certificateOfReadiness" name="certificateOfReadiness" #certificateOfReadiness="ngModel" maxlength="100" />
                    <div *ngIf="certificateOfReadiness.invalid && (certificateOfReadiness.dirty || certificateOfReadiness.touched)" class="textbox alert alert-danger">
                        <div *ngIf="certificateOfReadiness.errors.required">
                            Certificate of Readiness is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project Commissioning Date" [nbDatepicker]="dp9" [(ngModel)]="commissionDateDt" name="commissionDate" #commissionDate="ngModel"  />
                    <nb-datepicker #dp9 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="commissionDate.invalid && (commissionDate.dirty || commissionDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="commissionDate.errors.required">
                            Project Commissioning Date is required
                        </div>
                    </div>        
                </div>

                <div class="left textLabel">
                    Commissioning Test Certificate <br />
                    <input type="radio" id="commissionTestCertificateYes" class="radio" value="yes" [(ngModel)]="powerplant.commissioning.commissionTestCertificate" name="commissionTestCertificate" #commissionTestCertificate="ngModel" />
                    <label for="commissionTestCertificateYes">Yes</label>
                    <input type="radio" id="commissionTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commissioning.commissionTestCertificate" name="commissionTestCertificate" #commissionTestCertificate="ngModel" />
                    <label for="commissionTestCertificateNo">No</label>
                </div>

                <div class="right textLabel">
                    Commissioning Certificate for Sync <br />
                    <input type="radio" id="commissionCertificateSyncYes" class="radio" value="yes" [(ngModel)]="powerplant.commissioning.commissionCertificateSync" name="commissionCertificateSync" />
                    <label for="commissionCertificateSyncYes">Yes</label>
                    <input type="radio" id="commissionCertificateSyncNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commissioning.commissionCertificateSync" name="commissionCertificateSync" />
                    <label for="commissionCertificateSyncNo">No</label>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Turbine Performance Test Certificate <br />
                    <input type="radio" id="turbinePerformanceTestCertificateYes" class="radio" value="yes" [(ngModel)]="powerplant.commissioning.turbinePerformanceTestCertificate" name="turbinePerformanceTestCertificate" />
                    <label for="turbinePerformanceTestCertificateYes">Yes</label>
                    <input type="radio" id="turbinePerformanceTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commissioning.turbinePerformanceTestCertificate" name="turbinePerformanceTestCertificate" />
                    <label for="turbinePerformanceTestCertificateNo">No</label>
                </div>

                <div class="left textLabel">
                    Reliability Run Test Certificate <br />
                    <input type="radio" id="reliabilityRunTestCertificateYes" class="radio" value="yes" [(ngModel)]="powerplant.commissioning.reliabilityRunTestCertificate" name="reliabilityRunTestCertificate" />
                    <label for="reliabilityRunTestCertificateYes">Yes</label>
                    <input type="radio" id="reliabilityRunTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commissioning.reliabilityRunTestCertificate" name="reliabilityRunTestCertificate" />
                    <label for="reliabilityRunTestCertificateNo">No</label>
                </div>

                <div class="right textLabel">
                    Capacity Test Certificate <br />
                    <input type="radio" id="capacityTestCertificateYes" class="radio" value="yes" [(ngModel)]="powerplant.commissioning.capacityTestCertificate" name="capacityTestCertificate" />
                    <label for="capacityTestCertificateYes">Yes</label>
                    <input type="radio" id="capacityTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commissioning.capacityTestCertificate" name="capacityTestCertificate" />
                    <label for="capacityTestCertificateNo">No</label>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PLANT OPERATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    O&M Contractor Name<br />
                    <input type="textbox" class="textbox" placeholder="O&M Contractor Name" [(ngModel)]="powerplant.plantOperations.onmContractor" name="qnmContractor" #qnmContractor="ngModel" maxlength="50" />
                    <div *ngIf="qnmContractor.invalid && (qnmContractor.dirty || qnmContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="qnmContractor.errors.required">
                            O&M Contractor Name is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                   Operations Start Date  <br />
                    <input type="textbox" class="textbox" placeholder="Operations Start Date" [nbDatepicker]="dp10" [(ngModel)]="operationStartDt" name="operationStart" #operationStart="ngModel"  />
                    <nb-datepicker #dp10 format='M/D/yyyy'></nb-datepicker>      
                    <div *ngIf="operationStart.invalid && (operationStart.dirty || operationStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="operationStart.errors.required">
                            Operations Start Date is required
                        </div>
                    </div>        
                </div>

                <div class="left textLabel">
                    Name of LTSA Contractor <br />
                    <input type="textbox" class="textbox" placeholder="Name of LTSA Contractor" [(ngModel)]="powerplant.plantOperations.nameOfLTSAContractor" name="nameOfLTSAContractor" #nameOfLTSAContractor="ngModel" maxlength="50" />
                    <div *ngIf="nameOfLTSAContractor.invalid && (nameOfLTSAContractor.dirty || nameOfLTSAContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nameOfLTSAContractor.errors.required">
                            Name of LTSA Contractor is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Turbine A B&C Inspections <br />
                    <input type="textbox" class="textbox" placeholder="Turbine A B&C Inspections" [(ngModel)]="powerplant.plantOperations.plantInspections" name="turbineABCInspections" #turbineABCInspections="ngModel" maxlength="50" />
                    <div *ngIf="turbineABCInspections.invalid && (turbineABCInspections.dirty || turbineABCInspections.touched)" class="textbox alert alert-danger">
                        <div *ngIf="turbineABCInspections.errors.required">
                            Turbine A B&C Inspections is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    CMMS Computerized Mtce Mgr<br />
                    <input type="textbox" class="textbox" placeholder="CMMS Computerized Mtce Mgr" [(ngModel)]="powerplant.plantOperations.cmmsComputerizedMtceMgr" name="cmmsComputerizedMtceMgr" #cmmsComputerizedMtceMgr="ngModel" maxlength="50" />
                    <div *ngIf="cmmsComputerizedMtceMgr.invalid && (cmmsComputerizedMtceMgr.dirty || cmmsComputerizedMtceMgr.touched)" class="textbox alert alert-danger">
                        <div *ngIf="cmmsComputerizedMtceMgr.errors.required">
                            CMMS Mtce Mgr is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Plant Availability Capacity(MW) <br />
                    <input type="number" class="textbox" placeholder="Plant Availability Capacity" [(ngModel)]="powerplant.plantOperations.plantAvailabilityCapacity" name="plantAvailabilityCapacity" #plantAvailabilityCapacity="ngModel" />
                    <div *ngIf="plantAvailabilityCapacity.invalid && (plantAvailabilityCapacity.dirty || plantAvailabilityCapacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="plantAvailabilityCapacity.errors.required">
                            Plant Availability Capacity is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Plant Rating(MW) <br />
                    <input type="number" class="textbox" placeholder="Plant Rating" [(ngModel)]="powerplant.plantOperations.plantRating" name="plantRating" #plantRating="ngModel" />
                    <div *ngIf="plantRating.invalid && (plantRating.dirty || plantRating.touched)" class="textbox alert alert-danger">
                        <div *ngIf="plantRating.errors.required">
                            Plant Rating is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Terminal Voltage (KV) <br /> 
                    <input type="number" class="textbox" placeholder="Terminal Voltage" [(ngModel)]="powerplant.plantOperations.terminalVoltage" name="terminalVoltage" #terminalVoltage="ngModel" />
                    <div *ngIf="terminalVoltage.invalid && (terminalVoltage.dirty || terminalVoltage.touched)" class="textbox alert alert-danger">
                        <div *ngIf="terminalVoltage.errors.required">
                            Terminal Voltage is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Unit Efficiency (%) <br />
                    <input type="number" class="textbox" placeholder="Unit Efficiency" [(ngModel)]="powerplant.plantOperations.unitEfficiency" name="unitEfficiency" #unitEfficiency="ngModel" pattern="^[1-9][0-9]?$|^100$" />
                    <div *ngIf="unitEfficiency.invalid && (unitEfficiency.dirty || unitEfficiency.touched)" class="textbox alert alert-danger">
                        <div *ngIf="unitEfficiency.errors.required">
                            Unit Efficiency is required
                        </div>
                        <div *ngIf="unitEfficiency.errors.pattern">
                            Unit Efficiency can be b/w 1-100
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Reactive Power Requirement (MW) <br />
                    <input type="number" class="textbox" placeholder="Reactive Power Requirement" [(ngModel)]="powerplant.plantOperations.reactivePowerRequirement" name="reactivePowerRequirement" #reactivePowerRequirement="ngModel" />
                    <div *ngIf="reactivePowerRequirement.invalid && (reactivePowerRequirement.dirty || reactivePowerRequirement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="reactivePowerRequirement.errors.required">
                            Reactive Power Requirement is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Short Circuit Ratio <br />
                    <input type="textbox" class="textbox" placeholder="Short Circuit Ratio" [(ngModel)]="powerplant.plantOperations.shortCircuitRatio" name="shortCircuitRatio" #shortCircuitRatio="ngModel" maxlength="50" />
                    <div *ngIf="shortCircuitRatio.invalid && (shortCircuitRatio.dirty || shortCircuitRatio.touched)" class="textbox alert alert-danger">
                        <div *ngIf="shortCircuitRatio.errors.required">
                            Short Circuit Ratio is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Auxiliary Power Requirement (MW) <br /> 
                    <input type="number" class="textbox" placeholder="Auxiliary Power Requirement" [(ngModel)]="powerplant.plantOperations.auxilliaryPowerRequirement" name="auxilliaryPowerRequirement" #auxilliaryPowerRequirement="ngModel" />
                    <div *ngIf="auxilliaryPowerRequirement.invalid && (auxilliaryPowerRequirement.dirty || auxilliaryPowerRequirement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="auxilliaryPowerRequirement.errors.required">
                            Auxiliary Power Requirement is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Transformer Rated Capacity (MVA) <br />
                    <input type="number" class="textbox" placeholder="Transformer Rated Capacity" [(ngModel)]="powerplant.plantOperations.trasnformerRatedCapacity" name="trasnformerRatedCapacity" #trasnformerRatedCapacity="ngModel" />
                    <div *ngIf="trasnformerRatedCapacity.invalid && (trasnformerRatedCapacity.dirty || trasnformerRatedCapacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="trasnformerRatedCapacity.errors.required">
                            Transformer Rated Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Transformer Voltage Load Ratio(HV/LV) <br />
                    <input type="number" class="textbox" placeholder="Transformer Voltage Load Ratio" [(ngModel)]="powerplant.plantOperations.transformerVoltageLoadRatio" name="transformerVoltageLoadRatio" #transformerVoltageLoadRatio="ngModel" />
                    <div *ngIf="transformerVoltageLoadRatio.invalid && (transformerVoltageLoadRatio.dirty || transformerVoltageLoadRatio.touched)" class="textbox alert alert-danger">
                        <div *ngIf="transformerVoltageLoadRatio.errors.required">
                            Transformer Volt Load Ratio is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Steam Turbine Pressure (PSI) <br />
                    <input type="number" class="textbox" placeholder="Plant Pressure" [(ngModel)]="powerplant.plantOperations.steamTurbinePressure" name="steamTurbinePressure" #steamTurbinePressure="ngModel" />
                    <div *ngIf="steamTurbinePressure.invalid && (steamTurbinePressure.dirty || steamTurbinePressure.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steamTurbinePressure.errors.required">
                            Plant Pressure is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Steam Turbine Temperature <br /> 
                    <input type="number" class="textbox" placeholder="Plant Temperature" [(ngModel)]="powerplant.plantOperations.steamTurbineTemprature" name="steamTurbineTemprature" #steamTurbineTemprature="ngModel" />
                    <div *ngIf="steamTurbineTemprature.invalid && (steamTurbineTemprature.dirty || steamTurbineTemprature.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steamTurbineTemprature.errors.required">
                            Plant Temperature is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant Noise Level (dBA) <br />
                    <input type="number" class="textbox" placeholder="Plant Noise Level" [(ngModel)]="powerplant.plantOperations.plantNoiseLevel" name="plantNoiseLevel" #plantNoiseLevel="ngModel" />
                    <div *ngIf="plantNoiseLevel.invalid && (plantNoiseLevel.dirty || plantNoiseLevel.touched)" class="textbox alert alert-danger">
                        <div *ngIf="plantNoiseLevel.errors.required">
                            Plant Noise Level is required
                        </div>
                    </div>
                </div>
           
            </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMERCIAL OPERATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>        
            
            <div class="row margin">    
                <div class="left textLabel">
                    Natural Gas Supplier <br />
                    <input type="textbox" class="textbox" placeholder="Natural Gas Supplier" [(ngModel)]="powerplant.commercialOperations.naturalGasSupplier" name="naturalGasSupplier" #naturalGasSupplier="ngModel" maxlength="50" />
                    <div *ngIf="naturalGasSupplier.invalid && (naturalGasSupplier.dirty || naturalGasSupplier.touched)" class="textbox alert alert-danger">
                        <div *ngIf="naturalGasSupplier.errors.required">
                            Natural Gas Supplier is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Gas Contract Quantity (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Gas Contract Quantity" [(ngModel)]="powerplant.commercialOperations.gasContractQuantity" name="gasContractQuantity" #gasContractQuantity="ngModel" />
                    <div *ngIf="gasContractQuantity.invalid && (gasContractQuantity.dirty || gasContractQuantity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasContractQuantity.errors.required">
                            Gas Contract Quantity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Average Daily Gas Consumption <br />
                    <input type="number" class="textbox" placeholder="Average Daily Gas Consumption" [(ngModel)]="powerplant.commercialOperations.avgDailyGasConsumption" name="avgDailyGasConsumption" #avgDailyGasConsumption="ngModel" />
                    <div *ngIf="avgDailyGasConsumption.invalid && (avgDailyGasConsumption.dirty || avgDailyGasConsumption.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgDailyGasConsumption.errors.required">
                            Avg Daily Gas Consumption is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Gas Transportation Agreement <br />
                    <input type="textbox" class="textbox" placeholder="Gas Transportation Agreement" [(ngModel)]="powerplant.commercialOperations.gasTransportationAgreement" name="gasTransportationAgreement" #gasTransportationAgreement="ngModel" maxlength="50" />
                    <div *ngIf="gasTransportationAgreement.invalid && (gasTransportationAgreement.dirty || gasTransportationAgreement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasTransportationAgreement.errors.required">
                            Transportation Agreement is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">    
                <div class="left textLabel">
                    PPA for Power Expert <br />
                    <input type="radio" id="ppaForPowerExportYes" class="radio" value="yes" [(ngModel)]="powerplant.commercialOperations.ppaForPowerExport" name="ppaForPowerExport" #ppaForPowerExport="ngModel" />
                    <label for="ppaForPowerExportYes">Yes</label>
                    <input type="radio" id="ppaForPowerExportNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commercialOperations.ppaForPowerExport" name="ppaForPowerExport" #ppaForPowerExport="ngModel" />
                    <label for="ppaForPowerExportNo">No</label>
                </div>
                <div class="left textLabel">
                    PPA Contract Capacity(MW) <br />
                    <input type="number" class="textbox" placeholder="PPA Contract Capacity" [(ngModel)]="powerplant.commercialOperations.ppaContractCapacity" name="ppaContractCapacity" #ppaContractCapacity="ngModel" />
                    <div *ngIf="ppaContractCapacity.invalid && (ppaContractCapacity.dirty || ppaContractCapacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaContractCapacity.errors.required">
                            PPA Contract Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    PPA Customer <br />
                    <input type="textbox" class="textbox" placeholder="PPA Customer" [(ngModel)]="powerplant.commercialOperations.ppaCustomer" name="ppaCustomer" #ppaCustomer="ngModel" />
                    <div *ngIf="ppaCustomer.invalid && (ppaCustomer.dirty || ppaCustomer.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaCustomer.errors.required">
                            PPA Customer is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    PPA Effective Date  <br />
                    <input type="textbox" class="textbox" placeholder="PPA Effective Date" [nbDatepicker]="dp12" [(ngModel)]="ppaEffectiveDateDt" name="ppaEffectiveDate" #ppaEffectiveDate="ngModel"  />
                    <nb-datepicker #dp12 format='M/D/yyyy'></nb-datepicker>      
                    <div *ngIf="ppaEffectiveDate.invalid && (ppaEffectiveDate.dirty || ppaEffectiveDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaEffectiveDate.errors.required">
                            PPA Effective Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    PPA Term (Years) <br />
                    <input type="number" class="textbox" placeholder="PPA Term" [(ngModel)]="powerplant.commercialOperations.ppaTerm" name="ppaTerm" #ppaTerm="ngModel" />
                    <div *ngIf="ppaTerm.invalid && (ppaTerm.dirty || ppaTerm.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaTerm.errors.required">
                            PPA Term is required
                        </div>
                    </div>
                </div>   
                <div class="left textLabel">
                    PPA Securitized <br />
                    <input type="radio" id="ppaSecuritizedYes" class="radio" value="yes" [(ngModel)]="powerplant.commercialOperations.ppaSecuritized" name="ppaSecuritized" #ppaSecuritized="ngModel" />
                    <label for="ppaSecuritizedYes">Yes</label>
                    <input type="radio" id="ppaSecuritizedNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commercialOperations.ppaSecuritized" name="ppaSecuritized" #ppaSecuritized="ngModel" />
                    <label for="ppaSecuritizedNo">No</label>
                </div>               
                <div class="left textLabel">
                    Avg.Load Factor (%)  <br />
                    <input type="number" class="textbox" placeholder="Avg.Load Factor" [(ngModel)]="powerplant.commercialOperations.avgLoadFactor" name="avgLoadFactor" #avgLoadFactor="ngModel" pattern="^[1-9][0-9]?$|^100$" />
                    <div *ngIf="avgLoadFactor.invalid && (avgLoadFactor.dirty || avgLoadFactor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgLoadFactor.errors.required">
                            Avg.Load Factor is required
                        </div>
                        <div *ngIf="avgLoadFactor.errors.pattern">
                            Avg. Load Factor % can be b/w 1-100
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                   Avg Capacity Availability  <br />
                    <input type="number" class="textbox" placeholder="Avg Capacity Availability" [(ngModel)]="powerplant.commercialOperations.avgCapacityAvailability" name="avgCapacityAvailability" #avgCapacityAvailability="ngModel" />
                    <div *ngIf="avgCapacityAvailability.invalid && (avgCapacityAvailability.dirty || avgCapacityAvailability.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgCapacityAvailability.errors.required">
                            Avg Capacity Availability is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    Average NEO (kw/hr) <br />
                    <input type="number" class="textbox" placeholder="Average NEO" [(ngModel)]="powerplant.commercialOperations.avgNEO" name="avgNEO" #avgNEO="ngModel" />
                    <div *ngIf="avgNEO.invalid && (avgNEO.dirty || avgNEO.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgNEO.errors.required">
                            Average NEO is required
                        </div>
                    </div>
                </div>   
                <div class="left textLabel">
                    (CER) Certified Emission Reduce <br />
                    <input type="radio" id="certifiedEmissionRatioYes" class="radio" value="yes" [(ngModel)]="powerplant.commercialOperations.certifiedEmissionRatio" name="certifiedEmissionRatio" #certifiedEmissionRatio="ngModel" />
                    <label for="certifiedEmissionRatioYes">Yes</label>
                    <input type="radio" id="certifiedEmissionRatioNo" class="radio radiomargin" value="no" [(ngModel)]="powerplant.commercialOperations.certifiedEmissionRatio" name="certifiedEmissionRatio" #certifiedEmissionRatio="ngModel" />
                    <label for="certifiedEmissionRatioNo">No</label>
                </div>               
                <div class="left textLabel">
                    Notes  <br />
                    <textarea type="textbox" class="textbox" placeholder="Notes" [(ngModel)]="powerplant.commercialOperations.notes" name="notes" #notes="ngModel"></textarea>
                    <div *ngIf="notes.invalid && (notes.dirty || notes.touched)" class="textbox alert alert-danger">
                        <div *ngIf="notes.errors.required">
                            Notes is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                   Profile  <br />
                    <textarea type="textbox" class="textbox" placeholder="Profile" [(ngModel)]="powerplant.commercialOperations.profile" name="profile" #profile="ngModel"></textarea>
                    <div *ngIf="profile.invalid && (profile.dirty || profile.touched)" class="textbox alert alert-danger">
                        <div *ngIf="profile.errors.required">
                            Profile is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    Passcode <br />
                    <input type="textbox" class="textbox" placeholder="Passcode" [(ngModel)]="powerplant.passcode" name="passcode" #passcode="ngModel" required />
                    <div *ngIf="passcode.invalid && (passcode.dirty || passcode.touched)" class="textbox alert alert-danger">
                        <div *ngIf="passcode.errors.required">
                            Passcode is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <div class="textLabel">
            &nbsp; <br />
            <button type="submit" class="button blueButton" [disabled]="powerPlantForm.invalid || isSubmitted" (click)="saveFacility('2')" value="Save" ><span *ngIf="!isSubmitted">Save</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
            <input type="submit" class="button whiteButton grey" [disabled]="isSubmitted" (click)="navigate()" value="Cancel" />
        </div>
    </form>
</div>

<div *ngIf="facility && facility.type==3">
    <form #pipelineForm="ngForm" name="pipelineForm" novalidate>
        <mat-expansion-panel [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="pipelineForm">
                <div class="row">
                    <div class="right textLabel">
                        Owner <br />
                        <select class="textbox stateSelect" [(ngModel)]="pipeline.ownerId" name="ownerId" #ownerId="ngModel">
                            <option value="">Admin</option>
                            <option *ngFor="let corp of corps" value="{{corp.uid}}">{{corp.firstName+'('+corp.companyName+')'}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Admin Private <br />
                        <nb-toggle [(ngModel)]="pipeline.isAdminPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                    <div class="left textLabel">
                        Owner Private <br />
                        <nb-toggle [(ngModel)]="pipeline.isOwnerPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="pipelineForm">                
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <input type="textbox" class="textbox" placeholder="Facility Name" [(ngModel)]="pipeline.basic.facilityName" name="facilityName" #facilityName="ngModel" required maxlength="150" />
                        <div *ngIf="facilityName.invalid && (facilityName.dirty || facilityName.touched)" class="textbox alert alert-danger">
                            <div *ngIf="facilityName.errors.required">
                                Facility Name is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <input type="textbox" class="textbox" placeholder="Project Investors/Developers" [(ngModel)]="pipeline.basic.projectInvestors" name="projectInvestors" #projectInvestors="ngModel"  maxlength="150" />
                        <div *ngIf="projectInvestors.invalid && (projectInvestors.dirty || projectInvestors.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectInvestors.errors.required">
                                Project Investors/Developers is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <input type="textbox" class="textbox" placeholder="Project Financier" [(ngModel)]="pipeline.basic.projectFinancier" name="projectFinancier" #projectFinancier="ngModel"  maxlength="150" />
                        <div *ngIf="projectFinancier.invalid && (projectFinancier.dirty || projectFinancier.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectFinancier.errors.required">
                                Project Financier is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <select class="textbox stateSelect" [(ngModel)]="pipeline.basic.classification" name="classification" #classification="ngModel" >
                            <option *ngFor="let a of pipelineLookups['Pipeline Classification']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="classification.invalid && (classification.dirty || classification.touched)" class="textbox alert alert-danger">
                            <div *ngIf="classification.errors.required">
                                Classification is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <input type="textbox" class="textbox" placeholder="Owner" [(ngModel)]="pipeline.basic.owner" name="owner" #owner="ngModel"  maxlength="50" />
                        <div *ngIf="owner.invalid && (owner.dirty || owner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="owner.errors.required">
                                Owner is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <input type="textbox" class="textbox" placeholder="Date of Transfer to Owner" [nbDatepicker]="dp3" [(ngModel)]="dateTransferOwnerDt" name="dateTransferOwner" #dateTransferOwner="ngModel"   />
                        <nb-datepicker #dp3 format='M/D/yyyy'></nb-datepicker>
                        <div *ngIf="dateTransferOwner.invalid && (dateTransferOwner.dirty || dateTransferOwner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="dateTransferOwner.errors.required">
                                Date of Transfer to Owner is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <input type="textbox" class="textbox" placeholder="LinkedIn Account" [(ngModel)]="pipeline.basic.linkedInAccount" name="linkedInAccount" #linkedInAccount="ngModel" maxlength="250" pattern="(((http(s?)://)?)((([www])*\.)?)|[linkedin])[linkedin/~\-]+\.[a-zA-Z0-9/~\-_,&=\?\.;]+[^\.,\s<]" />
                        <div *ngIf="linkedInAccount.invalid && (linkedInAccount.dirty || linkedInAccount.touched)" class="textbox alert alert-danger">
                            <div *ngIf="linkedInAccount.errors.required">
                                LinkedIn Account is required
                            </div>
                            <div *ngIf="linkedInAccount.errors.pattern">
                                Please enter valid LinkedIn Url!
                            </div>
                        </div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <input type="textbox" class="textbox" placeholder="Company Website" [(ngModel)]="pipeline.basic.companyWebsite" name="companyWebsite" #companyWebsite="ngModel" maxlength="250" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" />
                        <div *ngIf="companyWebsite.invalid && (companyWebsite.dirty || companyWebsite.touched)" class="textbox alert alert-danger">
                            <div *ngIf="companyWebsite.errors.required">
                                Company Website is required
                            </div>
                            <div *ngIf="companyWebsite.errors.pattern">
                                Please enter valid Website Url!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <select class="textbox stateSelect" [(ngModel)]="pipeline.basic.ownerType" name="ownerType" #ownerType="ngModel" >
                            <option *ngFor="let a of pipelineLookups['Pipeline Owner Type']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="ownerType.invalid && (ownerType.dirty || ownerType.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="ownerType.errors.required">
                                Owner Type is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <input type="textbox" class="textbox" placeholder="Operator" [(ngModel)]="pipeline.basic.operator" name="operator" #operator="ngModel" maxlength="150" />
                        <div *ngIf="operator.invalid && (operator.dirty || operator.touched)" class="textbox alert alert-danger">
                            <div *ngIf="operator.errors.required">
                                Operator Type is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <textarea type="textbox" (click)="locate()" class="textbox" placeholder="Location" [(ngModel)]="pipeline.basic.location" name="location" #location="ngModel" maxlength="500" ></textarea>
                        <div *ngIf="location.invalid && (location.dirty || location.touched)" class="textbox alert alert-danger">
                            <div *ngIf="location.errors.required">
                                Location Type is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <select class="textbox stateSelect" [(ngModel)]="pipeline.basic.country" name="country" #country="ngModel" >
                            <option value="">Select Country</option>
                            <option *ngFor="let a of countries" value="{{a.country}}">{{a.country}}</option>
                        </select>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="country.errors.required">
                                Country Type is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <input type="textbox" class="textbox" placeholder="GPS Location" [(ngModel)]="pipeline.basic.gpslocation" name="gpslocation" #gpslocation="ngModel"  maxlength="150" />
                        <div *ngIf="gpslocation.invalid && (gpslocation.dirty || gpslocation.touched)" class="textbox alert alert-danger">
                            <div *ngIf="gpslocation.errors.required">
                                GPS Location is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <input type="textbox" class="textbox" placeholder="National Regulatory Agency" [(ngModel)]="pipeline.basic.nationalRegulatoryAgency" name="nationalRegulatoryAgency" #nationalRegulatoryAgency="ngModel"  maxlength="150" />
                        <div *ngIf="nationalRegulatoryAgency.invalid && (nationalRegulatoryAgency.dirty || nationalRegulatoryAgency.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalRegulatoryAgency.errors.required">
                                National Regulatory Agency is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <input type="textbox" class="textbox" placeholder="National Policy Administrator" [(ngModel)]="pipeline.basic.nationalPolicyAdmin" name="nationalPolicyAdmin" #nationalPolicyAdmin="ngModel"  maxlength="150" />
                        <div *ngIf="nationalPolicyAdmin.invalid && (nationalPolicyAdmin.dirty || nationalPolicyAdmin.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalPolicyAdmin.errors.required">
                                National Policy Administrator is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Pipeline Status <br />
                        <select class="textbox stateSelect" [(ngModel)]="pipeline.basic.pipelineStatus" name="pipelineStatus" #pipelineStatus="ngModel" >
                            <option *ngFor="let a of pipelineLookups['Pipeline Status']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="pipelineStatus.invalid && (pipelineStatus.dirty || pipelineStatus.touched)" class="textbox alert alert-danger">
                            <div *ngIf="pipelineStatus.errors.required">
                                Pipeline Status is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="right textLabel">
                        Region <br />
                        <select class="textbox stateSelect" [(ngModel)]="pipeline.basic.region" name="region" #region="ngModel" >
                            <option *ngFor="let a of pipelineLookups['Pipeline Region']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="region.invalid && (region.dirty || region.touched)" class="textbox alert alert-danger">
                            <div *ngIf="region.errors.required">
                                Region is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Size Diameter (inches) <br />
                    <input type="number" class="textbox" placeholder="Size Diameter" [(ngModel)]="pipeline.designSpecs.sizeDiameter" name="sizeDiameter" #sizeDiameter="ngModel"  />
                    <div *ngIf="sizeDiameter.invalid && (sizeDiameter.dirty || sizeDiameter.touched)" class="textbox alert alert-danger">
                        <div *ngIf="sizeDiameter.errors.required">
                            Size Diameter is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Maximum Velocity (m/s) <br />
                    <input type="number" class="textbox" placeholder="Maximum Velocity" [(ngModel)]="pipeline.designSpecs.maxVelocity" name="maxVelocity" #maxVelocity="ngModel" />
                    <div *ngIf="maxVelocity.invalid && (maxVelocity.dirty || maxVelocity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="maxVelocity.errors.required">
                            Maximum Velocity is required
                        </div>
                    </div>
                </div>
               
                <div class="left textLabel">
                    Capacity (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Capacity (MW)" [(ngModel)]="pipeline.designSpecs.capacity" name="capacity" #capacity="ngModel"  />
                    <div *ngIf="capacity.invalid && (capacity.dirty || capacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacity.errors.required">
                            Capacity is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Material <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.designSpecs.material" name="material" #material="ngModel" >
                        <option *ngFor="let a of pipelineLookups['Pipeline Material']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="material.invalid && (material.dirty || material.touched)" class="textbox alert alert-danger">
                        <div *ngIf="material.errors.required">
                            Material is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Design Life (years) <br />
                    <input type="number" class="textbox" placeholder="Design Life" [(ngModel)]="pipeline.designSpecs.designLife" name="designLife" #designLife="ngModel"  />
                    <div *ngIf="designLife.invalid && (designLife.dirty || designLife.touched)" class="textbox alert alert-danger">
                        <div *ngIf="designLife.errors.required">
                            Design Life is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Wall Thickness / Schedule (inches) <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.designSpecs.wallThicknessSchedule" name="wallThicknessSchedule" #wallThicknessSchedule="ngModel" >
                        <option *ngFor="let a of pipelineLookups['Pipeline Wall Thickness']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="wallThicknessSchedule.invalid && (wallThicknessSchedule.dirty || wallThicknessSchedule.touched)" class="textbox alert alert-danger">
                        <div *ngIf="wallThicknessSchedule.errors.required">
                            Wall Thickness/Schedule is required
                        </div>
                    </div>
                </div>
                
                <div class="left textLabel">
                    Steel Type <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.designSpecs.steelType" name="steelType" #steelType="ngModel" >
                        <option *ngFor="let a of pipelineLookups['Pipeline Steel Type']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="steelType.invalid && (steelType.dirty || steelType.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steelType.errors.required">
                            Steel Type is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Pipe Class <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.designSpecs.pipeClass" name="pipeClass" #pipeClass="ngModel" >
                        <option *ngFor="let a of pipelineLookups['Pipeline Pipe Class']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="pipeClass.invalid && (pipeClass.dirty || pipeClass.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steelType.errors.required">
                            Pipe Class is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Pipe Schedule <br />
                    <input type="number" class="textbox" placeholder="Pipe Schedule" [(ngModel)]="pipeline.designSpecs.pipeSchedule" name="pipeSchedule" #pipeSchedule="ngModel"  />
                    <div *ngIf="pipeSchedule.invalid && (pipeSchedule.dirty || pipeSchedule.touched)" class="textbox alert alert-danger">
                        <div *ngIf="pipeSchedule.errors.required">
                            Pipe Schedule is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Pipe Thickness <br />
                    <input type="number" class="textbox" placeholder="Pipe Thickness" [(ngModel)]="pipeline.designSpecs.pipeThickness" name="pipeThickness" #pipeThickness="ngModel" />
                    <div *ngIf="pipeThickness.invalid && (pipeThickness.dirty || pipeThickness.touched)" class="textbox alert alert-danger">
                        <div *ngIf="pipeThickness.errors.required">
                            Pipe Thickness is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OPERATING CONDITIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Design Pressure (barg) <br />
                    <input type="number" class="textbox" placeholder="Design Pressure" [(ngModel)]="pipeline.operatingConditions.designPressure" name="designPressure" #designPressure="ngModel" />
                    <div *ngIf="designPressure.invalid && (designPressure.dirty || designPressure.touched)" class="textbox alert alert-danger">
                        <div *ngIf="designPressure.errors.required">
                            Design Pressure is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Inlet Pressure (barg) <br />
                    <input type="number" class="textbox" placeholder="Inlet Pressure" [(ngModel)]="pipeline.operatingConditions.inletPressure" name="inletPressure" #inletPressure="ngModel" />
                    <div *ngIf="inletPressure.invalid && (inletPressure.dirty || inletPressure.touched)" class="textbox alert alert-danger">
                        <div *ngIf="inletPressure.errors.required">
                            Inlet Pressure is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Delivery Pressure (barg) <br />
                    <input type="number" class="textbox" placeholder="Delivery Pressure" [(ngModel)]="pipeline.operatingConditions.deliveryPressure" name="deliveryPressure" #deliveryPressure="ngModel" />
                    <div *ngIf="deliveryPressure.invalid && (deliveryPressure.dirty || deliveryPressure.touched)" class="textbox alert alert-danger">
                        <div *ngIf="deliveryPressure.errors.required">
                            Delivery Pressure is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Operating Tempratures (&deg;C) <br />
                    <input type="number" class="textbox" placeholder="Operating Tempratures" [(ngModel)]="pipeline.operatingConditions.operatingTemp" name="operatingTemp" #operatingTemp="ngModel" />
                    <div *ngIf="operatingTemp.invalid && (operatingTemp.dirty || operatingTemp.touched)" class="textbox alert alert-danger">
                        <div *ngIf="operatingTemp.errors.required">
                            Operating Tempratures is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Gas Type <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.operatingConditions.gasType" name="gasType" #gasType="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Gas Type']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="gasType.invalid && (gasType.dirty || gasType.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasType.errors.required">
                            Gas Type is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ROUTING
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Tie-in-point Coordinates <br />
                    <input type="text" class="textbox" placeholder="Tie-in-point Coordinates" [(ngModel)]="pipeline.routing.tieInPointCoordinates" name="tieInPointCoordinates" #tieInPointCoordinates="ngModel" maxlength="50" />
                    <div *ngIf="tieInPointCoordinates.invalid && (tieInPointCoordinates.dirty || tieInPointCoordinates.touched)" class="textbox alert alert-danger">
                        <div *ngIf="tieInPointCoordinates.errors.required">
                            Tie-in-point Coordinates is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    From <br />
                    <input type="text" class="textbox" placeholder="From" [(ngModel)]="pipeline.routing.from" name="from" #from="ngModel" maxlength="50" />
                    <div *ngIf="from.invalid && (from.dirty || from.touched)" class="textbox alert alert-danger">
                        <div *ngIf="from.errors.required">
                            From is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    To <br />
                    <input type="text" class="textbox" placeholder="To" [(ngModel)]="pipeline.routing.to" name="to" #to="ngModel" maxlength="50" />
                    <div *ngIf="to.invalid && (to.dirty || to.touched)" class="textbox alert alert-danger">
                        <div *ngIf="to.errors.required">
                            To is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Terrain <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.routing.terrain" name="terrain" #terrain="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Terrain']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="terrain.invalid && (terrain.dirty || terrain.touched)" class="textbox alert alert-danger">
                        <div *ngIf="terrain.errors.required">
                            Terrain is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Length (kms) <br />
                    <input type="number" class="textbox" placeholder="Length" [(ngModel)]="pipeline.routing.Length" name="Length" #Length="ngModel" />
                    <div *ngIf="Length.invalid && (Length.dirty || Length.touched)" class="textbox alert alert-danger">
                        <div *ngIf="Length.errors.required">
                            Length is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    RoW Width (ft.) <br />
                    <input type="number" class="textbox" placeholder="RoW Width" [(ngModel)]="pipeline.routing.rowWidth" name="rowWidth" #rowWidth="ngModel" />
                    <div *ngIf="rowWidth.invalid && (rowWidth.dirty || rowWidth.touched)" class="textbox alert alert-danger">
                        <div *ngIf="rowWidth.errors.required">
                            RoW Width is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT EXECUTION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <input type="text" class="textbox" placeholder="EPC Contractor" [(ngModel)]="pipeline.projectExecution.epcContractor" name="epcContractor" #epcContractor="ngModel" maxlength="50" />
                    <div *ngIf="epcContractor.invalid && (epcContractor.dirty || epcContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="epcContractor.errors.required">
                            EPC Contractor is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Construction Start Date <br />
                    <input type="textbox" class="textbox" placeholder="Construction Start Date" [nbDatepicker]="dp4" [(ngModel)]="constructionStartDt" name="constructionStart" #constructionStart="ngModel"  />
                    <nb-datepicker #dp4 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="constructionStart.invalid && (constructionStart.dirty || constructionStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="constructionStart.errors.required">
                            Construction Start Date is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Construction End Date <br />
                    <input type="textbox" class="textbox" placeholder="Construction End Date" [nbDatepicker]="dp5" [(ngModel)]="constructionEndDt" name="constructionEnd" #constructionEnd="ngModel"  />
                    <nb-datepicker #dp5 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="constructionEnd.invalid && (constructionEnd.dirty || constructionEnd.touched)" class="textbox alert alert-danger">
                        <div *ngIf="constructionEnd.errors.required">
                            Construction End Date is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Operation Start Date <br />
                    <input type="textbox" class="textbox" placeholder="Operation Start Date" [nbDatepicker]="dp6" [(ngModel)]="operationStartDt" name="operationStart" #operationStart="ngModel"  />
                    <nb-datepicker #dp6 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="operationStart.invalid && (operationStart.dirty || operationStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="operationStart.errors.required">
                            Operation Start Date is required
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="left textLabel">
                    Estimated Cost (US$) <br />
                    <input type="number" class="textbox" placeholder="Estimated Cost" [(ngModel)]="pipeline.projectExecution.estimatedCost" name="estimatedCost" #estimatedCost="ngModel" />
                    <div *ngIf="estimatedCost.invalid && (estimatedCost.dirty || estimatedCost.touched)" class="textbox alert alert-danger">
                        <div *ngIf="estimatedCost.errors.required">
                            Estimated Cost is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Derating (%) <br />
                    <input type="number" class="textbox" placeholder="Derating" [(ngModel)]="pipeline.projectExecution.derating" name="derating" #derating="ngModel" pattern="^[1-9][0-9]?$|^100$" />
                    <div *ngIf="derating.invalid && (derating.dirty || derating.touched)" class="textbox alert alert-danger">
                        <div *ngIf="derating.errors.required">
                            Derating is required
                        </div>
                        <div *ngIf="derating.errors.pattern">
                            Percentage can be b/w 1 to 100 only
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Status <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.projectExecution.status" name="status" #status="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Project Status']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="status.invalid && (status.dirty || status.touched)" class="textbox alert alert-danger">
                        <div *ngIf="status.errors.required">
                            Status is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    EIA/ESIA Approval Date  <br />
                    <input type="textbox" class="textbox" placeholder="EIA/ESIA Approval Date" [nbDatepicker]="dp7" [(ngModel)]="eiaApprovalDateDt" name="eiaApprovalDate" #eiaApprovalDate="ngModel"  />
                    <nb-datepicker #dp7 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="eiaApprovalDate.invalid && (eiaApprovalDate.dirty || eiaApprovalDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="eiaApprovalDate.errors.required">
                            EIA/ESIA Approval Date is required
                        </div>
                    </div>        
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OPERATING HISTORY
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Pipeline Purpose <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.operatingHistory.pipelinePurpose" name="pipelinePurpose" #pipelinePurpose="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Purpose']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="pipelinePurpose.invalid && (pipelinePurpose.dirty || pipelinePurpose.touched)" class="textbox alert alert-danger">
                        <div *ngIf="pipelinePurpose.errors.required">
                            Pipeline Purpose is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    O & M <br />
                    <input type="textbox" class="textbox" placeholder="O & M" [(ngModel)]="pipeline.operatingHistory.onm" name="onm" #onm="ngModel" maxlength="50"  />
                    <div *ngIf="onm.invalid && (onm.dirty || onm.touched)" class="textbox alert alert-danger">
                        <div *ngIf="onm.errors.required">
                            O & M is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Leaks(Date) <br />
                    <input type="textbox" class="textbox" placeholder="Leaks(Date)" [nbDatepicker]="dp8" [(ngModel)]="leaksDt" name="leaks" #leaks="ngModel"  />
                    <nb-datepicker #dp8 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="leaks.invalid && (leaks.dirty || leaks.touched)" class="textbox alert alert-danger">
                        <div *ngIf="leaks.errors.required">
                            Leaks(Date) is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Leak Type <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.operatingHistory.leakType" name="leakType" #leakType="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Leak Type']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="leakType.invalid && (leakType.dirty || leakType.touched)" class="textbox alert alert-danger">
                        <div *ngIf="leakType.errors.required">
                            Leak Type is required
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="left textLabel">
                    Market Delivery <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.operatingHistory.marketDelivery" name="marketDelivery" #marketDelivery="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Market Delivery']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="marketDelivery.invalid && (marketDelivery.dirty || marketDelivery.touched)" class="textbox alert alert-danger">
                        <div *ngIf="marketDelivery.errors.required">
                            Market Delivery is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Compressor Stations <br />
                    <input type="number" class="textbox" placeholder="Compressor Stations" [(ngModel)]="pipeline.operatingHistory.compressorStations" name="compressorStations" #compressorStations="ngModel" />
                    <div *ngIf="compressorStations.invalid && (compressorStations.dirty || compressorStations.touched)" class="textbox alert alert-danger">
                        <div *ngIf="compressorStations.errors.required">
                            Compressor Stations is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Pigging Stations <br />
                    <input type="number" class="textbox" placeholder="Pigging Stations" [(ngModel)]="pipeline.operatingHistory.piggingStations" name="piggingStations" #piggingStations="ngModel" />
                    <div *ngIf="piggingStations.invalid && (piggingStations.dirty || piggingStations.touched)" class="textbox alert alert-danger">
                        <div *ngIf="piggingStations.errors.required">
                            Pigging Stations is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Valve Stations <br />
                    <input type="number" class="textbox" placeholder="Valve Stations" [(ngModel)]="pipeline.operatingHistory.valveStations" name="valveStations" #valveStations="ngModel" />
                    <div *ngIf="valveStations.invalid && (valveStations.dirty || valveStations.touched)" class="textbox alert alert-danger">
                        <div *ngIf="valveStations.errors.required">
                            Valve Stations is required
                        </div>
                    </div>
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OTHERS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Odorized <br />
                    <input type="radio" id="odorizedYes" class="radio" value="yes" [(ngModel)]="pipeline.others.odorized" name="odorized" #odorized="ngModel" />
                    <label for="odorizedYes">Yes</label>
                    <input type="radio" id="odorizedNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.odorized" name="odorized" #odorized="ngModel" />
                    <label for="odorizedNo">No</label>
                </div>
                <div class="left textLabel">
                    Pipeline Markers <br />
                    <input type="radio" id="pipelineMarkersYes" class="radio" value="yes" [(ngModel)]="pipeline.others.pipelineMarkers" name="pipelineMarkers" #pipelineMarkers="ngModel" />
                    <label for="pipelineMarkersYes">Yes</label>
                    <input type="radio" id="pipelineMarkersNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.pipelineMarkers" name="pipelineMarkers" #pipelineMarkers="ngModel" />
                    <label for="pipelineMarkersNo">No</label>
                </div>
                <div class="left textLabel">
                    Open Access <br />
                    <input type="radio" id="openAccessYes" class="radio" value="yes" [(ngModel)]="pipeline.others.openAccess" name="openAccess" #openAccess="ngModel" />
                    <label for="openAccessYes">Yes</label>
                    <input type="radio" id="openAccessNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.openAccess" name="openAccess" #openAccess="ngModel" />
                    <label for="openAccessNo">No</label>
                </div>
                
                <div class="right textLabel">
                    River Crossing <br />
                    <input type="radio" id="riverCrossingYes" class="radio" value="yes" [(ngModel)]="pipeline.others.riverCrossing" name="riverCrossing" #riverCrossing="ngModel" />
                    <label for="riverCrossingYes">Yes</label>
                    <input type="radio" id="riverCrossingNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.riverCrossing" name="riverCrossing" #riverCrossing="ngModel" />
                    <label for="riverCrossingNo">No</label>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Linked To CityGate <br />
                    <input type="radio" id="linkedToCityGateYes" class="radio" value="yes" [(ngModel)]="pipeline.others.linkedToCityGate" name="linkedToCityGate" #linkedToCityGate="ngModel" />
                    <label for="linkedToCityGateYes">Yes</label>
                    <input type="radio" id="linkedToCityGateNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.linkedToCityGate" name="linkedToCityGate" #linkedToCityGate="ngModel" />
                    <label for="linkedToCityGateNo">No</label>
                </div>
                <div class="left textLabel">
                    Linked to Customer <br />
                    <input type="radio" id="linkedToCustomerYes" class="radio" value="yes" [(ngModel)]="pipeline.others.linkedToCustomer" name="linkedToCustomer" #linkedToCustomer="ngModel" />
                    <label for="linkedToCustomerYes">Yes</label>
                    <input type="radio" id="linkedToCustomerNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.linkedToCustomer" name="linkedToCustomer" #linkedToCustomer="ngModel" />
                    <label for="linkedToCustomerNo">No</label>
                </div>
                <div class="left textLabel">
                    Linked to Processing Plant <br />
                    <input type="radio" id="linkedToProcessingPlantYes" class="radio" value="yes" [(ngModel)]="pipeline.others.linkedToProcessingPlant" name="linkedToProcessingPlant" #linkedToProcessingPlant="ngModel" />
                    <label for="linkedToProcessingPlantYes">Yes</label>
                    <input type="radio" id="linkedToProcessingPlantNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.linkedToProcessingPlant" name="linkedToProcessingPlant" #linkedToProcessingPlant="ngModel" />
                    <label for="linkedToProcessingPlantNo">No</label>
                </div>
                
                <div class="right textLabel">
                    Linked to Manifold <br />
                    <input type="radio" id="linkedToManifoldYes" class="radio" value="yes" [(ngModel)]="pipeline.others.linkedToManifold" name="linkedToManifold" #linkedToManifold="ngModel" />
                    <label for="linkedToManifoldYes">Yes</label>
                    <input type="radio" id="linkedToManifoldNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.linkedToManifold" name="linkedToManifold" #linkedToManifold="ngModel" />
                    <label for="linkedToManifoldNo">No</label>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Interconnected to Other Pipeline <br />
                    <input type="radio" id="interconnectedToOtherPipelineYes" class="radio" value="yes" [(ngModel)]="pipeline.others.interconnectedToOtherPipeline" name="interconnectedToOtherPipeline" #interconnectedToOtherPipeline="ngModel" />
                    <label for="interconnectedToOtherPipelineYes">Yes</label>
                    <input type="radio" id="interconnectedToOtherPipelineNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.interconnectedToOtherPipeline" name="interconnectedToOtherPipeline" #interconnectedToOtherPipeline="ngModel" />
                    <label for="interconnectedToOtherPipelineNo">No</label>
                </div>
                
                <div class="left textLabel">
                    Pipe Coating <br />
                    <input type="radio" id="pipeCoatingYes" class="radio" value="yes" [(ngModel)]="pipeline.others.pipeCoating" name="pipeCoating" #pipeCoating="ngModel" />
                    <label for="pipeCoatingYes">Yes</label>
                    <input type="radio" id="pipeCoatingNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.pipeCoating" name="pipeCoating" #pipeCoating="ngModel" />
                    <label for="pipeCoatingNo">No</label>
                </div>

                <div class="left textLabel">
                    Corrosion Management <br />
                    <input type="radio" id="corrosionManagementYes" class="radio" value="yes" [(ngModel)]="pipeline.others.corrosionManagement" name="corrosionManagement" #corrosionManagement="ngModel" />
                    <label for="corrosionManagementYes">Yes</label>
                    <input type="radio" id="corrosionManagementNo" class="radio radiomargin" value="no" [(ngModel)]="pipeline.others.corrosionManagement" name="corrosionManagement" #corrosionManagement="ngModel" />
                    <label for="corrosionManagementNo">No</label>
                </div>
                <div class="right textLabel">
                    Type of Corrosion Management <br />
                    <select class="textbox stateSelect" [(ngModel)]="pipeline.others.typeOfCorrosionManagement" name="typeOfCorrosionManagement" #typeOfCorrosionManagement="ngModel">
                        <option *ngFor="let a of pipelineLookups['Pipeline Type of Corrosion Management']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="typeOfCorrosionManagement.invalid && (typeOfCorrosionManagement.dirty || typeOfCorrosionManagement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="typeOfCorrosionManagement.errors.required">
                            Type of Corrosion Mgmt is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Rivers Crossed <br />
                    <input type="textbox" class="textbox" placeholder="Rivers Crossed" [(ngModel)]="pipeline.others.riversCrossed" name="riversCrossed" #riversCrossed="ngModel"  />
                    <div *ngIf="riversCrossed.invalid && (riversCrossed.dirty || riversCrossed.touched)" class="textbox alert alert-danger">
                        <div *ngIf="riversCrossed.errors.required">
                            Rivers Crossed is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Name of Customer <br />
                    <input type="textbox" class="textbox" placeholder="Name of Customer" [(ngModel)]="pipeline.others.nameOfCustomer" name="nameOfCustomer" #nameOfCustomer="ngModel"  />
                    <div *ngIf="nameOfCustomer.invalid && (nameOfCustomer.dirty || nameOfCustomer.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nameOfCustomer.errors.required">
                            Name of Customer is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Name of CityGate <br />
                    <input type="textbox" class="textbox" placeholder="Name of CityGate" [(ngModel)]="pipeline.others.nameOfCityGate" name="nameOfCityGate" #nameOfCityGate="ngModel" maxlength="50"  />
                    <div *ngIf="nameOfCityGate.invalid && (nameOfCityGate.dirty || nameOfCityGate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nameOfCityGate.errors.required">
                            Name of CityGate is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Name of Processing Plant <br />
                    <input type="textbox" class="textbox" placeholder="Name of Processing Plant" [(ngModel)]="pipeline.others.nameOfProcessingPlant" name="nameOfProcessingPlant" #nameOfProcessingPlant="ngModel" maxlength="150"  />
                    <div *ngIf="nameOfProcessingPlant.invalid && (nameOfProcessingPlant.dirty || nameOfProcessingPlant.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nameOfProcessingPlant.errors.required">
                            Name of Processing Plant is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Notes <br />
                    <textarea type="textbox" class="textbox" placeholder="Notes" [(ngModel)]="pipeline.others.notes" name="notes" #notes="ngModel" ></textarea>
                    <div *ngIf="notes.invalid && (notes.dirty || notes.touched)" class="textbox alert alert-danger">
                        <div *ngIf="notes.errors.required">
                            Notes is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Profile <br />
                    <textarea type="textbox" class="textbox" placeholder="Profile" [(ngModel)]="pipeline.others.profile" name="profile" #profile="ngModel" ></textarea>
                    <div *ngIf="profile.invalid && (profile.dirty || profile.touched)" class="textbox alert alert-danger">
                        <div *ngIf="profile.errors.required">
                            Profile is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Passcode <br />
                    <input type="textbox" class="textbox" placeholder="Passcode" [(ngModel)]="pipeline.passcode" name="passcode" #passcode="ngModel"  />
                    <div *ngIf="passcode.invalid && (passcode.dirty || passcode.touched)" class="textbox alert alert-danger">
                        <div *ngIf="passcode.errors.required">
                            Passcode is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <div class="textLabel">
            &nbsp; <br />
            <button type="submit" class="button blueButton" [disabled]="pipelineForm.invalid || isSubmitted" (click)="saveFacility('3')" value="Save" ><span *ngIf="!isSubmitted">Save</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
            <input type="submit" class="button whiteButton grey" [disabled]="isSubmitted" (click)="navigate()" value="Cancel" />
        </div>
    </form>
</div>

<div *ngIf="facility && facility.type==4">
    <form #offTakerForm="ngForm" name="offTakerForm" novalidate>
        <mat-expansion-panel [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="powerplantForm">
                <div class="row">
                    <div class="right textLabel">
                        Owner <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.ownerId" name="ownerId" #ownerId="ngModel">
                            <option value="">Admin</option>
                            <option *ngFor="let corp of corps" value="{{corp.uid}}">{{corp.firstName+'('+corp.companyName+')'}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Admin Private <br />
                        <nb-toggle [(ngModel)]="offtaker.isAdminPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                    <div class="left textLabel">
                        Owner Private <br />
                        <nb-toggle [(ngModel)]="offtaker.isOwnerPrivate"  [ngModelOptions]="{standalone: true}"></nb-toggle>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="powerplantForm">                
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <input type="textbox" class="textbox" placeholder="Facility Name" [(ngModel)]="offtaker.basic.facilityName" name="facilityName" #facilityName="ngModel" required maxlength="100" />
                        <div *ngIf="facilityName.invalid && (facilityName.dirty || facilityName.touched)" class="textbox alert alert-danger">
                            <div *ngIf="facilityName.errors.required">
                                Facility Name is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <input type="textbox" class="textbox" placeholder="Project Investors/Developers" [(ngModel)]="offtaker.basic.projectInvestors" name="projectInvestors" #projectInvestors="ngModel" maxlength="150" />
                        <div *ngIf="projectInvestors.invalid && (projectInvestors.dirty || projectInvestors.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectInvestors.errors.required">
                                Project Investors/Developers is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <input type="textbox" class="textbox" placeholder="Project Financier" [(ngModel)]="offtaker.basic.projectFinancier" name="projectFinancier" #projectFinancier="ngModel" maxlength="150" />
                        <div *ngIf="projectFinancier.invalid && (projectFinancier.dirty || projectFinancier.touched)" class="textbox alert alert-danger">
                            <div *ngIf="projectFinancier.errors.required">
                                Project Financier is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.basic.classification" name="classification" #classification="ngModel">
                            <option *ngFor="let a of offTakerLookups['Customer Classification']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="classification.invalid && (classification.dirty || classification.touched)" class="textbox alert alert-danger">
                            <div *ngIf="classification.errors.required">
                                Classification is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <input type="textbox" class="textbox" placeholder="Owner" [(ngModel)]="offtaker.basic.owner" name="owner" #owner="ngModel" maxlength="150" />
                        <div *ngIf="owner.invalid && (owner.dirty || owner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="owner.errors.required">
                                Owner is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <input type="textbox" class="textbox" placeholder="Date of Transfer to Owner" [nbDatepicker]="dp3" [(ngModel)]="dateTransferOwnerDt" name="dateTransferOwner" #dateTransferOwner="ngModel"  />
                        <nb-datepicker #dp3 format='M/D/yyyy'></nb-datepicker>
                        <div *ngIf="dateTransferOwner.invalid && (dateTransferOwner.dirty || dateTransferOwner.touched)" class="textbox alert alert-danger">
                            <div *ngIf="dateTransferOwner.errors.required">
                                Date of Transfer to Owner is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <input type="textbox" class="textbox" placeholder="LinkedIn Account" [(ngModel)]="offtaker.basic.linkedInAccount" name="linkedInAccount" #linkedInAccount="ngModel" maxlength="250" pattern="(((http(s?)://)?)((([www])*\.)?)|[linkedin])[linkedin/~\-]+\.[a-zA-Z0-9/~\-_,&=\?\.;]+[^\.,\s<]" />
                        <div *ngIf="linkedInAccount.invalid && (linkedInAccount.dirty || linkedInAccount.touched)" class="textbox alert alert-danger">
                            <div *ngIf="linkedInAccount.errors.required">
                                LinkedIn Account is required
                            </div>
                            <div *ngIf="linkedInAccount.errors.pattern">
                                Please enter valid LinkedIn Url!
                            </div>
                        </div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <input type="textbox" class="textbox" placeholder="Company Website" [(ngModel)]="offtaker.basic.companyWebsite" name="companyWebsite" #companyWebsite="ngModel" maxlength="250" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" />
                        <div *ngIf="companyWebsite.invalid && (companyWebsite.dirty || companyWebsite.touched)" class="textbox alert alert-danger">
                            <div *ngIf="companyWebsite.errors.required">
                                Company Website is required
                            </div>
                            <div *ngIf="companyWebsite.errors.pattern">
                                Please enter valid Website Url!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.basic.ownerType" name="ownerType" #ownerType="ngModel">
                            <option *ngFor="let a of offTakerLookups['Customer Owner Type']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="ownerType.invalid && (ownerType.dirty || ownerType.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="ownerType.errors.required">
                                Owner Type is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <input type="textbox" class="textbox" placeholder="Operator" [(ngModel)]="offtaker.basic.operator" name="operator" #operator="ngModel" maxlength="150" />
                        <div *ngIf="operator.invalid && (operator.dirty || operator.touched)" class="textbox alert alert-danger">
                            <div *ngIf="operator.errors.required">
                                Operator Type is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <textarea type="textbox" class="textbox" placeholder="Location" [(ngModel)]="offtaker.basic.location" name="location" #location="ngModel" maxlength="500" ></textarea>
                        <div *ngIf="location.invalid && (location.dirty || location.touched)" class="textbox alert alert-danger">
                            <div *ngIf="location.errors.required">
                                Location Type is required
                            </div>
                        </div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.basic.country" name="country" #country="ngModel">
                            <option value="">Select Country</option>
                            <option *ngFor="let a of countries" value="{{a.country}}">{{a.country}}</option>
                        </select>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="country.errors.required">
                                Country is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <input type="textbox" class="textbox" placeholder="GPS Location" [(ngModel)]="offtaker.basic.gpslocation" name="gpslocation" #gpslocation="ngModel" maxlength="150" />
                        <div *ngIf="gpslocation.invalid && (gpslocation.dirty || gpslocation.touched)" class="textbox alert alert-danger">
                            <div *ngIf="gpslocation.errors.required">
                                GPS Location is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <input type="textbox" class="textbox" placeholder="National Regulatory Agency" [(ngModel)]="offtaker.basic.nationalRegulatoryAgency" name="nationalRegulatoryAgency" #nationalRegulatoryAgency="ngModel" maxlength="150" />
                        <div *ngIf="nationalRegulatoryAgency.invalid && (nationalRegulatoryAgency.dirty || nationalRegulatoryAgency.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalRegulatoryAgency.errors.required">
                                National Regulatory Agency is required
                            </div>
                        </div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <input type="textbox" class="textbox" placeholder="National Policy Administrator" [(ngModel)]="offtaker.basic.nationalPolicyAdmin" name="nationalPolicyAdmin" #nationalPolicyAdmin="ngModel" maxlength="150" />
                        <div *ngIf="nationalPolicyAdmin.invalid && (nationalPolicyAdmin.dirty || nationalPolicyAdmin.touched)" class="textbox alert alert-danger">
                            <div *ngIf="nationalPolicyAdmin.errors.required">
                                National Policy Administrator is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Type <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.basic.powerPlantType" name="powerPlantType" #powerPlantType="ngModel">
                            <option *ngFor="let a of offTakerLookups['Customer Type']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="powerPlantType.invalid && (powerPlantType.dirty || powerPlantType.touched)" class="textbox selectErr alert alert-danger">
                            <div *ngIf="powerPlantType.errors.required">
                                Type is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Plant Status <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.basic.plantStatus" name="powerPlantStatus" #powerPlantStatus="ngModel">
                            <option *ngFor="let a of offTakerLookups['Customer Plant Status']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="powerPlantStatus.invalid && (powerPlantStatus.dirty || powerPlantStatus.touched)" class="textbox alert alert-danger">
                            <div *ngIf="powerPlantStatus.errors.required">
                                Plant Status is required
                            </div>
                        </div>
                    </div>
                    <div class="left textLabel">
                        Region <br />
                        <select class="textbox stateSelect" [(ngModel)]="offtaker.basic.region" name="region" #region="ngModel">
                            <option *ngFor="let a of offTakerLookups['Customer Region']" value={{a}}>{{a}}</option>
                        </select>
                        <div *ngIf="region.invalid && (region.dirty || region.touched)" class="textbox alert alert-danger">
                            <div *ngIf="region.errors.required">
                                Region is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>



        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity <br />
                    <input type="text" class="textbox" placeholder="Capacity" [(ngModel)]="offtaker.designSpecs.capacity" name="capacity" #capacity="ngModel" />
                    <div *ngIf="capacity.invalid && (capacity.dirty || capacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacity.errors.required">
                            Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Net Capacity <br />
                    <input type="text" class="textbox" placeholder="Net Capacity" [(ngModel)]="offtaker.designSpecs.netCapacity" name="netElectricalOut" #netElectricalOut="ngModel" />
                    <div *ngIf="netElectricalOut.invalid && (netElectricalOut.dirty || netElectricalOut.touched)" class="textbox alert alert-danger">
                        <div *ngIf="netElectricalOut.errors.required">
                            Net Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    No. of Plants <br />
                    <input type="number" class="textbox" placeholder="No. of Plants" [(ngModel)]="offtaker.designSpecs.nosOfPlants" name="nosOfGasTurbines" #nosOfGasTurbines="ngModel" />
                    <div *ngIf="nosOfGasTurbines.invalid && (nosOfGasTurbines.dirty || nosOfGasTurbines.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nosOfGasTurbines.errors.required">
                            No. of Plants is required
                        </div>
                    </div>
                </div>

                <div class="right textLabel">
                    Gas Requirements (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Gas Requirements (MMscf)" [(ngModel)]="offtaker.designSpecs.gasRequirements" name="gasRequirements" #gasRequirements="ngModel" />
                    <div *ngIf="gasRequirements.invalid && (gasRequirements.dirty || gasRequirements.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasRequirements.errors.required">
                            Gas Requirements is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    No. of Plant Type <br />
                    <input type="number" class="textbox" placeholder="No. of Plant Type" [(ngModel)]="offtaker.designSpecs.nosOfPlantType" name="nosOfSteamTurbines" #nosOfSteamTurbines="ngModel" />
                    <div *ngIf="nosOfSteamTurbines.invalid && (nosOfSteamTurbines.dirty || nosOfSteamTurbines.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nosOfSteamTurbines.errors.required">
                            No. of Plant Type is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Capacity of Plant Type <br />
                    <input type="text" class="textbox" placeholder="Capacity of Plant Type" [(ngModel)]="offtaker.designSpecs.capacityOfPlantType" name="capacitySteamTurbine" #capacitySteamTurbine="ngModel" />
                    <div *ngIf="capacitySteamTurbine.invalid && (capacitySteamTurbine.dirty || capacitySteamTurbine.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacitySteamTurbine.errors.required">
                            Capacity of Plant Type is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Capacity of Plant Type 2 <br />
                    <input type="text" class="textbox" placeholder="Capacity of Plant Type 2" [(ngModel)]="offtaker.designSpecs.capacityOfPlantType2" name="capacityOfGasTurbines" #capacityOfGasTurbines="ngModel" />
                    <div *ngIf="capacityOfGasTurbines.invalid && (capacityOfGasTurbines.dirty || capacityOfGasTurbines.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacityOfGasTurbines.errors.required">
                            Capacity of Plant Type 2 is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Plant OEM <br />
                    <input type="text" class="textbox" placeholder="Plant OEM" [(ngModel)]="offtaker.designSpecs.plantOEM" name="gasTurbinesOEM" #gasTurbinesOEM="ngModel" maxlength="50" />
                    <div *ngIf="gasTurbinesOEM.invalid && (gasTurbinesOEM.dirty || gasTurbinesOEM.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasTurbinesOEM.errors.required">
                            Plant OEM is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant 2 OEM <br />
                    <input type="textbox" class="textbox" placeholder="Plant 2 OEM" [(ngModel)]="offtaker.designSpecs.plant2OEM" name="steamTurbinesOEM" #steamTurbinesOEM="ngModel" maxlength="50" />
                    <div *ngIf="steamTurbinesOEM.invalid && (steamTurbinesOEM.dirty || steamTurbinesOEM.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steamTurbinesOEM.errors.required">
                            Plant 2 OEM is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Capacity of Black Start Generator  <br />
                    <input type="text" class="textbox" placeholder="Capacity of Black Start Generator" [(ngModel)]="offtaker.designSpecs.capacityOfBlackStart" name="capacityOfBlackStart" #capacityOfBlackStart="ngModel"  />
                    <div *ngIf="capacityOfBlackStart.invalid && (capacityOfBlackStart.dirty || capacityOfBlackStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="capacityOfBlackStart.errors.required">
                            Capacity of Black Start is required
                        </div>
                    </div>    
                </div>

                <div class="left textLabel">
                    Black Start Fuel  <br />
                    <input type="textbox" class="textbox" placeholder="Black Start Fuel" [(ngModel)]="offtaker.designSpecs.blackStartFuel" name="blackStartFuel" #blackStartFuel="ngModel" maxlength="150"  />
                    
                    <div *ngIf="blackStartFuel.invalid && (blackStartFuel.dirty || blackStartFuel.touched)" class="textbox alert alert-danger">
                        <div *ngIf="blackStartFuel.errors.required">
                            Black Start Fuel is required
                        </div>
                    </div>            
                </div>

                <div class="right textLabel">
                    Regulatory License Date  <br />
                    <input type="textbox" class="textbox" placeholder="Regulatory License Date" [nbDatepicker]="dp4" [(ngModel)]="regulatoryLicenseDateDt" name="regulatoryLicenseDate" #regulatoryLicenseDate="ngModel"  />
                    <nb-datepicker #dp4 format='M/D/yyyy'></nb-datepicker>       
                    <div *ngIf="regulatoryLicenseDate.invalid && (regulatoryLicenseDate.dirty || regulatoryLicenseDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="regulatoryLicenseDate.errors.required">
                            Regulatory License Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                   Target Customer <br />
                    <select class="textbox stateSelect" [(ngModel)]="offtaker.designSpecs.targetCustomer" name="targetCustomer" #targetCustomer="ngModel">
                        <option *ngFor="let a of offTakerLookups['Customer Target Customer']" value={{a}}>{{a}}</option>
                    </select>
                    <div *ngIf="targetCustomer.invalid && (targetCustomer.dirty || targetCustomer.touched)" class="textbox alert alert-danger">
                        <div *ngIf="targetCustomer.errors.required">
                            Target Customer is required
                        </div>
                    </div>               
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT MANAGEMENT
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <input type="textbox" class="textbox" placeholder="EPC Contractor" [(ngModel)]="offtaker.projectManagement.epcContractor" name="epcContractor" #epcContractor="ngModel" maxlength="50" />
                    <div *ngIf="epcContractor.invalid && (epcContractor.dirty || epcContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="epcContractor.errors.required">
                            EPC Contractor is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Project Execution Consultant <br />
                    <input type="textbox" class="textbox" placeholder="Project Execution Consultant" [(ngModel)]="offtaker.projectManagement.projectExecutionConsultant" name="projectExecutionConsultant" #projectExecutionConsultant="ngModel" maxlength="50" />
                    <div *ngIf="projectExecutionConsultant.invalid && (projectExecutionConsultant.dirty || projectExecutionConsultant.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectExecutionConsultant.errors.required">
                            Project Execution Consultant is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <input type="number" class="textbox" placeholder="Estimated Project Cost" [(ngModel)]="offtaker.projectManagement.estimatedProjCost" name="estimatedProjCost" #estimatedProjCost="ngModel" />
                    <div *ngIf="estimatedProjCost.invalid && (estimatedProjCost.dirty || estimatedProjCost.touched)" class="textbox alert alert-danger">
                        <div *ngIf="estimatedProjCost.errors.required">
                            Estimated Project Cost is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Project Start Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project Start Date" [nbDatepicker]="dp5" [(ngModel)]="projectStartDateDt" name="projectStartDate" #projectStartDate="ngModel"  />
                    <nb-datepicker #dp5 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="projectStartDate.invalid && (projectStartDate.dirty || projectStartDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectStartDate.errors.required">
                            Project Start Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row margin">
                <div class="left textLabel">
                    Project End Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project End Date" [nbDatepicker]="dp6" [(ngModel)]="projectEndDateDt" name="projectEndDate" #projectEndDate="ngModel"  />
                    <nb-datepicker #dp6 format='M/D/yyyy'></nb-datepicker>  
                    <div *ngIf="projectEndDate.invalid && (projectEndDate.dirty || projectEndDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectEndDate.errors.required">
                            Project End Date is required
                        </div>
                    </div>        
                </div>
                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project Commissioning Date" [nbDatepicker]="dp7" [(ngModel)]="projectCommissioningDateDt" name="projectCommissioningDate" #projectCommissioningDate="ngModel"  />
                    <nb-datepicker #dp7 format='M/D/yyyy'></nb-datepicker>  
                    <div *ngIf="projectCommissioningDate.invalid && (projectCommissioningDate.dirty || projectCommissioningDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="projectCommissioningDate.errors.required">
                            Project Commissioning Date is required
                        </div>
                    </div>        
                </div>
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <input type="textbox" class="textbox" placeholder="EIA/ESIA Approval Date" [nbDatepicker]="dp8" [(ngModel)]="eiaApprovalDateDt" name="eiaApprovalDate" #eiaApprovalDate="ngModel"  />
                    <nb-datepicker #dp8 format='M/D/yyyy'></nb-datepicker>    
                    <div *ngIf="eiaApprovalDate.invalid && (eiaApprovalDate.dirty || eiaApprovalDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="eiaApprovalDate.errors.required">
                            EIA/ESIA Approval Date is required
                        </div>
                    </div>        
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMISSIONING
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Certificate of Readiness <br />
                    <input type="textbox" class="textbox" placeholder="Certificate of Readiness" [(ngModel)]="offtaker.commissioning.certificateOfReadiness" name="certificateOfReadiness" #certificateOfReadiness="ngModel" maxlength="100" />
                    <div *ngIf="certificateOfReadiness.invalid && (certificateOfReadiness.dirty || certificateOfReadiness.touched)" class="textbox alert alert-danger">
                        <div *ngIf="certificateOfReadiness.errors.required">
                            Certificate of Readiness is required
                        </div>
                    </div>
                </div>

                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <input type="textbox" class="textbox" placeholder="Project Commissioning Date" [nbDatepicker]="dp9" [(ngModel)]="commissionDateDt" name="commissionDate" #commissionDate="ngModel"  />
                    <nb-datepicker #dp9 format='M/D/yyyy'></nb-datepicker>
                    <div *ngIf="commissionDate.invalid && (commissionDate.dirty || commissionDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="commissionDate.errors.required">
                            Project Commissioning Date is required
                        </div>
                    </div>        
                </div>

                <div class="left textLabel">
                    Commissioning Test Certificate <br />
                    <input type="radio" id="commissionTestCertificateYes" class="radio" value="yes" [(ngModel)]="offtaker.commissioning.commissionTestCertificate" name="commissionTestCertificate" #commissionTestCertificate="ngModel" />
                    <label for="commissionTestCertificateYes">Yes</label>
                    <input type="radio" id="commissionTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commissioning.commissionTestCertificate" name="commissionTestCertificate" #commissionTestCertificate="ngModel" />
                    <label for="commissionTestCertificateNo">No</label>
                </div>

                <div class="right textLabel">
                    Commissioning Certificate for Sync <br />
                    <input type="radio" id="commissionCertificateSyncYes" class="radio" value="yes" [(ngModel)]="offtaker.commissioning.commissionCertificateSync" name="commissionCertificateSync" />
                    <label for="commissionCertificateSyncYes">Yes</label>
                    <input type="radio" id="commissionCertificateSyncNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commissioning.commissionCertificateSync" name="commissionCertificateSync" />
                    <label for="commissionCertificateSyncNo">No</label>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Performance Test Certificate <br />
                    <input type="radio" id="turbinePerformanceTestCertificateYes" class="radio" value="yes" [(ngModel)]="offtaker.commissioning.performanceTestCertificate" name="turbinePerformanceTestCertificate" />
                    <label for="turbinePerformanceTestCertificateYes">Yes</label>
                    <input type="radio" id="turbinePerformanceTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commissioning.performanceTestCertificate" name="turbinePerformanceTestCertificate" />
                    <label for="turbinePerformanceTestCertificateNo">No</label>
                </div>

                <div class="left textLabel">
                    Reliability Run Test Certificate <br />
                    <input type="radio" id="reliabilityRunTestCertificateYes" class="radio" value="yes" [(ngModel)]="offtaker.commissioning.reliabilityRunTestCertificate" name="reliabilityRunTestCertificate" />
                    <label for="reliabilityRunTestCertificateYes">Yes</label>
                    <input type="radio" id="reliabilityRunTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commissioning.reliabilityRunTestCertificate" name="reliabilityRunTestCertificate" />
                    <label for="reliabilityRunTestCertificateNo">No</label>
                </div>

                <div class="right textLabel">
                    Capacity Test Certificate <br />
                    <input type="radio" id="capacityTestCertificateYes" class="radio" value="yes" [(ngModel)]="offtaker.commissioning.capacityTestCertificate" name="capacityTestCertificate" />
                    <label for="capacityTestCertificateYes">Yes</label>
                    <input type="radio" id="capacityTestCertificateNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commissioning.capacityTestCertificate" name="capacityTestCertificate" />
                    <label for="capacityTestCertificateNo">No</label>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PLANT OPERATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    O&M Contractor Name<br />
                    <input type="textbox" class="textbox" placeholder="O&M Contractor Name" [(ngModel)]="offtaker.plantOperations.onmContractor" name="qnmContractor" #qnmContractor="ngModel" maxlength="50" />
                    <div *ngIf="qnmContractor.invalid && (qnmContractor.dirty || qnmContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="qnmContractor.errors.required">
                            O&M Contractor Name is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                   Operations Start Date  <br />
                    <input type="textbox" class="textbox" placeholder="Operations Start Date" [nbDatepicker]="dp10" [(ngModel)]="operationStartDt" name="operationStart" #operationStart="ngModel"  />
                    <nb-datepicker #dp10 format='M/D/yyyy'></nb-datepicker>      
                    <div *ngIf="operationStart.invalid && (operationStart.dirty || operationStart.touched)" class="textbox alert alert-danger">
                        <div *ngIf="operationStart.errors.required">
                            Operations Start Date is required
                        </div>
                    </div>        
                </div>

                <div class="left textLabel">
                    Name of LTSA Contractor <br />
                    <input type="textbox" class="textbox" placeholder="Name of LTSA Contractor" [(ngModel)]="offtaker.plantOperations.nameOfLTSAContractor" name="nameOfLTSAContractor" #nameOfLTSAContractor="ngModel" maxlength="50" />
                    <div *ngIf="nameOfLTSAContractor.invalid && (nameOfLTSAContractor.dirty || nameOfLTSAContractor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="nameOfLTSAContractor.errors.required">
                            Name of LTSA Contractor is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Plant Inspections <br />
                    <input type="textbox" class="textbox" placeholder="Plant Inspections" [(ngModel)]="offtaker.plantOperations.plantInspections" name="turbineABCInspections" #turbineABCInspections="ngModel" maxlength="50" />
                    <div *ngIf="turbineABCInspections.invalid && (turbineABCInspections.dirty || turbineABCInspections.touched)" class="textbox alert alert-danger">
                        <div *ngIf="turbineABCInspections.errors.required">
                            Plant Inspections is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    CMMS Computerized Mtce Mgr<br />
                    <input type="textbox" class="textbox" placeholder="CMMS Computerized Mtce Mgr" [(ngModel)]="offtaker.plantOperations.cmmsComputerizedMtceMgr" name="cmmsComputerizedMtceMgr" #cmmsComputerizedMtceMgr="ngModel" maxlength="50" />
                    <div *ngIf="cmmsComputerizedMtceMgr.invalid && (cmmsComputerizedMtceMgr.dirty || cmmsComputerizedMtceMgr.touched)" class="textbox alert alert-danger">
                        <div *ngIf="cmmsComputerizedMtceMgr.errors.required">
                            CMMS Mtce Mgr is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Plant Availability Capacity <br />
                    <input type="number" class="textbox" placeholder="Plant Availability Capacity" [(ngModel)]="offtaker.plantOperations.plantAvailabilityCapacity" name="plantAvailabilityCapacity" #plantAvailabilityCapacity="ngModel" />
                    <div *ngIf="plantAvailabilityCapacity.invalid && (plantAvailabilityCapacity.dirty || plantAvailabilityCapacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="plantAvailabilityCapacity.errors.required">
                            Plant Availability Capacity is required
                        </div>
                    </div>
                </div>

                <!--<div class="left textLabel">
                    Plant Rating(MW) <br />
                    <input type="number" class="textbox" placeholder="Plant Rating" [(ngModel)]="offtaker.plantOperations.plantRating" name="plantRating" #plantRating="ngModel" />
                    <div *ngIf="plantRating.invalid && (plantRating.dirty || plantRating.touched)" class="textbox alert alert-danger">
                        <div *ngIf="plantRating.errors.required">
                            Plant Rating is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Terminal Voltage (KV) <br /> 
                    <input type="number" class="textbox" placeholder="Terminal Voltage" [(ngModel)]="offtaker.plantOperations.terminalVoltage" name="terminalVoltage" #terminalVoltage="ngModel" />
                    <div *ngIf="terminalVoltage.invalid && (terminalVoltage.dirty || terminalVoltage.touched)" class="textbox alert alert-danger">
                        <div *ngIf="terminalVoltage.errors.required">
                            Terminal Voltage is required
                        </div>
                    </div>
                </div>-->
            
                <div class="left textLabel">
                    Unit Efficiency (%) <br />
                    <input type="number" class="textbox" placeholder="Unit Efficiency" [(ngModel)]="offtaker.plantOperations.unitEfficiency" name="unitEfficiency" #unitEfficiency="ngModel" pattern="^[1-9][0-9]?$|^100$" />
                    <div *ngIf="unitEfficiency.invalid && (unitEfficiency.dirty || unitEfficiency.touched)" class="textbox alert alert-danger">
                        <div *ngIf="unitEfficiency.errors.required">
                            Unit Efficiency is required
                        </div>
                        <div *ngIf="unitEfficiency.errors.pattern">
                            Unit Efficiency can be b/w 1-100
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Reactive Power Requirement (MW) <br />
                    <input type="number" class="textbox" placeholder="Reactive Power Requirement" [(ngModel)]="offtaker.plantOperations.reactivePowerRequirement" name="reactivePowerRequirement" #reactivePowerRequirement="ngModel" />
                    <div *ngIf="reactivePowerRequirement.invalid && (reactivePowerRequirement.dirty || reactivePowerRequirement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="reactivePowerRequirement.errors.required">
                            Reactive Power Requirement is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Short Circuit Ratio <br />
                    <input type="textbox" class="textbox" placeholder="Short Circuit Ratio" [(ngModel)]="offtaker.plantOperations.shortCircuitRatio" name="shortCircuitRatio" #shortCircuitRatio="ngModel" maxlength="50" />
                    <div *ngIf="shortCircuitRatio.invalid && (shortCircuitRatio.dirty || shortCircuitRatio.touched)" class="textbox alert alert-danger">
                        <div *ngIf="shortCircuitRatio.errors.required">
                            Short Circuit Ratio is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Auxiliary Power Requirement (MW) <br /> 
                    <input type="number" class="textbox" placeholder="Auxiliary Power Requirement" [(ngModel)]="offtaker.plantOperations.auxilliaryPowerRequirement" name="auxilliaryPowerRequirement" #auxilliaryPowerRequirement="ngModel" />
                    <div *ngIf="auxilliaryPowerRequirement.invalid && (auxilliaryPowerRequirement.dirty || auxilliaryPowerRequirement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="auxilliaryPowerRequirement.errors.required">
                            Auxiliary Power Requirement is required
                        </div>
                    </div>
                </div>
            
                <div class="left textLabel">
                    Transformer Rated Capacity (MVA) <br />
                    <input type="number" class="textbox" placeholder="Transformer Rated Capacity" [(ngModel)]="offtaker.plantOperations.trasnformerRatedCapacity" name="trasnformerRatedCapacity" #trasnformerRatedCapacity="ngModel" />
                    <div *ngIf="trasnformerRatedCapacity.invalid && (trasnformerRatedCapacity.dirty || trasnformerRatedCapacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="trasnformerRatedCapacity.errors.required">
                            Transformer Rated Capacity is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Transformer Voltage Load Ratio(HV/LV) <br />
                    <input type="number" class="textbox" placeholder="Transformer Voltage Load Ratio" [(ngModel)]="offtaker.plantOperations.transformerVoltageLoadRatio" name="transformerVoltageLoadRatio" #transformerVoltageLoadRatio="ngModel" />
                    <div *ngIf="transformerVoltageLoadRatio.invalid && (transformerVoltageLoadRatio.dirty || transformerVoltageLoadRatio.touched)" class="textbox alert alert-danger">
                        <div *ngIf="transformerVoltageLoadRatio.errors.required">
                            Transformer Volt Load Ratio is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant Pressure (PSI) <br />
                    <input type="number" class="textbox" placeholder="Plant Turbine Pressure" [(ngModel)]="offtaker.plantOperations.plantPressure" name="steamTurbinePressure" #steamTurbinePressure="ngModel" />
                    <div *ngIf="steamTurbinePressure.invalid && (steamTurbinePressure.dirty || steamTurbinePressure.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steamTurbinePressure.errors.required">
                            Plant Pressure is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Plant Temperature <br /> 
                    <input type="text" class="textbox" placeholder="Plant Temperature" [(ngModel)]="offtaker.plantOperations.plantTemprature" name="steamTurbineTemprature" #steamTurbineTemprature="ngModel" />
                    <div *ngIf="steamTurbineTemprature.invalid && (steamTurbineTemprature.dirty || steamTurbineTemprature.touched)" class="textbox alert alert-danger">
                        <div *ngIf="steamTurbineTemprature.errors.required">
                            Plant Temperature is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Plant Noise Level (dBA) <br />
                    <input type="number" class="textbox" placeholder="Plant Noise Level" [(ngModel)]="offtaker.plantOperations.plantNoiseLevel" name="plantNoiseLevel" #plantNoiseLevel="ngModel" />
                    <div *ngIf="plantNoiseLevel.invalid && (plantNoiseLevel.dirty || plantNoiseLevel.touched)" class="textbox alert alert-danger">
                        <div *ngIf="plantNoiseLevel.errors.required">
                            Plant Noise Level is required
                        </div>
                    </div>
                </div>
           
            </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel class="marginTop50px"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMERCIAL OPERATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>        
            
            <div class="row margin">    
                <div class="left textLabel">
                    Natural Gas Supplier <br />
                    <input type="textbox" class="textbox" placeholder="Natural Gas Supplier" [(ngModel)]="offtaker.commercialOperations.naturalGasSupplier" name="naturalGasSupplier" #naturalGasSupplier="ngModel" maxlength="50" />
                    <div *ngIf="naturalGasSupplier.invalid && (naturalGasSupplier.dirty || naturalGasSupplier.touched)" class="textbox alert alert-danger">
                        <div *ngIf="naturalGasSupplier.errors.required">
                            Natural Gas Supplier is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Gas Contract Quantity (MMscf) <br />
                    <input type="number" class="textbox" placeholder="Gas Contract Quantity" [(ngModel)]="offtaker.commercialOperations.gasContractQuantity" name="gasContractQuantity" #gasContractQuantity="ngModel" />
                    <div *ngIf="gasContractQuantity.invalid && (gasContractQuantity.dirty || gasContractQuantity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasContractQuantity.errors.required">
                            Gas Contract Quantity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    Average Daily Gas Consumption <br />
                    <input type="number" class="textbox" placeholder="Average Daily Gas Consumption" [(ngModel)]="offtaker.commercialOperations.avgDailyGasConsumption" name="avgDailyGasConsumption" #avgDailyGasConsumption="ngModel" />
                    <div *ngIf="avgDailyGasConsumption.invalid && (avgDailyGasConsumption.dirty || avgDailyGasConsumption.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgDailyGasConsumption.errors.required">
                            Avg Daily Gas Consumption is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    Gas Transportation Agreement <br />
                    <input type="textbox" class="textbox" placeholder="Gas Transportation Agreement" [(ngModel)]="offtaker.commercialOperations.gasTransportationAgreement" name="gasTransportationAgreement" #gasTransportationAgreement="ngModel" maxlength="50" />
                    <div *ngIf="gasTransportationAgreement.invalid && (gasTransportationAgreement.dirty || gasTransportationAgreement.touched)" class="textbox alert alert-danger">
                        <div *ngIf="gasTransportationAgreement.errors.required">
                            Transportation Agreement is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">    
                <div class="left textLabel">
                    PPA for Power Expert <br />
                    <input type="radio" id="ppaForPowerExportYes" class="radio" value="yes" [(ngModel)]="offtaker.commercialOperations.ppaForPowerExport" name="ppaForPowerExport" #ppaForPowerExport="ngModel" />
                    <label for="ppaForPowerExportYes">Yes</label>
                    <input type="radio" id="ppaForPowerExportNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commercialOperations.ppaForPowerExport" name="ppaForPowerExport" #ppaForPowerExport="ngModel" />
                    <label for="ppaForPowerExportNo">No</label>
                </div>
                <div class="left textLabel">
                    PPA Contract Capacity(MW) <br />
                    <input type="number" class="textbox" placeholder="PPA Contract Capacity" [(ngModel)]="offtaker.commercialOperations.ppaContractCapacity" name="ppaContractCapacity" #ppaContractCapacity="ngModel" />
                    <div *ngIf="ppaContractCapacity.invalid && (ppaContractCapacity.dirty || ppaContractCapacity.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaContractCapacity.errors.required">
                            PPA Contract Capacity is required
                        </div>
                    </div>
                </div>
                <div class="left textLabel">
                    PPA Customer <br />
                    <input type="textbox" class="textbox" placeholder="PPA Customer" [(ngModel)]="offtaker.commercialOperations.ppaCustomer" name="ppaCustomer" #ppaCustomer="ngModel" />
                    <div *ngIf="ppaCustomer.invalid && (ppaCustomer.dirty || ppaCustomer.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaCustomer.errors.required">
                            PPA Customer is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                    PPA Effective Date  <br />
                    <input type="textbox" class="textbox" placeholder="PPA Effective Date" [nbDatepicker]="dp12" [(ngModel)]="ppaEffectiveDateDt" name="ppaEffectiveDate" #ppaEffectiveDate="ngModel"  />
                    <nb-datepicker #dp12 format='M/D/yyyy'></nb-datepicker>      
                    <div *ngIf="ppaEffectiveDate.invalid && (ppaEffectiveDate.dirty || ppaEffectiveDate.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaEffectiveDate.errors.required">
                            PPA Effective Date is required
                        </div>
                    </div>        
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    PPA Term (Years) <br />
                    <input type="number" class="textbox" placeholder="PPA Term" [(ngModel)]="offtaker.commercialOperations.ppaTerm" name="ppaTerm" #ppaTerm="ngModel" />
                    <div *ngIf="ppaTerm.invalid && (ppaTerm.dirty || ppaTerm.touched)" class="textbox alert alert-danger">
                        <div *ngIf="ppaTerm.errors.required">
                            PPA Term is required
                        </div>
                    </div>
                </div>   
                <div class="left textLabel">
                    PPA Securitized <br />
                    <input type="radio" id="ppaSecuritizedYes" class="radio" value="yes" [(ngModel)]="offtaker.commercialOperations.ppaSecuritized" name="ppaSecuritized" #ppaSecuritized="ngModel" />
                    <label for="ppaSecuritizedYes">Yes</label>
                    <input type="radio" id="ppaSecuritizedNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commercialOperations.ppaSecuritized" name="ppaSecuritized" #ppaSecuritized="ngModel" />
                    <label for="ppaSecuritizedNo">No</label>
                </div>               
                <div class="left textLabel">
                    Avg.Load Factor (%)  <br />
                    <input type="number" class="textbox" placeholder="Avg.Load Factor" [(ngModel)]="offtaker.commercialOperations.avgLoadFactor" name="avgLoadFactor" #avgLoadFactor="ngModel" pattern="^[1-9][0-9]?$|^100$" />
                    <div *ngIf="avgLoadFactor.invalid && (avgLoadFactor.dirty || avgLoadFactor.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgLoadFactor.errors.required">
                            Avg.Load Factor is required
                        </div>
                        <div *ngIf="avgLoadFactor.errors.pattern">
                            Avg. Load Factor % can be b/w 1-100
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                   Avg Capacity Availability  <br />
                    <input type="number" class="textbox" placeholder="Avg Capacity Availability" [(ngModel)]="offtaker.commercialOperations.avgCapacityAvailability" name="avgCapacityAvailability" #avgCapacityAvailability="ngModel" />
                    <div *ngIf="avgCapacityAvailability.invalid && (avgCapacityAvailability.dirty || avgCapacityAvailability.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgCapacityAvailability.errors.required">
                            Avg Capacity Availability is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    Average NEO (kw/hr) <br />
                    <input type="number" class="textbox" placeholder="Average NEO" [(ngModel)]="offtaker.commercialOperations.avgNEO" name="avgNEO" #avgNEO="ngModel" />
                    <div *ngIf="avgNEO.invalid && (avgNEO.dirty || avgNEO.touched)" class="textbox alert alert-danger">
                        <div *ngIf="avgNEO.errors.required">
                            Average NEO is required
                        </div>
                    </div>
                </div>   
                <div class="left textLabel">
                    (CER) Certified Emission Reduce <br />
                    <input type="radio" id="certifiedEmissionRatioYes" class="radio" value="yes" [(ngModel)]="offtaker.commercialOperations.certifiedEmissionRatio" name="certifiedEmissionRatio" #certifiedEmissionRatio="ngModel" />
                    <label for="certifiedEmissionRatioYes">Yes</label>
                    <input type="radio" id="certifiedEmissionRatioNo" class="radio radiomargin" value="no" [(ngModel)]="offtaker.commercialOperations.certifiedEmissionRatio" name="certifiedEmissionRatio" #certifiedEmissionRatio="ngModel" />
                    <label for="certifiedEmissionRatioNo">No</label>
                </div>               
                <div class="left textLabel">
                    Notes  <br />
                    <textarea type="textbox" class="textbox" placeholder="Notes" [(ngModel)]="offtaker.commercialOperations.notes" name="notes" #notes="ngModel"></textarea>
                    <div *ngIf="notes.invalid && (notes.dirty || notes.touched)" class="textbox alert alert-danger">
                        <div *ngIf="notes.errors.required">
                            Notes is required
                        </div>
                    </div>
                </div>
                <div class="right textLabel">
                   Profile  <br />
                    <textarea type="textbox" class="textbox" placeholder="Profile" [(ngModel)]="offtaker.commercialOperations.profile" name="profile" #profile="ngModel"></textarea>
                    <div *ngIf="profile.invalid && (profile.dirty || profile.touched)" class="textbox alert alert-danger">
                        <div *ngIf="profile.errors.required">
                            Profile is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    Passcode <br />
                    <input type="textbox" class="textbox" placeholder="Passcode" [(ngModel)]="offtaker.passcode" name="passcode" #passcode="ngModel" required />
                    <div *ngIf="passcode.invalid && (passcode.dirty || passcode.touched)" class="textbox alert alert-danger">
                        <div *ngIf="passcode.errors.required">
                            Passcode is required
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <div class="textLabel">
            &nbsp; <br />
            <button type="submit" class="button blueButton" [disabled]="offTakerForm.invalid || isSubmitted" (click)="saveFacility('4')" value="Save" ><span *ngIf="!isSubmitted">Save</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
            <input type="submit" class="button whiteButton grey" [disabled]="isSubmitted" (click)="navigate()" value="Cancel" />
        </div>
    </form>
</div>
