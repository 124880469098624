import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServiceService } from './../../../service/auth-service/auth-service.service';
import { environment } from './../../../../environments/environment';
import { of } from 'rxjs';

@Component({
  selector: 'ngx-review-addedit',
  templateUrl: './review-addedit.component.html',
  styleUrls: ['./review-addedit.component.scss']
})
export class ReviewAddeditComponent implements OnInit {

  constructor(private router:Router, private activatedRoute:ActivatedRoute, private service: AuthServiceService) { }
  gasplant;
  powerplant;
  offtaker;
  pipeline;
  facilityId;
  facility;
  dateTransferOwnerDt;
  commissionDateDt;
  constructionEndDt;
  constructionStartDt;
  operationStartDt;
  eiaApprovalDateDt;
  regulatoryLicenseDateDt;
  projectEndDateDt;
  projectStartDateDt;
  projectCommissioningDateDt;
  ppaEffectiveDateDt;
  leaksDt;
  isSubmitted=false;
  async ngOnInit() {
    if(this.activatedRoute.snapshot.paramMap.get('id')){
      this.facilityId = this.activatedRoute.snapshot.paramMap.get('id');
      this.facility = await this.service.fetchFacility(this.facilityId);
      if(this.facility.type==1){
        this.gasplant = this.facility;        
      } else if(this.facility.type==2){
        this.powerplant = this.facility;        
      } else if(this.facility.type==3){
        this.pipeline = this.facility;        
      } else {
        this.offtaker = this.facility;
      }
    }
  }

  breakStringToKeywords(facilityBasic){
    let arrName=[];
    let curName='';
    for(let facilityName in facilityBasic){
      if(typeof(facilityBasic[facilityName]) == 'string'){
        facilityBasic[facilityName].toLowerCase().split('').forEach((letter)=>{
          curName+=letter;
          arrName.push(curName);
        })
        facilityBasic[facilityName].toLowerCase().split(' ').forEach((str, index) => {
          curName='';
          if(index!=0){
            str.split('').forEach(letter => {
              curName+=letter;
              arrName.push(curName);
            });
          }      
        });
      }
    }    
    return arrName;
  }

  async saveFacility(accept){
    this.isSubmitted=true;
    this.facility.inReview=2;
    if(accept){      
      if(this.facility.type==1){
        this.gasplant.basic=JSON.parse(JSON.stringify(this.gasplant.basicInreview));
        this.gasplant.keywords = this.breakStringToKeywords(this.gasplant.basic);
        this.gasplant.additional=JSON.parse(JSON.stringify(this.gasplant.additionalInreview));
        this.gasplant.updatedByOwner = true;
        this.gasplant.inReview=1;
      }
      else if(this.facility.type==2){
        this.powerplant.basic = JSON.parse(JSON.stringify(this.powerplant.basicInreview));
        this.powerplant.keywords = this.breakStringToKeywords(this.powerplant.basic);
        this.powerplant.designSpecs = JSON.parse(JSON.stringify(this.powerplant.designSpecsInreview));
        this.powerplant.projectManagement = JSON.parse(JSON.stringify(this.powerplant.projectManagementInreview));
        this.powerplant.commissioning = JSON.parse(JSON.stringify(this.powerplant.commissioningInreview));
        this.powerplant.commercialOperations = JSON.parse(JSON.stringify(this.powerplant.commercialOperationsInreview));
        this.powerplant.plantOperations = JSON.parse(JSON.stringify(this.powerplant.plantOperationsInreview));
        this.powerplant.updatedByOwner=true;
        this.powerplant.inReview=1;
      } else if(this.facility.type==3){
        this.pipeline.basic = JSON.parse(JSON.stringify(this.pipeline.basicInreview));
        this.pipeline.keywords = this.breakStringToKeywords(this.pipeline.basic);
        this.pipeline.designSpecs = JSON.parse(JSON.stringify(this.pipeline.designSpecsInreview));
        this.pipeline.operatingConditions = JSON.parse(JSON.stringify(this.pipeline.operatingConditionsInreview));
        this.pipeline.routing = JSON.parse(JSON.stringify(this.pipeline.routingInreview));
        this.pipeline.projectExecution = JSON.parse(JSON.stringify(this.pipeline.projectExecutionInreview));
        this.pipeline.operatingHistory = JSON.parse(JSON.stringify(this.pipeline.operatingHistoryInreview));
        this.pipeline.others = JSON.parse(JSON.stringify(this.pipeline.othersInreview));
        this.pipeline.updatedByOwner=true;
        this.pipeline.inReview=1;
      } else if(this.facility.type==4){
        this.offtaker.basic = JSON.parse(JSON.stringify(this.offtaker.basicInreview));
        this.offtaker.keywords = this.breakStringToKeywords(this.offtaker.basic);
        this.offtaker.designSpecs = JSON.parse(JSON.stringify(this.offtaker.designSpecsInreview));
        this.offtaker.projectManagement = JSON.parse(JSON.stringify(this.offtaker.projectManagementInreview));
        this.offtaker.commissioning = JSON.parse(JSON.stringify(this.offtaker.commissioningInreview));
        this.offtaker.commercialOperations = JSON.parse(JSON.stringify(this.offtaker.commercialOperationsInreview));
        this.offtaker.plantOperations = JSON.parse(JSON.stringify(this.offtaker.plantOperationsInreview));
        this.offtaker.updatedByOwner=true;
        this.offtaker.inReview=1;
      }
    }
    if(this.facility.type==1){
      delete this.gasplant.additionalInreview;
      delete this.gasplant.basicInreview;
    } else if(this.facility.type==2){
      delete this.powerplant.basicInreview;
      delete this.powerplant.designSpecsInreview;
      delete this.powerplant.projectManagementInreview;
      delete this.powerplant.commissioningInreview;
      delete this.powerplant.commercialOperationsInreview;
      delete this.powerplant.plantOperationsInreview;
    } else if(this.facility.type==3){
      delete this.pipeline.basicInreview;
      delete this.pipeline.designSpecsInreview;
      delete this.pipeline.operatingConditionsInreview;
      delete this.pipeline.routingInreview;
      delete this.pipeline.projectExecutionInreview;
      delete this.pipeline.operatingHistorInreview;
      delete this.pipeline.othersInreview;
    } else if(this.facility.type==4){
      delete this.offtaker.basicInreview;
      delete this.offtaker.designSpecsInreview;
      delete this.offtaker.projectManagementInreview;
      delete this.offtaker.commissioningInreview;
      delete this.offtaker.commercialOperationsInreview;
      delete this.offtaker.plantOperationsInreview;
    }

    if(!this.facility.basic.facilityName){
      //delete facility
      await this.service.daleteFacility(this.facilityId);
      this.isSubmitted=false;
      this.router.navigate(['/pages/reviewfacilities'])
    } else if(this.facility.type==1){
      await this.service.saveFacilityForReview(this.gasplant);   
      this.isSubmitted=false;
      this.router.navigate(['/pages/reviewfacilities'])   
      window.open(environment.baseUrl+'facilitydetails/'+this.gasplant.id+'/pdf/true', '_self');
    } else if(this.facility.type==2){
      await this.service.saveFacilityForReview(this.powerplant);
      this.isSubmitted=false;
      this.router.navigate(['/pages/reviewfacilities'])
      window.open(environment.baseUrl+'facilitydetails/'+this.powerplant.id+'/pdf/true', '_self');
    } else if(this.facility.type==3){
      await this.service.saveFacilityForReview(this.pipeline);
      this.isSubmitted=false;
      this.router.navigate(['/pages/reviewfacilities'])
      window.open(environment.baseUrl+'facilitydetails/'+this.pipeline.id+'/pdf/true', '_self');
      
    } else if(this.facility.type==4){
      await this.service.saveFacilityForReview(this.offtaker);
      this.isSubmitted=false;
      this.router.navigate(['/pages/reviewfacilities'])
      window.open(environment.baseUrl+'facilitydetails/'+this.offtaker.id+'/pdf/true', '_self');
    }
  }
}
