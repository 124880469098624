import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServiceService } from './../../../service/auth-service/auth-service.service';
import {NbMomentDateService} from '@nebular/moment';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'ngx-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss'],
  providers:[NbMomentDateService]
})
export class AddEditComponent implements OnInit {
  facility:any;
  constructor(private router:Router, private activatedRoute:ActivatedRoute, private service: AuthServiceService, private moment:NbMomentDateService) { 
    this.service.fetchLookups('',['Gas Plant Classification', 'Gas Plant Output','Gas Plant Owner Type', 'Gas Plant Processing Technology', 'Gas Plant Region', 'Gas Plant Status', 'Gas Plant Target Market', 'Gas Plant Type']).subscribe((data:any)=>{
      let a = this.arrToObj(JSON.parse(JSON.stringify(data)));
      this.gasPlantLookups = a;
    });

    this.service.fetchLookups('',['Power Plant Owner Type', 'Power Plant Region','Power Plant Target Customer', 'Power Plant Classification', 'Power Plant Type', 'Power Plant Status']).subscribe((data:any)=>{
      let a = this.arrToObj(JSON.parse(JSON.stringify(data)));
      this.powerPlantLookups = a;
    });

    this.service.fetchLookups('',['Customer Owner Type', 'Customer Region','Customer Target Customer', 'Customer Classification', 'Customer Type', 'Customer Plant Status']).subscribe((data:any)=>{
      let a = this.arrToObj(JSON.parse(JSON.stringify(data)));
      this.offTakerLookups = a;
    });

    this.service.fetchLookups('',['Pipeline Owner Type', 'Pipeline Status','Pipeline Purpose', 'Pipeline Status', 'Pipeline Terrain', 'Pipeline Market Delivery', 'Pipeline Region', 'Pipeline Material', 'Pipeline Steel Type', 'Pipeline Gas Type']).subscribe((data:any)=>{
      let a = this.arrToObj(JSON.parse(JSON.stringify(data)));
      this.service.fetchLookups('',['Pipeline Type of Corrosion Management', 'Pipeline Leak Type', 'Pipeline Pipe Class', 'Pipeline Wall Thickness', 'Pipeline Classification', 'Pipeline Project Status']).subscribe((data1:any)=>{
        this.pipelineLookups = {...a, ...this.arrToObj(JSON.parse(JSON.stringify(data1)))};
      })      
    });
  }
  facilityId='';
  gasPlantLookups:any={};
  gasplant={
    basic:{
      gpslocation:'',
      operator:'',
      owner:'',
      dateTransferOwner:'',
      linkedInAccount:'',
      companyWebsite:'',
      ownerType:'',
      location:'',
      country:'',
      nationalRegulatoryAgency:'',
      facilityName:'',
      nationalPolicyAdmin:'',
      projectInvestors:'',
      projectFinancier:'',
      classification:'',
      gasPlantStatus:'',
      region:''
    },
    additional:{
      capacity:'',
      nosProcessingTraining:'',
      capacityOfTraining:'',
      supplyFields:'',
      oml:'',
      gasReserveEstimates:'',
      supplyInletPressure:'',
      deliveryPressurePipeline:'',
      epcContractor:'',
      constructionStart:'',
      constructionEnd:'',
      commissionDate:'',
      operationStart:'',
      estimatedProjCost:'',
      eiaApprovalDate:'',
      onmOperator:'',
      gasplantType:'',
      gasplantOutput:'',
      targetMarket:'',
      exportPipeline:'',
      sizeExportPipeline:'',
      processingTech:'',
      notes:'',
      profile:'',
    },
    passcode:Math.random().toString(36).slice(-8),
    keywords:[],
    ownerId:'',
    id:'',
    type:'1', //1 'Gas Plant', '2' Power Plant, '3' Pipeline
    isAdminPrivate:false,
    isOwnerPrivate:false,
    inReview:1
  };
  
  powerPlantLookups:any={};
  powerplant={
    basic:{
      gpslocation:'',
      operator:'',
      owner:'',
      dateTransferOwner:'',
      linkedInAccount:'',
      companyWebsite:'',
      ownerType:'',
      location:'',
      country:'',
      nationalRegulatoryAgency:'',
      facilityName:'',
      nationalPolicyAdmin:'',
      projectInvestors:'',
      projectFinancier:'',
      classification:'',
      powerPlantType:'',
      powerPlantStatus:'',
      region:''
    },
    designSpecs:{
      capacity:'',
      netElectricalOut:'',
      nosOfGasTurbines:'',
      gasRequirements:'',
      nosOfSteamTurbines:'',
      capacitySteamTurbine:'',
      capacityOfGasTurbines:'',
      gasTurbinesOEM:'',
      steamTurbinesOEM:'',
      capacityOfBlackStart:'',
      blackStartFuel:'',
      regulatoryLicenseDate:'',
      targetCustomer:''
    },
    projectManagement:{
      epcContractor:'',
      projectExecutionConsultant:'',
      estimatedProjCost:'',
      projectStartDate:'',
      projectEndDate:'',
      projectCommissioningDate:'',
      eiaApprovalDate:''
    },
    commissioning:{
      certificateOfReadiness:'',
      commissionDate:'',
      commissionTestCertificate:'yes',
      commissionCertificateSync:'yes',
      turbinePerformanceTestCertificate:'yes',
      reliabilityRunTestCertificate:'yes',
      capacityTestCertificate:'yes'
    },
    plantOperations:{
      onmContractor:'',
      operationStart:'',
      nameOfLTSAContractor:'',
      turbineABCInspections:'',
      cmmsComputerizedMtceMgr:'',
      plantAvailabilityCapacity:'',
      plantRating:'',
      terminalVoltage:'',
      unitEfficiency:'',
      reactivePowerRequirement:'',
      shortCircuitRatio:'',
      auxilliaryPowerRequirement:'',
      trasnformerRatedCapacity:'',
      transformerVoltageLoadRatio:'',
      steamTurbinePressure:'',
      steamTurbineTemprature:'',
      plantNoiseLevel:''
    },
    commercialOperations:{
      naturalGasSupplier:'',
      gasContractQuantity:'',
      avgDailyGasConsumption:'',
      gasTransportationAgreement:'',
      ppaForPowerExport:'yes',
      ppaContractCapacity:'',
      ppaCustomer:'',
      ppaEffectiveDate:'',
      ppaTerm:'',
      ppaSecuritized:'yes',
      avgLoadFactor:'',
      avgCapacityAvailability:'',
      avgNEO:'',
      certifiedEmissionRatio:'yes',
      notes:'',
      profile:''
    },
    keywords:[],
    passcode:Math.random().toString(36).slice(-8),
    ownerId:'',
    id:'',
    type:'2', //1 'Gas Plant', '2' Power Plant, '3' Pipeline
    isAdminPrivate:false,
    isOwnerPrivate:false,
    inReview:1
  };
  offTakerLookups:any={};
  offtaker={
    basic:{
      gpslocation:'',
      operator:'',
      owner:'',
      dateTransferOwner:'',
      linkedInAccount:'',
      companyWebsite:'',
      ownerType:'',
      location:'',
      country:'',
      nationalRegulatoryAgency:'',
      facilityName:'',
      nationalPolicyAdmin:'',
      projectInvestors:'',
      projectFinancier:'',
      classification:'',
      powerPlantType:'',
      plantStatus:'',
      region:''
    },
    designSpecs:{
      capacity:'',
      netCapacity:'',
      nosOfPlants:'',
      gasRequirements:'',
      nosOfPlantType:'',
      capacityOfPlantType:'',
      capacityOfPlantType2:'',
      plantOEM:'',
      plant2OEM:'',
      capacityOfBlackStart:'',
      blackStartFuel:'',
      regulatoryLicenseDate:'',
      targetCustomer:''
    },
    projectManagement:{
      epcContractor:'',
      projectExecutionConsultant:'',
      estimatedProjCost:'',
      projectStartDate:'',
      projectEndDate:'',
      projectCommissioningDate:'',
      eiaApprovalDate:''
    },
    commissioning:{
      certificateOfReadiness:'',
      commissionDate:'',
      commissionTestCertificate:'yes',
      commissionCertificateSync:'yes',
      performanceTestCertificate:'yes',
      reliabilityRunTestCertificate:'yes',
      capacityTestCertificate:'yes'
    },
    plantOperations:{
      onmContractor:'',
      operationStart:'',
      nameOfLTSAContractor:'',
      plantInspections:'',
      cmmsComputerizedMtceMgr:'',
      plantAvailabilityCapacity:'',
      /*plantRating:'',
      terminalVoltage:'',*/
      unitEfficiency:'',
      reactivePowerRequirement:'',
      shortCircuitRatio:'',
      auxilliaryPowerRequirement:'',
      trasnformerRatedCapacity:'',
      transformerVoltageLoadRatio:'',
      plantPressure:'',
      plantTemprature:'',
      plantNoiseLevel:''
    },
    commercialOperations:{
      naturalGasSupplier:'',
      gasContractQuantity:'',
      avgDailyGasConsumption:'',
      gasTransportationAgreement:'',
      ppaForPowerExport:'yes',
      ppaContractCapacity:'',
      ppaCustomer:'',
      ppaEffectiveDate:'',
      ppaTerm:'',
      ppaSecuritized:'yes',
      avgLoadFactor:'',
      avgCapacityAvailability:'',
      avgNEO:'',
      certifiedEmissionRatio:'yes',
      notes:'',
      profile:''
    },
    keywords:[],
    passcode:Math.random().toString(36).slice(-8),
    ownerId:'',
    id:'',
    type:'4', //1 'Gas Plant', '2' Power Plant, '3' Pipeline
    isAdminPrivate:false,
    isOwnerPrivate:false,
    inReview:1
  };

  pipelineLookups:any={};
  pipeline={
    basic:{
      gpslocation:'',
      operator:'',
      owner:'',
      dateTransferOwner:'',
      linkedInAccount:'',
      companyWebsite:'',
      ownerType:'',
      location:'',
      country:'',
      nationalRegulatoryAgency:'',
      facilityName:'',
      nationalPolicyAdmin:'',
      projectInvestors:'',
      projectFinancier:'',
      classification:'',
      pipelineStatus:'',
      region:''
    },
    designSpecs:{
      sizeDiameter:'',
      maxVelocity:'',
      capacity:'',
      material:'',
      designLife:'',
      wallThicknessSchedule:'',
      steelType:'',
      pipeClass:'',
      pipeSchedule:'',
      pipeThickness:'',
    },
    operatingConditions:{
      designPressure:'',
      inletPressure:'',
      deliveryPressure:'',
      operatingTemp:'',
      gasType:''
    },
    routing:{
      tieInPointCoordinates:'',
      from:'',
      to:'',
      terrain:'',
      Length:'',
      rowWidth:''
    },
    projectExecution:{
      epcContractor:'',
      constructionStart:'',
      constructionEnd:'',
      operationStart:'',
      estimatedCost:'',
      derating:'',
      status:'',
      eiaApprovalDate:''
    },
    operatingHistory:{
      pipelinePurpose:'',
      onm:'',
      leaks:'',
      leakType:'',
      marketDelivery:'',
      compressorStations:'',
      piggingStations:'',
      valveStations:''
    },
    others:{
      odorized:'yes',
      pipelineMarkers:'yes',
      openAccess:'yes',
      riverCrossing:'yes',
      linkedToCityGate:'yes',
      linkedToCustomer:'yes',
      linkedToProcessingPlant:'yes',
      linkedToManifold:'yes',
      interconnectedToOtherPipeline:'yes',
      pipeCoating:'yes',
      corrosionManagement:'yes',
      typeOfCorrosionManagement:'',
      riversCrossed:'',
      nameOfCustomer:'',
      nameOfCityGate:'',
      nameOfProcessingPlant:'',
      notes:'',
      profile:''
    },
    passcode:Math.random().toString(36).slice(-8),
    keywords:[],
    ownerId:'',
    id:'',
    type:'3', //1 'Gas Plant', '2' Power Plant, '3' Pipeline
    isAdminPrivate:false,
    isOwnerPrivate:false,
    inReview:1
  };

  
  isSubmitted=false;

  //Date Variables in all types of facilities
  dateTransferOwnerDt;
  commissionDateDt;
  constructionEndDt;
  constructionStartDt;
  operationStartDt;
  eiaApprovalDateDt;
  regulatoryLicenseDateDt;
  projectEndDateDt;
  projectStartDateDt;
  projectCommissioningDateDt;
  ppaEffectiveDateDt;
  leaksDt;
  corps=[];
  //Date Variables in all types of facilities

  countries=[];
  async ngOnInit() {
    let usersCorp:any = await this.service.fetchAllUsers();
    if(usersCorp)
    for(let usrs of usersCorp){
      if(usrs.type==2){
        this.corps.push(usrs);
      }
    }
    this.countries = await this.service.fetchCountries();
    if(this.activatedRoute.snapshot.paramMap.get('id')){
      this.facilityId = this.activatedRoute.snapshot.paramMap.get('id');
      this.facility = await this.service.fetchFacility(this.facilityId);
      if(this.facility.type==1){        
        this.gasplant = this.facility;
        this.moment.parse(this.gasplant.basic.dateTransferOwner,'M/D/yyyy')
        //console.log("Date==>", this.moment.parse(this.gasplant.basic.dateTransferOwner,'M/D/yyyy'));
        this.dateTransferOwnerDt = this.gasplant.basic.dateTransferOwner?this.moment.parse(this.gasplant.basic.dateTransferOwner,'M/D/yyyy'):'';
        this.commissionDateDt = this.gasplant.additional.commissionDate?this.moment.parse(this.gasplant.additional.commissionDate,'M/D/yyyy'):'';
        this.constructionEndDt = this.gasplant.additional.constructionEnd?this.moment.parse(this.gasplant.additional.constructionEnd,'M/D/yyyy'):'';
        this.constructionStartDt = this.gasplant.additional.constructionStart?this.moment.parse(this.gasplant.additional.constructionStart,'M/D/yyyy'):'';
        this.operationStartDt = this.gasplant.additional.operationStart?this.moment.parse(this.gasplant.additional.operationStart,'M/D/yyyy'):'';
        this.eiaApprovalDateDt = this.gasplant.additional.eiaApprovalDate?this.moment.parse(this.gasplant.additional.eiaApprovalDate,'M/D/yyyy'):'';
      } else if(this.facility.type==2){        
        this.powerplant = this.facility;
        this.dateTransferOwnerDt = this.powerplant.basic.dateTransferOwner?this.moment.parse(this.powerplant.basic.dateTransferOwner,'M/D/yyyy'):'';
        this.eiaApprovalDateDt = this.powerplant.projectManagement.eiaApprovalDate?this.moment.parse(this.powerplant.projectManagement.eiaApprovalDate,'M/D/yyyy'):'';
        this.regulatoryLicenseDateDt = this.powerplant.designSpecs.regulatoryLicenseDate?this.moment.parse(this.powerplant.designSpecs.regulatoryLicenseDate,'M/D/yyyy'):'';
        this.projectEndDateDt = this.powerplant.projectManagement.projectEndDate?this.moment.parse(this.powerplant.projectManagement.projectEndDate,'M/D/yyyy'):'';
        this.projectStartDateDt = this.powerplant.projectManagement.projectStartDate?this.moment.parse(this.powerplant.projectManagement.projectStartDate,'M/D/yyyy'):'';
        this.projectCommissioningDateDt = this.powerplant.projectManagement.projectCommissioningDate?this.moment.parse(this.powerplant.projectManagement.projectCommissioningDate,'M/D/yyyy'):'';
        this.commissionDateDt = this.powerplant.commissioning.commissionDate?this.moment.parse(this.powerplant.commissioning.commissionDate,'M/D/yyyy'):'';
        this.operationStartDt = this.powerplant.plantOperations.operationStart?this.moment.parse(this.powerplant.plantOperations.operationStart,'M/D/yyyy'):'';
        this.ppaEffectiveDateDt = this.powerplant.commercialOperations.ppaEffectiveDate?this.moment.parse(this.powerplant.commercialOperations.ppaEffectiveDate,'M/D/yyyy'):'';
      } else if(this.facility.type == 3){        
        this.pipeline = this.facility;
        this.dateTransferOwnerDt = this.pipeline.basic.dateTransferOwner?this.moment.parse(this.pipeline.basic.dateTransferOwner,'M/D/yyyy'):'';
        this.eiaApprovalDateDt = this.pipeline.projectExecution.eiaApprovalDate?this.moment.parse(this.pipeline.projectExecution.eiaApprovalDate,'M/D/yyyy'):'';
        this.constructionStartDt = this.pipeline.projectExecution.constructionStart?this.moment.parse(this.pipeline.projectExecution.constructionStart,'M/D/yyyy'):'';
        this.constructionEndDt = this.pipeline.projectExecution.constructionEnd?this.moment.parse(this.pipeline.projectExecution.constructionEnd,'M/D/yyyy'):'';
        this.operationStartDt = this.pipeline.projectExecution.operationStart?this.moment.parse(this.pipeline.projectExecution.operationStart,'M/D/yyyy'):'';
        this.leaksDt = this.pipeline.operatingHistory.leaks?this.moment.parse(this.pipeline.operatingHistory.leaks,'M/D/yyyy'):'';
      } else {
        this.offtaker = this.facility;
        this.dateTransferOwnerDt = this.offtaker.basic.dateTransferOwner?this.moment.parse(this.offtaker.basic.dateTransferOwner,'M/D/yyyy'):'';
        this.eiaApprovalDateDt = this.offtaker.projectManagement.eiaApprovalDate?this.moment.parse(this.offtaker.projectManagement.eiaApprovalDate,'M/D/yyyy'):'';
        this.regulatoryLicenseDateDt = this.offtaker.designSpecs.regulatoryLicenseDate?this.moment.parse(this.offtaker.designSpecs.regulatoryLicenseDate,'M/D/yyyy'):'';
        this.projectEndDateDt = this.offtaker.projectManagement.projectEndDate?this.moment.parse(this.offtaker.projectManagement.projectEndDate,'M/D/yyyy'):'';
        this.projectStartDateDt = this.offtaker.projectManagement.projectStartDate?this.moment.parse(this.offtaker.projectManagement.projectStartDate,'M/D/yyyy'):'';
        this.projectCommissioningDateDt = this.offtaker.projectManagement.projectCommissioningDate?this.moment.parse(this.offtaker.projectManagement.projectCommissioningDate,'M/D/yyyy'):'';
        this.commissionDateDt = this.offtaker.commissioning.commissionDate?this.moment.parse(this.offtaker.commissioning.commissionDate,'M/D/yyyy'):'';
        this.operationStartDt = this.offtaker.plantOperations.operationStart?this.moment.parse(this.offtaker.plantOperations.operationStart,'M/D/yyyy'):'';
        this.ppaEffectiveDateDt = this.offtaker.commercialOperations.ppaEffectiveDate?this.moment.parse(this.offtaker.commercialOperations.ppaEffectiveDate,'M/D/yyyy'):'';
      }
    } else if(this.activatedRoute.snapshot.paramMap.get('page')){
      if(this.activatedRoute.snapshot.paramMap.get('page')=='gasplant'){        
        this.facility={type:'1'};
      } else if(this.activatedRoute.snapshot.paramMap.get('page')=='powerplant'){
        this.facility={type:'2'};
      } else if(this.activatedRoute.snapshot.paramMap.get('page')=='pipeline') {
        this.facility={type:'3'};
      } else {
        this.facility={type:'4'};
      }
    }
  }

  breakStringToKeywords(facilityBasic){
    let arrName=[];
    let curName='';
    for(let facilityName in facilityBasic){
      if(typeof(facilityBasic[facilityName]) == 'string'){
        facilityBasic[facilityName].toLowerCase().split('').forEach((letter)=>{
          curName+=letter;
          arrName.push(curName);
        })
        facilityBasic[facilityName].toLowerCase().split(' ').forEach((str, index) => {
          curName='';
          if(index!=0){
            str.split('').forEach(letter => {
              curName+=letter;
              arrName.push(curName);
            });
          }      
        });
      }
    }    
    return arrName;
  }
  async saveFacility(type){
    this.isSubmitted=true;
    let val;
    if(type=='1'){ //Gas Plant
      this.gasplant.keywords = this.breakStringToKeywords(this.gasplant.basic);
      this.gasplant.basic.dateTransferOwner = this.moment.format(this.dateTransferOwnerDt,'MM[/]DD[/]YYYY');
      this.gasplant.additional.commissionDate = this.moment.format(this.commissionDateDt,'MM[/]DD[/]YYYY')
      this.gasplant.additional.constructionEnd = this.moment.format(this.constructionEndDt,'MM[/]DD[/]YYYY')
      this.gasplant.additional.constructionStart = this.moment.format(this.constructionStartDt,'MM[/]DD[/]YYYY')
      this.gasplant.additional.operationStart = this.moment.format(this.operationStartDt,'MM[/]DD[/]YYYY')
      this.gasplant.additional.eiaApprovalDate = this.moment.format(this.eiaApprovalDateDt,'MM[/]DD[/]YYYY')
      this.gasplant.inReview=1;
      val = await this.service.saveFacility(this.gasplant);
    }else if(type=='2'){ //Power Plant
      this.powerplant.keywords = this.breakStringToKeywords(this.powerplant.basic);
      this.powerplant.basic.dateTransferOwner = this.moment.format(this.dateTransferOwnerDt,'MM[/]DD[/]YYYY');
      this.powerplant.projectManagement.eiaApprovalDate = this.moment.format(this.eiaApprovalDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.designSpecs.regulatoryLicenseDate = this.moment.format(this.regulatoryLicenseDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.projectManagement.projectEndDate = this.moment.format(this.projectEndDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.projectManagement.projectStartDate = this.moment.format(this.projectStartDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.projectManagement.projectCommissioningDate = this.moment.format(this.projectCommissioningDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.commissioning.commissionDate = this.moment.format(this.commissionDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.plantOperations.operationStart = this.moment.format(this.operationStartDt,'MM[/]DD[/]YYYY');
      this.powerplant.commercialOperations.ppaEffectiveDate = this.moment.format(this.ppaEffectiveDateDt,'MM[/]DD[/]YYYY');
      this.powerplant.inReview=1;
      val = await this.service.saveFacility(this.powerplant);
    }else if(type=='3'){ // Pipeline
      this.pipeline.keywords = this.breakStringToKeywords(this.pipeline.basic);
      this.pipeline.basic.dateTransferOwner = this.moment.format(this.dateTransferOwnerDt,'MM[/]DD[/]YYYY');
      this.pipeline.projectExecution.eiaApprovalDate = this.moment.format(this.eiaApprovalDateDt,'MM[/]DD[/]YYYY');
      this.pipeline.projectExecution.constructionStart = this.moment.format(this.constructionStartDt,'MM[/]DD[/]YYYY');
      this.pipeline.projectExecution.constructionEnd = this.moment.format(this.constructionEndDt,'MM[/]DD[/]YYYY');
      this.pipeline.projectExecution.operationStart = this.moment.format(this.operationStartDt,'MM[/]DD[/]YYYY');
      this.pipeline.operatingHistory.leaks =this.moment.format(this.leaksDt,'MM[/]DD[/]YYYY');
      this.pipeline.inReview=1;
      val = await this.service.saveFacility(this.pipeline);
    } else { // Off Taker
      this.offtaker.keywords = this.breakStringToKeywords(this.offtaker.basic);
      this.offtaker.basic.dateTransferOwner = this.moment.format(this.dateTransferOwnerDt,'MM[/]DD[/]YYYY');
      this.offtaker.projectManagement.eiaApprovalDate = this.moment.format(this.eiaApprovalDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.designSpecs.regulatoryLicenseDate = this.moment.format(this.regulatoryLicenseDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.projectManagement.projectEndDate = this.moment.format(this.projectEndDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.projectManagement.projectStartDate = this.moment.format(this.projectStartDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.projectManagement.projectCommissioningDate = this.moment.format(this.projectCommissioningDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.commissioning.commissionDate = this.moment.format(this.commissionDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.plantOperations.operationStart = this.moment.format(this.operationStartDt,'MM[/]DD[/]YYYY');
      this.offtaker.commercialOperations.ppaEffectiveDate = this.moment.format(this.ppaEffectiveDateDt,'MM[/]DD[/]YYYY');
      this.offtaker.inReview=1;
      val = await this.service.saveFacility(this.offtaker);
    }
    console.log("val==>", val)
    this.isSubmitted=false;
    if(val || this.facility.id){
      console.log(type==4, !this.offtaker.isAdminPrivate, environment.baseUrl+'facilitydetails/'+(val && val.id?val.id:this.offtaker.id)+'/pdf/true')
      if(type==1 && !this.gasplant.isAdminPrivate)
        window.open(environment.baseUrl+'facilitydetails/'+(val && val.id?val.id:this.gasplant.id)+'/pdf/true', '_self');
      else if(type==2 && !this.powerplant.isAdminPrivate)
        window.open(environment.baseUrl+'facilitydetails/'+(val && val.id?val.id:this.powerplant.id)+'/pdf/true', '_self');
      else if(type==3 && !this.pipeline.isAdminPrivate)
        window.open(environment.baseUrl+'facilitydetails/'+(val && val.id?val.id:this.pipeline.id)+'/pdf/true', '_self');  
      else if(type==4 && !this.offtaker.isAdminPrivate)
        window.open(environment.baseUrl+'facilitydetails/'+(val && val.id?val.id:this.offtaker.id)+'/pdf/true', '_self');
       
        this.router.navigate(['pages/facilities']);  
    }
  }
  navigate(val?){
    this.router.navigate(['pages/facilities']);
  }

  arrToObj(data){
    let obj={}
    for(let a of data){
      obj[a.type]=a.values;
    }
    return obj;
  }

}
