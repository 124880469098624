import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from './../../service/auth-service/auth-service.service';

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private authService:AuthServiceService) { }

  ngOnInit(): void {
  }
  user={
    email:''
  }
  submitted=false;
  async requestPass(){
    this.submitted=true;
    await this.authService.ForgotPassword(this.user.email);
    this.submitted=false;
  }
}
