<h1>Review {{facility&&facility.basic.facilityName?'Existing':'New'}} {{facility&&facility.type==1?'Gas Plant':''}}{{facility&&facility.type==2?'Power Plant':''}}{{facility&&facility.type==3?'Pipeline':''}}{{facility&&facility.type==4?'Customer':''}}
    - {{facility&&facility.basic.facilityName?facility.basic.facilityName:facility.basicInreview.facilityName}}
</h1>
<div class="buttons">
    <button type="button" class="button blueButton" [disabled]="isSubmitted" (click)="saveFacility(true)"><span *ngIf="!isSubmitted">Accept</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
    <button type="button" class="button redButton" [disabled]="isSubmitted" (click)="saveFacility(false)"><span *ngIf="!isSubmitted">Reject</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
</div>

<div *ngIf="facility && facility.type==1" class="clearboth">
        <mat-expansion-panel class="marginTop50px half" *ngIf="facility.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="gasplantForm">
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div>
                            {{gasplant.basic.facilityName}}
                        </div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <div>{{gasplant.basic.projectInvestors}}</div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <div>{{gasplant.basic.projectFinancier}}</div>
                    </div>
                    <div class="left textLabel">
                        Classification <br />
                        <div>{{gasplant.basic.classification}}</div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <div>{{gasplant.basic.owner}}</div>
                        
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <div>{{gasplant.basic.dateTransferOwner}}</div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <div>{{gasplant.basic.linkedInAccount}}</div>
                    </div>

                    <div class="left textLabel">
                        Company Website <br />
                        <div>{{gasplant.basic.companyWebsite}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <div>{{gasplant.basic.ownerType}}</div>
                        
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <div>{{gasplant.basic.operator}}</div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <div>{{gasplant.basic.location}}</div>
                    </div>
                    <div class="left textLabel">
                        Country <br />
                        <div>{{gasplant.basic.country}}</div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <div>{{gasplant.basic.gpslocation}}</div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <div>{{gasplant.basic.nationalRegulatoryAgency}}</div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <div>{{gasplant.basic.nationalPolicyAdmin}}</div>
                    </div>
                    <div class="left textLabel">
                        Gas Plant Status <br />
                        <div>{{gasplant.basic.gasPlantStatus}}</div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Region <br />
                        <div>{{gasplant.basic.region}}</div>                      
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="gasplantForm">
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div *ngIf="gasplant.basicInreview.facilityName == gasplant.basic.facilityName" class="green">
                            {{gasplant.basicInreview.facilityName}}
                        </div>
                        <div *ngIf="gasplant.basicInreview.facilityName != gasplant.basic.facilityName" class="red">
                            {{gasplant.basicInreview.facilityName}}
                        </div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <div *ngIf="gasplant.basicInreview.projectInvestors==gasplant.basic.projectInvestors" class="green">{{gasplant.basicInreview.projectInvestors}}</div>
                        <div *ngIf="gasplant.basicInreview.projectInvestors!=gasplant.basic.projectInvestors" class="red">{{gasplant.basicInreview.projectInvestors}}</div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <div *ngIf="gasplant.basicInreview.projectFinancier==gasplant.basic.projectFinancier" class="green">{{gasplant.basicInreview.projectFinancier}}</div>
                        <div *ngIf="gasplant.basicInreview.projectFinancier!=gasplant.basic.projectFinancier" class="red">{{gasplant.basicInreview.projectFinancier}}</div>
                    </div>
                    <div class="left textLabel">
                        Classification <br />
                        <div *ngIf="gasplant.basicInreview.classification==gasplant.basic.classification" class="green">{{gasplant.basicInreview.classification}}</div>
                        <div *ngIf="gasplant.basicInreview.classification!=gasplant.basic.classification" class="red">{{gasplant.basicInreview.classification}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <div *ngIf="gasplant.basicInreview.owner==gasplant.basic.owner" class="green">{{gasplant.basicInreview.owner}}</div>
                        <div *ngIf="gasplant.basicInreview.owner!=gasplant.basic.owner" class="red">{{gasplant.basicInreview.owner}}</div>
                        
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <div *ngIf="gasplant.basicInreview.dateTransferOwner==gasplant.basic.dateTransferOwner" class="green">{{gasplant.basicInreview.dateTransferOwner}}</div>
                        <div *ngIf="gasplant.basicInreview.dateTransferOwner!=gasplant.basic.dateTransferOwner" class="red">{{gasplant.basicInreview.dateTransferOwner}}</div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <div *ngIf="gasplant.basicInreview.linkedInAccount==gasplant.basic.linkedInAccount" class="green">{{gasplant.basicInreview.linkedInAccount}}</div>
                        <div *ngIf="gasplant.basicInreview.linkedInAccount!=gasplant.basic.linkedInAccount" class="red">{{gasplant.basicInreview.linkedInAccount}}</div>
                    </div>

                    <div class="left textLabel">
                        Company Website <br />
                        <div *ngIf="gasplant.basicInreview.companyWebsite == gasplant.basic.companyWebsite" class="green">{{gasplant.basicInreview.companyWebsite}}</div>
                        <div *ngIf="gasplant.basicInreview.companyWebsite != gasplant.basic.companyWebsite" class="red">{{gasplant.basicInreview.companyWebsite}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <div *ngIf="gasplant.basicInreview.ownerType==gasplant.basic.ownerType" class="green">{{gasplant.basicInreview.ownerType}}</div>
                        <div *ngIf="gasplant.basicInreview.ownerType!=gasplant.basic.ownerType" class="red">{{gasplant.basicInreview.ownerType}}</div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <div *ngIf="gasplant.basicInreview.operator==gasplant.basic.operator" class="green">{{gasplant.basicInreview.operator}}</div>
                        <div *ngIf="gasplant.basicInreview.operator!=gasplant.basic.operator" class="red">{{gasplant.basicInreview.operator}}</div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <div *ngIf="gasplant.basicInreview.location==gasplant.basic.location" class="green">{{gasplant.basicInreview.location}}</div>
                        <div *ngIf="gasplant.basicInreview.location!=gasplant.basic.location" class="red">{{gasplant.basicInreview.location}}</div>
                    </div>
                    <div class="left textLabel">
                        Country <br />
                        <div *ngIf="gasplant.basicInreview.country==gasplant.basic.country" class="green">{{gasplant.basicInreview.country}}</div>
                        <div *ngIf="gasplant.basicInreview.country!=gasplant.basic.country" class="red">{{gasplant.basicInreview.country}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <div *ngIf="gasplant.basicInreview.gpslocation==gasplant.basic.gpslocation" class="green">{{gasplant.basicInreview.gpslocation}}</div>
                        <div *ngIf="gasplant.basicInreview.gpslocation!=gasplant.basic.gpslocation" class="red">{{gasplant.basicInreview.gpslocation}}</div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <div *ngIf="gasplant.basicInreview.nationalRegulatoryAgency==gasplant.basic.nationalRegulatoryAgency" class="green">{{gasplant.basicInreview.nationalRegulatoryAgency}}</div>
                        <div *ngIf="gasplant.basicInreview.nationalRegulatoryAgency!=gasplant.basic.nationalRegulatoryAgency" class="red">{{gasplant.basicInreview.nationalRegulatoryAgency}}</div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <div *ngIf="gasplant.basicInreview.nationalPolicyAdmin==gasplant.basic.nationalPolicyAdmin" class="green">{{gasplant.basicInreview.nationalPolicyAdmin}}</div>
                        <div *ngIf="gasplant.basicInreview.nationalPolicyAdmin!=gasplant.basic.nationalPolicyAdmin" class="red">{{gasplant.basicInreview.nationalPolicyAdmin}}</div>
                    </div>
                    <div class="left textLabel">
                        Gas Plant Status <br />
                        <div *ngIf="gasplant.basicInreview.gasPlantStatus==gasplant.basic.gasPlantStatus" class="green">{{gasplant.basicInreview.gasPlantStatus}}</div>
                        <div *ngIf="gasplant.basicInreview.gasPlantStatus!=gasplant.basic.gasPlantStatus" class="red">{{gasplant.basicInreview.gasPlantStatus}}</div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Region <br />
                        <div *ngIf="gasplant.basicInreview.region==gasplant.basic.region" class="green">{{gasplant.basicInreview.region}}</div>   
                        <div *ngIf="gasplant.basicInreview.region!=gasplant.basic.region" class="red">{{gasplant.basicInreview.region}}</div>                        

                    </div>
                </div>
            </div>
        </mat-expansion-panel>



        <mat-expansion-panel class="marginTop50px half" *ngIf="facility.basic.facilityName"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ADDITIONAL INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity (MMscf) <br />
                    <div>{{gasplant.additional.capacity}}</div>
                    
                </div>
                <div class="left textLabel">
                    No. of Processing Trainings <br />
                    <div>{{gasplant.additional.nosProcessingTraining}}</div>
                </div>

                <div class="left textLabel">
                    Capacity of Trainings (MMscf) <br />
                    <div>{{gasplant.additional.capacityOfTraining}}</div>
                </div>
                <div class="right textLabel">
                    Gas Supply Fields <br />
                    <div>{{gasplant.additional.supplyFields}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    OMLs <br />
                    <div>{{gasplant.additional.oml}}</div>
                </div>
                <div class="left textLabel">
                    Gas Reserves Estimates(TCF) <br />
                    <div>{{gasplant.additional.gasReserveEstimates}}</div>
                </div>

                <div class="left textLabel">
                    Supply Inlet Pressure (barg) <br />
                    <div>{{gasplant.additional.supplyInletPressure}}</div>
                </div>
                <div class="right textLabel">
                    Delivery Pressure into Pipeline (barg) <br />
                    <div>{{gasplant.additional.deliveryPressurePipeline}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <div>{{gasplant.additional.epcContractor}}</div>
                </div>
                <div class="left textLabel">
                    Construction Start Date  <br />
                    <div>{{gasplant.additional.constructionStart}}</div>
                </div>

                <div class="left textLabel">
                    Construction End Date  <br />
                    <div>{{gasplant.additional.constructionEnd}}</div>    
                </div>

                <div class="right textLabel">
                    Commissioning Date  <br />
                    <div>{{gasplant.additional.commissionDate}}</div>      
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Operation Start Date  <br />
                    <div>{{gasplant.additional.operationStart}}</div>
                </div>
                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <div>{{gasplant.additional.estimatedProjCost}}</div>
                </div>
                
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <div>{{gasplant.additional.eiaApprovalDate}}</div>
                </div>

                <div class="right textLabel">
                    O&M Operator  <br />
                    <div>{{gasplant.additional.onmOperator}}</div>
                    
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Gas Plant Type <br />
                    <div>{{gasplant.additional.gasplantType}}</div>
                </div>
                <div class="left textLabel">
                    Gas Plant Output <br />
                    <div>{{gasplant.additional.gasplantOutput}}</div>
                </div>
                
                <div class="left textLabel">
                    Target Market <br />
                    <div>{{gasplant.additional.targetMarket}}</div>
                </div>

                <div class="right textLabel">
                    Export Pipeline  <br />
                    <div>{{gasplant.additional.exportPipeline}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Size of Export Pipeline  <br />
                    <div>{{gasplant.additional.sizeExportPipeline}}</div>
                </div>
                <div class="left textLabel">
                    Gas Plant Processing Technology <br />
                    <div>{{gasplant.additional.processingTech}}</div>
                </div>
                
                <div class="left textLabel">
                    Notes  <br />
                    <div>{{gasplant.additional.notes}}</div>
                    
                </div>

                <div class="right textLabel">
                    Profile  <br />
                    <div>{{gasplant.additional.profile}}</div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ADDITIONAL INFORMATION For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity MW <br />
                    <div *ngIf="gasplant.additionalInreview.capacity==gasplant.additional.capacity" class="green">{{gasplant.additionalInreview.capacity}}</div>
                    <div *ngIf="gasplant.additionalInreview.capacity!=gasplant.additional.capacity" class="red">{{gasplant.additionalInreview.capacity}}</div>
                </div>
                <div class="left textLabel">
                    No. of Processing Trainings <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.nosProcessingTraining==gasplant.additional.nosProcessingTraining">{{gasplant.additionalInreview.nosProcessingTraining}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.nosProcessingTraining!=gasplant.additional.nosProcessingTraining">{{gasplant.additionalInreview.nosProcessingTraining}}</div>
                </div>

                <div class="left textLabel">
                    Capacity of Trainings (MMscf) <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.capacityOfTraining==gasplant.additional.capacityOfTraining">{{gasplant.additionalInreview.capacityOfTraining}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.capacityOfTraining!=gasplant.additional.capacityOfTraining">{{gasplant.additionalInreview.capacityOfTraining}}</div>
                </div>
                <div class="right textLabel">
                    Gas Supply Fields <br />
                    <div *ngIf="gasplant.additionalInreview.supplyFields!=gasplant.additional.supplyFields" class="red">{{gasplant.additionalInreview.supplyFields}}</div>
                    <div *ngIf="gasplant.additionalInreview.supplyFields==gasplant.additional.supplyFields" class="green">{{gasplant.additionalInreview.supplyFields}}</div>
                    
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    OMLs <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.oml==gasplant.additional.oml">{{gasplant.additionalInreview.oml}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.oml!=gasplant.additional.oml">{{gasplant.additionalInreview.oml}}</div>
                </div>
                <div class="left textLabel">
                    Gas Reserves Estimates(TCF) <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.gasReserveEstimates==gasplant.additional.gasReserveEstimates">{{gasplant.additionalInreview.gasReserveEstimates}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.gasReserveEstimates!=gasplant.additional.gasReserveEstimates">{{gasplant.additionalInreview.gasReserveEstimates}}</div>
                </div>

                <div class="left textLabel">
                    Supply Inlet Pressure (barg) <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.supplyInletPressure==gasplant.additional.supplyInletPressure">{{gasplant.additionalInreview.supplyInletPressure}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.supplyInletPressure!=gasplant.additional.supplyInletPressure">{{gasplant.additionalInreview.supplyInletPressure}}</div>
                </div>
                <div class="right textLabel">
                    Delivery Pressure into Pipeline (barg) <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.deliveryPressurePipeline==gasplant.additional.deliveryPressurePipeline">{{gasplant.additionalInreview.deliveryPressurePipeline}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.deliveryPressurePipeline!=gasplant.additional.deliveryPressurePipeline">{{gasplant.additionalInreview.deliveryPressurePipeline}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.epcContractor==gasplant.additional.epcContractor">{{gasplant.additionalInreview.epcContractor}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.epcContractor!=gasplant.additional.epcContractor">{{gasplant.additionalInreview.epcContractor}}</div>
                </div>
                <div class="left textLabel">
                    Construction Start Date  <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.constructionStart==gasplant.additional.constructionStart">{{gasplant.additionalInreview.constructionStart}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.constructionStart!=gasplant.additional.constructionStart">{{gasplant.additionalInreview.constructionStart}}</div>
                </div>

                <div class="left textLabel">
                    Construction End Date  <br />
                    <div *ngIf="gasplant.additionalInreview.constructionEnd==gasplant.additional.constructionEnd" class="green">{{gasplant.additionalInreview.constructionEnd}}</div>    
                    <div *ngIf="gasplant.additionalInreview.constructionEnd!=gasplant.additional.constructionEnd" class="red">{{gasplant.additionalInreview.constructionEnd}}</div>    
                </div>

                <div class="right textLabel">
                    Commissioning Date  <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.commissionDate==gasplant.additional.commissionDate">{{gasplant.additionalInreview.commissionDate}}</div>      
                    <div class="red" *ngIf="gasplant.additionalInreview.commissionDate!=gasplant.additional.commissionDate">{{gasplant.additionalInreview.commissionDate}}</div>      
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Operation Start Date  <br />
                    <div *ngIf="gasplant.additionalInreview.operationStart==gasplant.additional.operationStart" class="green">{{gasplant.additionalInreview.operationStart}}</div>
                    <div *ngIf="gasplant.additionalInreview.operationStart!=gasplant.additional.operationStart" class="red">{{gasplant.additionalInreview.operationStart}}</div>
                </div>
                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <div *ngIf="gasplant.additionalInreview.estimatedProjCost==gasplant.additional.estimatedProjCost" class="green">{{gasplant.additionalInreview.estimatedProjCost}}</div>
                    <div *ngIf="gasplant.additionalInreview.estimatedProjCost!=gasplant.additional.estimatedProjCost" class="red">{{gasplant.additionalInreview.estimatedProjCost}}</div>
                </div>
                
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <div *ngIf="gasplant.additionalInreview.eiaApprovalDate==gasplant.additional.eiaApprovalDate" class="green">{{gasplant.additionalInreview.eiaApprovalDate}}</div>
                    <div *ngIf="gasplant.additionalInreview.eiaApprovalDate!=gasplant.additional.eiaApprovalDate" class="red">{{gasplant.additionalInreview.eiaApprovalDate}}</div>
                </div>

                <div class="right textLabel">
                    O&M Operator  <br />
                    <div *ngIf="gasplant.additionalInreview.onmOperator==gasplant.additional.onmOperator" class="green">{{gasplant.additionalInreview.onmOperator}}</div>
                    <div *ngIf="gasplant.additionalInreview.onmOperator!=gasplant.additional.onmOperator" class="red">{{gasplant.additionalInreview.onmOperator}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Gas Plant Type <br />
                    <div *ngIf="gasplant.additionalInreview.gasplantType==gasplant.additional.gasplantType" class="green">{{gasplant.additionalInreview.gasplantType}}</div>
                    <div *ngIf="gasplant.additionalInreview.gasplantType!=gasplant.additional.gasplantType" class="red">{{gasplant.additionalInreview.gasplantType}}</div>
                </div>
                <div class="left textLabel">
                    Gas Plant Output <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.gasplantOutput==gasplant.additional.gasplantOutput">{{gasplant.additionalInreview.gasplantOutput}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.gasplantOutput!=gasplant.additional.gasplantOutput">{{gasplant.additionalInreview.gasplantOutput}}</div>
                </div>
                
                <div class="left textLabel">
                    Target Market <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.targetMarket==gasplant.additional.targetMarket">{{gasplant.additionalInreview.targetMarket}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.targetMarket!=gasplant.additional.targetMarket">{{gasplant.additionalInreview.targetMarket}}</div>
                </div>

                <div class="right textLabel">
                    Export Pipeline  <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.exportPipeline==gasplant.additional.exportPipeline">{{gasplant.additionalInreview.exportPipeline}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.exportPipeline!=gasplant.additional.exportPipeline">{{gasplant.additionalInreview.exportPipeline}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Size of Export Pipeline  <br />
                    <div *ngIf="gasplant.additionalInreview.sizeExportPipeline==gasplant.additional.sizeExportPipeline" class="green">{{gasplant.additionalInreview.sizeExportPipeline}}</div>
                    <div *ngIf="gasplant.additionalInreview.sizeExportPipeline!=gasplant.additional.sizeExportPipeline" class="red">{{gasplant.additionalInreview.sizeExportPipeline}}</div>
                </div>
                <div class="left textLabel">
                    Gas Plant Processing Technology <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.processingTech==gasplant.additional.processingTech">{{gasplant.additionalInreview.processingTech}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.processingTech!=gasplant.additional.processingTech">{{gasplant.additionalInreview.processingTech}}</div>
                </div>
                
                <div class="left textLabel">
                    Notes  <br />
                    <div *ngIf="gasplant.additionalInreview.notes==gasplant.additional.notes" class="green">{{gasplant.additionalInreview.notes}}</div>
                    <div *ngIf="gasplant.additionalInreview.notes!=gasplant.additional.notes" class="red">{{gasplant.additionalInreview.notes}}</div>
                    
                </div>

                <div class="right textLabel">
                    Profile  <br />
                    <div class="green" *ngIf="gasplant.additionalInreview.profile==gasplant.additional.profile">{{gasplant.additionalInreview.profile}}</div>
                    <div class="red" *ngIf="gasplant.additionalInreview.profile!=gasplant.additional.profile">{{gasplant.additionalInreview.profile}}</div>
                </div>
            </div>
        </mat-expansion-panel>

        <!--<div class="textLabel" >
            &nbsp; <br />
            <button type="submit" class="button blueButton" [disabled]="gasPlantForm.invalid || isSubmitted" (click)="saveFacility('1')" value="Save" ><span *ngIf="!isSubmitted">Save</span><mat-spinner *ngIf="isSubmitted" [diameter]="17"></mat-spinner></button>
            <input type="submit" class="button whiteButton grey" [disabled]="isSubmitted" (click)="navigate()" value="Cancel" />
        </div>-->
    
</div>

<div *ngIf="facility && facility.type==2" class="clearboth">
        <mat-expansion-panel [expanded]=true *ngIf="facility.basic.facilityName" class="marginTop50px half">
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="powerplantForm">
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div>{{powerplant.basic.facilityName}}</div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <div>{{powerplant.basic.projectInvestors}}</div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <div>{{powerplant.basic.projectFinancier}}</div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <div>{{powerplant.basic.classification}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <div>{{powerplant.basic.owner}}</div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <div>{{powerplant.basic.dateTransferOwner}}</div>
                        
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <div>{{powerplant.basic.linkedInAccount}}</div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <div>{{powerplant.basic.companyWebsite}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <div>{{powerplant.basic.ownerType}}</div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <div>{{powerplant.basic.operator}}</div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <div>{{powerplant.basic.location}}</div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <div>{{powerplant.basic.country}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <div>{{powerplant.basic.gpslocation}}</div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <div>{{powerplant.basic.nationalRegulatoryAgency}}</div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <div>{{powerplant.basic.nationalPolicyAdmin}}</div>
                    </div>
                    <div class="left textLabel">
                        Type <br />
                        <div>{{powerplant.basic.powerPlantType}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Power Plant Status <br />
                        <div>{{powerplant.basic.powerPlantStatus}}</div>
                    </div>
                    <div class="left textLabel">
                        Region <br />
                        <div>{{powerplant.basic.region}}</div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]=true class="marginTop50px half" >
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="powerplantForm">
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div class="green" *ngIf="powerplant.basicInreview.facilityName == powerplant.basic.facilityName">{{powerplant.basicInreview.facilityName}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.facilityName != powerplant.basic.facilityName">{{powerplant.basicInreview.facilityName}}</div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <div class="green" *ngIf="powerplant.basicInreview.projectInvestors==powerplant.basic.projectInvestors">{{powerplant.basicInreview.projectInvestors}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.projectInvestors!=powerplant.basic.projectInvestors">{{powerplant.basicInreview.projectInvestors}}</div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <div class="green" *ngIf="powerplant.basicInreview.projectFinancier==powerplant.basic.projectFinancier">{{powerplant.basicInreview.projectFinancier}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.projectFinancier!=powerplant.basic.projectFinancier">{{powerplant.basicInreview.projectFinancier}}</div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <div *ngIf="powerplant.basicInreview.classification==powerplant.basic.classification" class="green">{{powerplant.basicInreview.classification}}</div>
                        <div *ngIf="powerplant.basicInreview.classification!=powerplant.basic.classification" class="red">{{powerplant.basicInreview.classification}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <div class="green" *ngIf="powerplant.basicInreview.owner==powerplant.basic.owner">{{powerplant.basicInreview.owner}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.owner!=powerplant.basic.owner">{{powerplant.basicInreview.owner}}</div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <div class="green" *ngIf="powerplant.basicInreview.dateTransferOwner==powerplant.basic.dateTransferOwner">{{powerplant.basicInreview.dateTransferOwner}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.dateTransferOwner!=powerplant.basic.dateTransferOwner">{{powerplant.basicInreview.dateTransferOwner}}</div>
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <div class="green" *ngIf="powerplant.basicInreview.linkedInAccount==powerplant.basic.linkedInAccount">{{powerplant.basicInreview.linkedInAccount}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.linkedInAccount!=powerplant.basic.linkedInAccount">{{powerplant.basicInreview.linkedInAccount}}</div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <div *ngIf="powerplant.basicInreview.companyWebsite==powerplant.basic.companyWebsite" class="green">{{powerplant.basicInreview.companyWebsite}}</div>
                        <div *ngIf="powerplant.basicInreview.companyWebsite!=powerplant.basic.companyWebsite" class="red">{{powerplant.basicInreview.companyWebsite}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <div *ngIf="powerplant.basicInreview.ownerType==powerplant.basic.ownerType" class="green">{{powerplant.basicInreview.ownerType}}</div>
                        <div *ngIf="powerplant.basicInreview.ownerType!=powerplant.basic.ownerType" class="red">{{powerplant.basicInreview.ownerType}}</div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <div class="green" *ngIf="powerplant.basicInreview.operator==powerplant.basic.operator">{{powerplant.basicInreview.operator}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.operator!=powerplant.basic.operator">{{powerplant.basicInreview.operator}}</div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <div class="green" *ngIf="powerplant.basicInreview.location==powerplant.basic.location">{{powerplant.basicInreview.location}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.location!=powerplant.basic.location">{{powerplant.basicInreview.location}}</div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <div class="green" *ngIf="powerplant.basicInreview.country==powerplant.basic.country">{{powerplant.basicInreview.country}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.country!=powerplant.basic.country">{{powerplant.basicInreview.country}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <div class="green" *ngIf="powerplant.basicInreview.gpslocation==powerplant.basic.gpslocation">{{powerplant.basicInreview.gpslocation}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.gpslocation!=powerplant.basic.gpslocation">{{powerplant.basicInreview.gpslocation}}</div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <div class="green" *ngIf="powerplant.basicInreview.nationalRegulatoryAgency==powerplant.basic.nationalRegulatoryAgency">{{powerplant.basicInreview.nationalRegulatoryAgency}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.nationalRegulatoryAgency!=powerplant.basic.nationalRegulatoryAgency">{{powerplant.basicInreview.nationalRegulatoryAgency}}</div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <div class="green" *ngIf="powerplant.basicInreview.nationalPolicyAdmin==powerplant.basic.nationalPolicyAdmin">{{powerplant.basicInreview.nationalPolicyAdmin}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.nationalPolicyAdmin!=powerplant.basic.nationalPolicyAdmin">{{powerplant.basicInreview.nationalPolicyAdmin}}</div>
                    </div>
                    <div class="left textLabel">
                        Type <br />
                        <div *ngIf="powerplant.basicInreview.powerPlantType==powerplant.basic.powerPlantType" class="green">{{powerplant.basicInreview.powerPlantType}}</div>
                        <div *ngIf="powerplant.basicInreview.powerPlantType!=powerplant.basic.powerPlantType" class="red">{{powerplant.basicInreview.powerPlantType}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Power Plant Status <br />
                        <div class="green" *ngIf="powerplant.basicInreview.powerPlantStatus==powerplant.basic.powerPlantStatus">{{powerplant.basicInreview.powerPlantStatus}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.powerPlantStatus!=powerplant.basic.powerPlantStatus">{{powerplant.basicInreview.powerPlantStatus}}</div>
                    </div>
                    <div class="left textLabel">
                        Region <br />
                        <div class="green" *ngIf="powerplant.basicInreview.region==powerplant.basic.region">{{powerplant.basicInreview.region}}</div>
                        <div class="red" *ngIf="powerplant.basicInreview.region!=powerplant.basic.region">{{powerplant.basicInreview.region}}</div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>


       <mat-expansion-panel class="marginTop50px half" *ngIf="powerplant.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity (MW) <br />
                    <div>{{powerplant.designSpecs.capacity}}</div>
                </div>
                <div class="left textLabel">
                    Net Eletrical Out (MW) <br />
                    <div>{{powerplant.designSpecs.netElectricalOut}}</div>
                </div>
                <div class="left textLabel">
                    No. of Gas Turbines <br />
                    <div>{{powerplant.designSpecs.nosOfGasTurbines}}</div>
                </div>
                <div class="left textLabel">
                    Gas Requirements (MMscf) <br />
                    <div>{{powerplant.designSpecs.gasRequirements}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    No. of Steam Turbines <br />
                    <div>{{powerplant.designSpecs.nosOfSteamTurbines}}</div>
                </div>
                <div class="left textLabel">
                    Capacity of Steam Trubines (MW) <br />
                    <div>{{powerplant.designSpecs.capacitySteamTurbine}}</div>
                </div>

                <div class="left textLabel">
                    Capacity of Gas Turbines (MW) <br />
                    <div>{{powerplant.designSpecs.capacityOfGasTurbines}}</div>
                </div>
                <div class="left textLabel">
                    Gas Turbines OEM <br />
                    <div>{{powerplant.designSpecs.gasTurbinesOEM}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Steam Turbines OEM <br />
                    <div>{{powerplant.designSpecs.steamTurbinesOEM}}</div>
                </div>
                <div class="left textLabel">
                    Capacity of Black Start Generator  <br />
                    <div>{{powerplant.designSpecs.capacityOfBlackStart}}</div>
                </div>

                <div class="left textLabel">
                    Black Start Fuel  <br />
                    <div>{{powerplant.designSpecs.blackStartFuel}}</div>            
                </div>

                <div class="right textLabel">
                    Regulatory License Date  <br />
                    <div>{{powerplant.designSpecs.regulatoryLicenseDate}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                   Target Customer <br />
                    <div>{{powerplant.designSpecs.targetCustomer}}</div>               
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Capacity (MW) <br />
                    <div *ngIf="powerplant.designSpecsInreview.capacity==powerplant.designSpecs.capacity" class="green">{{powerplant.designSpecsInreview.capacity}}</div>
                    <div *ngIf="powerplant.designSpecsInreview.capacity!=powerplant.designSpecs.capacity" class="red">{{powerplant.designSpecsInreview.capacity}}</div>
                </div>
                <div class="left textLabel">
                    Net Eletrical Out (MW) <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.netElectricalOut==powerplant.designSpecs.netElectricalOut">{{powerplant.designSpecsInreview.netElectricalOut}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.netElectricalOut!=powerplant.designSpecs.netElectricalOut">{{powerplant.designSpecsInreview.netElectricalOut}}</div>
                </div>
                <div class="left textLabel">
                    No. of Gas Turbines <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.nosOfGasTurbines==powerplant.designSpecs.nosOfGasTurbines">{{powerplant.designSpecsInreview.nosOfGasTurbines}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.nosOfGasTurbines!=powerplant.designSpecs.nosOfGasTurbines">{{powerplant.designSpecsInreview.nosOfGasTurbines}}</div>
                </div>

                <div class="right textLabel">
                    Gas Requirements (MMscf) <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.gasRequirements==powerplant.designSpecs.gasRequirements">{{powerplant.designSpecsInreview.gasRequirements}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.gasRequirements!=powerplant.designSpecs.gasRequirements">{{powerplant.designSpecsInreview.gasRequirements}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    No. of Steam Turbines <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.nosOfSteamTurbines==powerplant.designSpecs.nosOfSteamTurbines">{{powerplant.designSpecsInreview.nosOfSteamTurbines}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.nosOfSteamTurbines!=powerplant.designSpecs.nosOfSteamTurbines">{{powerplant.designSpecsInreview.nosOfSteamTurbines}}</div>
                </div>
                <div class="left textLabel">
                    Capacity of Steam Trubines (MW) <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.capacitySteamTurbine==powerplant.designSpecs.capacitySteamTurbine">{{powerplant.designSpecsInreview.capacitySteamTurbine}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.capacitySteamTurbine!=powerplant.designSpecs.capacitySteamTurbine">{{powerplant.designSpecsInreview.capacitySteamTurbine}}</div>
                </div>

                <div class="left textLabel">
                    Capacity of Gas Turbines (MW) <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.capacityOfGasTurbines==powerplant.designSpecs.capacityOfGasTurbines">{{powerplant.designSpecsInreview.capacityOfGasTurbines}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.capacityOfGasTurbines!=powerplant.designSpecs.capacityOfGasTurbines">{{powerplant.designSpecsInreview.capacityOfGasTurbines}}</div>
                </div>
                <div class="right textLabel">
                    Gas Turbines OEM <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.gasTurbinesOEM==powerplant.designSpecs.gasTurbinesOEM">{{powerplant.designSpecsInreview.gasTurbinesOEM}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.gasTurbinesOEM!=powerplant.designSpecs.gasTurbinesOEM">{{powerplant.designSpecsInreview.gasTurbinesOEM}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Steam Turbines OEM <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.steamTurbinesOEM==powerplant.designSpecs.steamTurbinesOEM">{{powerplant.designSpecsInreview.steamTurbinesOEM}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.steamTurbinesOEM!=powerplant.designSpecs.steamTurbinesOEM">{{powerplant.designSpecsInreview.steamTurbinesOEM}}</div>
                </div>
                <div class="left textLabel">
                    Capacity of Black Start Generator  <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.capacityOfBlackStart==powerplant.designSpecs.capacityOfBlackStart">{{powerplant.designSpecsInreview.capacityOfBlackStart}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.capacityOfBlackStart!=powerplant.designSpecs.capacityOfBlackStart">{{powerplant.designSpecsInreview.capacityOfBlackStart}}</div>
                </div>

                <div class="left textLabel">
                    Black Start Fuel  <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.blackStartFuel==powerplant.designSpecs.blackStartFuel">{{powerplant.designSpecsInreview.blackStartFuel}}</div>            
                    <div class="red" *ngIf="powerplant.designSpecsInreview.blackStartFuel!=powerplant.designSpecs.blackStartFuel">{{powerplant.designSpecsInreview.blackStartFuel}}</div>            
                </div>

                <div class="right textLabel">
                    Regulatory License Date  <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.regulatoryLicenseDate==powerplant.designSpecs.regulatoryLicenseDate">{{powerplant.designSpecsInreview.regulatoryLicenseDate}}</div>
                    <div class="red" *ngIf="powerplant.designSpecsInreview.regulatoryLicenseDate!=powerplant.designSpecs.regulatoryLicenseDate">{{powerplant.designSpecsInreview.regulatoryLicenseDate}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                   Target Customer <br />
                    <div class="green" *ngIf="powerplant.designSpecsInreview.targetCustomer==powerplant.designSpecs.targetCustomer">{{powerplant.designSpecsInreview.targetCustomer}}</div>               
                    <div class="red" *ngIf="powerplant.designSpecsInreview.targetCustomer!=powerplant.designSpecs.targetCustomer">{{powerplant.designSpecsInreview.targetCustomer}}</div>               
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" *ngIf="powerplant.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT MANAGEMENT
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <div>{{powerplant.projectManagement.epcContractor}}</div>
                </div>

                <div class="left textLabel">
                    Project Execution Consultant <br />
                    <div>{{powerplant.projectManagement.projectExecutionConsultant}}</div>
                </div>

                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <div>{{powerplant.projectManagement.estimatedProjCost}}</div>
                </div>
                <div class="right textLabel">
                    Project Start Date  <br />
                    <div>{{powerplant.projectManagement.projectStartDate}}</div>      
                </div>
            </div>
            <div class="row margin">
                <div class="left textLabel">
                    Project End Date  <br />
                    <div>{{powerplant.projectManagement.projectEndDate}}</div>
                </div>
                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <div>{{powerplant.projectManagement.projectCommissioningDate}}</div>     
                </div>
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <div>{{powerplant.projectManagement.eiaApprovalDate}}</div>  
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT MANAGEMENT For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.epcContractor==powerplant.projectManagement.epcContractor">{{powerplant.projectManagementInreview.epcContractor}}</div>
                    <div class="red" *ngIf="powerplant.projectManagementInreview.epcContractor!=powerplant.projectManagement.epcContractor">{{powerplant.projectManagementInreview.epcContractor}}</div>
                </div>

                <div class="left textLabel">
                    Project Execution Consultant <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.projectExecutionConsultant==powerplant.projectManagement.projectExecutionConsultant">{{powerplant.projectManagementInreview.projectExecutionConsultant}}</div>
                    <div class="red" *ngIf="powerplant.projectManagementInreview.projectExecutionConsultant!=powerplant.projectManagement.projectExecutionConsultant">{{powerplant.projectManagementInreview.projectExecutionConsultant}}</div>
                </div>

                <div class="left textLabel">
                    Estimated Project Cost (US$) <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.estimatedProjCost==powerplant.projectManagement.estimatedProjCost">{{powerplant.projectManagementInreview.estimatedProjCost}}</div>
                    <div class="red" *ngIf="powerplant.projectManagementInreview.estimatedProjCost!=powerplant.projectManagement.estimatedProjCost">{{powerplant.projectManagementInreview.estimatedProjCost}}</div>
                </div>
                <div class="right textLabel">
                    Project Start Date  <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.projectStartDate==powerplant.projectManagement.projectStartDate">{{powerplant.projectManagementInreview.projectStartDate}}</div>      
                    <div class="red" *ngIf="powerplant.projectManagementInreview.projectStartDate!=powerplant.projectManagement.projectStartDate">{{powerplant.projectManagementInreview.projectStartDate}}</div>      
                </div>
            </div>
            <div class="row margin">
                <div class="left textLabel">
                    Project End Date  <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.projectEndDate==powerplant.projectManagement.projectEndDate">{{powerplant.projectManagementInreview.projectEndDate}}</div>
                    <div class="red" *ngIf="powerplant.projectManagementInreview.projectEndDate!=powerplant.projectManagement.projectEndDate">{{powerplant.projectManagementInreview.projectEndDate}}</div>
                </div>
                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.projectCommissioningDate==powerplant.projectManagement.projectCommissioningDate">{{powerplant.projectManagementInreview.projectCommissioningDate}}</div>     
                    <div class="red" *ngIf="powerplant.projectManagementInreview.projectCommissioningDate!=powerplant.projectManagement.projectCommissioningDate">{{powerplant.projectManagementInreview.projectCommissioningDate}}</div>     
                </div>
                <div class="left textLabel">
                    EIA/ESIA Approval Date  <br />
                    <div class="green" *ngIf="powerplant.projectManagementInreview.eiaApprovalDate==powerplant.projectManagement.eiaApprovalDate">{{powerplant.projectManagementInreview.eiaApprovalDate}}</div>  
                    <div class="red" *ngIf="powerplant.projectManagementInreview.eiaApprovalDate!=powerplant.projectManagement.eiaApprovalDate">{{powerplant.projectManagementInreview.eiaApprovalDate}}</div>  
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" *ngIf="powerplant.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMISSIONING
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Certificate of Readiness <br />
                    <div>{{powerplant.commissioning.certificateOfReadiness}}</div>
                </div>

                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <div>{{powerplant.commissioning.commissionDate}}</div>
                </div>

                <div class="left textLabel">
                    Commissioning Test Certificate <br />
                    <div>{{powerplant.commissioning.commissionTestCertificate}}</div>
                </div>

                <div class="right textLabel">
                    Commissioning Certificate for Sync <br />
                    <div>{{powerplant.commissioning.commissionCertificateSync}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Turbine Performance Test Certificate <br />
                    <div>{{powerplant.commissioning.turbinePerformanceTestCertificate}}</div>
                </div>

                <div class="left textLabel">
                    Reliability Run Test Certificate <br />
                    <div>{{powerplant.commissioning.reliabilityRunTestCertificate}}</div>
                </div>

                <div class="right textLabel">
                    Capacity Test Certificate <br />
                    <div>{{powerplant.commissioning.capacityTestCertificate}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px half" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMISSIONING For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Certificate of Readiness <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.certificateOfReadiness==powerplant.commissioning.certificateOfReadiness">{{powerplant.commissioningInreview.certificateOfReadiness}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.certificateOfReadiness!=powerplant.commissioning.certificateOfReadiness">{{powerplant.commissioningInreview.certificateOfReadiness}}</div>
                </div>

                <div class="left textLabel">
                    Project Commissioning Date  <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.commissionDate==powerplant.commissioning.commissionDate">{{powerplant.commissioningInreview.commissionDate}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.commissionDate!=powerplant.commissioning.commissionDate">{{powerplant.commissioningInreview.commissionDate}}</div>
                </div>

                <div class="left textLabel">
                    Commissioning Test Certificate <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.commissionTestCertificate==powerplant.commissioning.commissionTestCertificate">{{powerplant.commissioningInreview.commissionTestCertificate}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.commissionTestCertificate!=powerplant.commissioning.commissionTestCertificate">{{powerplant.commissioningInreview.commissionTestCertificate}}</div>
                </div>

                <div class="right textLabel">
                    Commissioning Certificate for Sync <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.commissionCertificateSync==powerplant.commissioning.commissionCertificateSync">{{powerplant.commissioningInreview.commissionCertificateSync}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.commissionCertificateSync!=powerplant.commissioning.commissionCertificateSync">{{powerplant.commissioningInreview.commissionCertificateSync}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Turbine Performance Test Certificate <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.turbinePerformanceTestCertificate==powerplant.commissioning.turbinePerformanceTestCertificate">{{powerplant.commissioningInreview.turbinePerformanceTestCertificate}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.turbinePerformanceTestCertificate!=powerplant.commissioning.turbinePerformanceTestCertificate">{{powerplant.commissioningInreview.turbinePerformanceTestCertificate}}</div>
                </div>

                <div class="left textLabel">
                    Reliability Run Test Certificate <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.reliabilityRunTestCertificate==powerplant.commissioning.reliabilityRunTestCertificate">{{powerplant.commissioningInreview.reliabilityRunTestCertificate}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.reliabilityRunTestCertificate!=powerplant.commissioning.reliabilityRunTestCertificate">{{powerplant.commissioningInreview.reliabilityRunTestCertificate}}</div>
                </div>

                <div class="right textLabel">
                    Capacity Test Certificate <br />
                    <div class="green" *ngIf="powerplant.commissioningInreview.capacityTestCertificate==powerplant.commissioning.capacityTestCertificate">{{powerplant.commissioningInreview.capacityTestCertificate}}</div>
                    <div class="red" *ngIf="powerplant.commissioningInreview.capacityTestCertificate!=powerplant.commissioning.capacityTestCertificate">{{powerplant.commissioningInreview.capacityTestCertificate}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px half" *ngIf="powerplant.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PLANT OPERATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    O&M Contractor Name<br />
                    <div>{{powerplant.plantOperations.onmContractor}}</div>
                </div>
                <div class="left textLabel">
                    Operations Start Date  <br />
                    <div>{{powerplant.plantOperations.operationStart}}</div>
                           
                </div>

                <div class="left textLabel">
                    Name of LTSA Contractor <br />
                    <div>{{powerplant.plantOperations.nameOfLTSAContractor}}</div>
                </div>
                <div class="right textLabel">
                    Turbine A,B&C Inspections <br />
                    <div>{{powerplant.plantOperations.turbineABCInspections}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    CMMS Computerized Mtce Mgr<br />
                    <div>{{powerplant.plantOperations.cmmsComputerizedMtceMgr}}</div>
                </div>
                <div class="left textLabel">
                    Plant Availability Capacity(MW) <br />
                    <div>{{powerplant.plantOperations.plantAvailabilityCapacity}}</div>
                </div>

                <div class="left textLabel">
                    Plant Rating(MW) <br />
                    <div>{{powerplant.plantOperations.plantRating}}</div>
                </div>
                <div class="right textLabel">
                    Terminal Voltage (KV) <br /> 
                    <div>{{powerplant.plantOperations.terminalVoltage}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Unit Efficiency (%) <br />
                    <div>{{powerplant.plantOperations.unitEfficiency}}</div>
                </div>
                <div class="left textLabel">
                    Reactive Power Requirement (MW) <br />
                    <div>{{powerplant.plantOperations.reactivePowerRequirement}}</div>
                </div>

                <div class="left textLabel">
                    Short Circuit Ratio <br />
                    <div>{{powerplant.plantOperations.shortCircuitRatio}}</div>
                </div>
                <div class="right textLabel">
                    Auxiliary Power Requirement (MW) <br /> 
                    <div>{{powerplant.plantOperations.auxilliaryPowerRequirement}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Transformer Rated Capacity (MVA) <br />
                    <div>{{powerplant.plantOperations.trasnformerRatedCapacity}}</div>
                </div>
                <div class="left textLabel">
                    Transformer Voltage Load Ratio(HV/LV) <br />
                    <div>{{powerplant.plantOperations.transformerVoltageLoadRatio}}</div>
                </div>

                <div class="left textLabel">
                    Steam Turbine Pressure (PSI) <br />
                    <div>{{powerplant.plantOperations.steamTurbinePressure}}</div>
                </div>
                <div class="right textLabel">
                    Steam Turbine Temperature <br /> 
                    <div>{{powerplant.plantOperations.steamTurbineTemprature}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant Noise Level (dBA) <br />
                    <div>{{powerplant.plantOperations.plantNoiseLevel}}</div>
                </div>
           
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PLANT OPERATIONS For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    O&M Contractor Name<br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.onmContractor==powerplant.plantOperations.onmContractor">{{powerplant.plantOperationsInreview.onmContractor}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.onmContractor!=powerplant.plantOperations.onmContractor">{{powerplant.plantOperationsInreview.onmContractor}}</div>
                </div>
                <div class="left textLabel">
                    Operations Start Date  <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.operationStart==powerplant.plantOperations.operationStart">{{powerplant.plantOperationsInreview.operationStart}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.operationStart!=powerplant.plantOperations.operationStart">{{powerplant.plantOperationsInreview.operationStart}}</div>
                           
                </div>

                <div class="left textLabel">
                    Name of LTSA Contractor <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.nameOfLTSAContractor==powerplant.plantOperations.nameOfLTSAContractor">{{powerplant.plantOperationsInreview.nameOfLTSAContractor}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.nameOfLTSAContractor!=powerplant.plantOperations.nameOfLTSAContractor">{{powerplant.plantOperationsInreview.nameOfLTSAContractor}}</div>
                </div>
                <div class="right textLabel">
                    Turbine A,B&C Inspections <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.turbineABCInspections==powerplant.plantOperations.turbineABCInspections">{{powerplant.plantOperationsInreview.turbineABCInspections}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.turbineABCInspections!=powerplant.plantOperations.turbineABCInspections">{{powerplant.plantOperationsInreview.turbineABCInspections}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    CMMS Computerized Mtce Mgr<br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.cmmsComputerizedMtceMgr==powerplant.plantOperations.cmmsComputerizedMtceMgr">{{powerplant.plantOperationsInreview.cmmsComputerizedMtceMgr}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.cmmsComputerizedMtceMgr!=powerplant.plantOperations.cmmsComputerizedMtceMgr">{{powerplant.plantOperationsInreview.cmmsComputerizedMtceMgr}}</div>
                </div>
                <div class="left textLabel">
                    Plant Availability Capacity(MW) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.plantAvailabilityCapacity==powerplant.plantOperations.plantAvailabilityCapacity">{{powerplant.plantOperationsInreview.plantAvailabilityCapacity}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.plantAvailabilityCapacity!=powerplant.plantOperations.plantAvailabilityCapacity">{{powerplant.plantOperationsInreview.plantAvailabilityCapacity}}</div>
                </div>

                <div class="left textLabel">
                    Plant Rating(MW) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.plantRating==powerplant.plantOperations.plantRating">{{powerplant.plantOperationsInreview.plantRating}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.plantRating!=powerplant.plantOperations.plantRating">{{powerplant.plantOperationsInreview.plantRating}}</div>
                </div>
                <div class="right textLabel">
                    Terminal Voltage (KV) <br /> 
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.terminalVoltage==powerplant.plantOperations.terminalVoltage">{{powerplant.plantOperationsInreview.terminalVoltage}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.terminalVoltage!=powerplant.plantOperations.terminalVoltage">{{powerplant.plantOperationsInreview.terminalVoltage}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Unit Efficiency (%) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.unitEfficiency==powerplant.plantOperations.unitEfficiency">{{powerplant.plantOperationsInreview.unitEfficiency}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.unitEfficiency!=powerplant.plantOperations.unitEfficiency">{{powerplant.plantOperationsInreview.unitEfficiency}}</div>
                </div>
                <div class="left textLabel">
                    Reactive Power Requirement (MW) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.reactivePowerRequirement==powerplant.plantOperations.reactivePowerRequirement">{{powerplant.plantOperationsInreview.reactivePowerRequirement}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.reactivePowerRequirement!=powerplant.plantOperations.reactivePowerRequirement">{{powerplant.plantOperationsInreview.reactivePowerRequirement}}</div>
                </div>

                <div class="left textLabel">
                    Short Circuit Ratio <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.shortCircuitRatio==powerplant.plantOperations.shortCircuitRatio">{{powerplant.plantOperationsInreview.shortCircuitRatio}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.shortCircuitRatio!=powerplant.plantOperations.shortCircuitRatio">{{powerplant.plantOperationsInreview.shortCircuitRatio}}</div>
                </div>
                <div class="right textLabel">
                    Auxiliary Power Requirement (MW) <br /> 
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.auxilliaryPowerRequirement==powerplant.plantOperations.auxilliaryPowerRequirement">{{powerplant.plantOperationsInreview.auxilliaryPowerRequirement}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.auxilliaryPowerRequirement!=powerplant.plantOperations.auxilliaryPowerRequirement">{{powerplant.plantOperationsInreview.auxilliaryPowerRequirement}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Transformer Rated Capacity (MVA) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.trasnformerRatedCapacity==powerplant.plantOperations.trasnformerRatedCapacity">{{powerplant.plantOperationsInreview.trasnformerRatedCapacity}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.trasnformerRatedCapacity!=powerplant.plantOperations.trasnformerRatedCapacity">{{powerplant.plantOperationsInreview.trasnformerRatedCapacity}}</div>
                </div>
                <div class="left textLabel">
                    Transformer Voltage Load Ratio(HV/LV) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.transformerVoltageLoadRatio==powerplant.plantOperations.transformerVoltageLoadRatio">{{powerplant.plantOperationsInreview.transformerVoltageLoadRatio}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.transformerVoltageLoadRatio!=powerplant.plantOperations.transformerVoltageLoadRatio">{{powerplant.plantOperationsInreview.transformerVoltageLoadRatio}}</div>
                </div>

                <div class="left textLabel">
                    Steam Turbine Pressure (PSI) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.steamTurbinePressure==powerplant.plantOperations.steamTurbinePressure">{{powerplant.plantOperationsInreview.steamTurbinePressure}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.steamTurbinePressure!=powerplant.plantOperations.steamTurbinePressure">{{powerplant.plantOperationsInreview.steamTurbinePressure}}</div>
                </div>
                <div class="right textLabel">
                    Steam Turbine Temperature <br /> 
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.steamTurbineTemprature==powerplant.plantOperations.steamTurbineTemprature">{{powerplant.plantOperationsInreview.steamTurbineTemprature}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.steamTurbineTemprature!=powerplant.plantOperations.steamTurbineTemprature">{{powerplant.plantOperationsInreview.steamTurbineTemprature}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant Noise Level (dBA) <br />
                    <div class="green" *ngIf="powerplant.plantOperationsInreview.plantNoiseLevel==powerplant.plantOperations.plantNoiseLevel">{{powerplant.plantOperationsInreview.plantNoiseLevel}}</div>
                    <div class="red" *ngIf="powerplant.plantOperationsInreview.plantNoiseLevel!=powerplant.plantOperations.plantNoiseLevel">{{powerplant.plantOperationsInreview.plantNoiseLevel}}</div>
                </div>
           
            </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel class="marginTop50px half" *ngIf="powerplant.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMERCIAL OPERATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>        
            
            <div class="row margin">    
                <div class="left textLabel">
                    Natural Gas Supplier <br />
                    <div>{{powerplant.commercialOperations.naturalGasSupplier}}</div>
                    
                </div>
                <div class="left textLabel">
                    Gas Contract Quantity (MMscf) <br />
                    <div>{{powerplant.commercialOperations.gasContractQuantity}}</div>
                </div>
                <div class="left textLabel">
                    Average Daily Gas Consumption <br />
                    <div>{{powerplant.commercialOperations.avgDailyGasConsumption}}</div>
                </div>
                <div class="right textLabel">
                    Gas Transportation Agreement <br />
                    <div>{{powerplant.commercialOperations.gasTransportationAgreement}}</div>
                </div>
            </div>
            <div class="row">    
                <div class="left textLabel">
                    PPA for Power Expert <br />
                    <div>{{powerplant.commercialOperations.ppaForPowerExport}}</div>
                </div>
                <div class="left textLabel">
                    PPA Contract Capacity(MW) <br />
                    <div>{{powerplant.commercialOperations.ppaContractCapacity}}</div>
                </div>
                <div class="left textLabel">
                    PPA Customer <br />
                    <div>{{powerplant.commercialOperations.ppaCustomer}}</div>
                </div>
                <div class="right textLabel">
                    PPA Effective Date  <br />
                    <div>{{powerplant.commercialOperations.ppaEffectiveDate}}</div>
                           
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    PPA Term (Years) <br />
                    <div>{{powerplant.commercialOperations.ppaTerm}}</div>
                </div>   
                <div class="left textLabel">
                    PPA Securitized <br />
                    <div>{{powerplant.commercialOperations.ppaSecuritized}}</div>
                </div>               
                <div class="left textLabel">
                    Avg.Load Factor (%)  <br />
                    <div>{{powerplant.commercialOperations.avgLoadFactor}}</div>
                </div>
                <div class="right textLabel">
                   Avg Capacity Availability  <br />
                    <div>{{powerplant.commercialOperations.avgCapacityAvailability}}</div>
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    Average NEO (kw/hr) <br />
                    <div>{{powerplant.commercialOperations.avgNEO}}</div>
                </div>   
                <div class="left textLabel">
                    (CER) Certified Emission Reduce <br />
                    <div>{{powerplant.commercialOperations.certifiedEmissionRatio}}</div>
                </div>               
                <div class="left textLabel">
                    Notes  <br />
                    <div>{{powerplant.commercialOperations.notes}}</div>
                </div>
                <div class="right textLabel">
                   Profile  <br />
                    <div>{{powerplant.commercialOperations.profile}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                COMMERCIAL OPERATIONS For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>        
            
            <div class="row margin">    
                <div class="left textLabel">
                    Natural Gas Supplier <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.naturalGasSupplier==powerplant.commercialOperations.naturalGasSupplier">{{powerplant.commercialOperationsInreview.naturalGasSupplier}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.naturalGasSupplier!=powerplant.commercialOperations.naturalGasSupplier">{{powerplant.commercialOperationsInreview.naturalGasSupplier}}</div>
                    
                </div>
                <div class="left textLabel">
                    Gas Contract Quantity (MMscf) <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.gasContractQuantity==powerplant.commercialOperations.gasContractQuantity">{{powerplant.commercialOperationsInreview.gasContractQuantity}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.gasContractQuantity!=powerplant.commercialOperations.gasContractQuantity">{{powerplant.commercialOperationsInreview.gasContractQuantity}}</div>
                </div>
                <div class="left textLabel">
                    Average Daily Gas Consumption <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.avgDailyGasConsumption==powerplant.commercialOperations.avgDailyGasConsumption">{{powerplant.commercialOperationsInreview.avgDailyGasConsumption}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.avgDailyGasConsumption!=powerplant.commercialOperations.avgDailyGasConsumption">{{powerplant.commercialOperationsInreview.avgDailyGasConsumption}}</div>
                </div>
                <div class="right textLabel">
                    Gas Transportation Agreement <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.gasTransportationAgreement==powerplant.commercialOperations.gasTransportationAgreement">{{powerplant.commercialOperationsInreview.gasTransportationAgreement}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.gasTransportationAgreement!=powerplant.commercialOperations.gasTransportationAgreement">{{powerplant.commercialOperationsInreview.gasTransportationAgreement}}</div>
                </div>
            </div>
            <div class="row">    
                <div class="left textLabel">
                    PPA for Power Expert <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.ppaForPowerExport==powerplant.commercialOperations.ppaForPowerExport">{{powerplant.commercialOperationsInreview.ppaForPowerExport}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.ppaForPowerExport!=powerplant.commercialOperations.ppaForPowerExport">{{powerplant.commercialOperationsInreview.ppaForPowerExport}}</div>
                </div>
                <div class="left textLabel">
                    PPA Contract Capacity(MW) <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.ppaContractCapacity==powerplant.commercialOperations.ppaContractCapacity">{{powerplant.commercialOperationsInreview.ppaContractCapacity}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.ppaContractCapacity!=powerplant.commercialOperations.ppaContractCapacity">{{powerplant.commercialOperationsInreview.ppaContractCapacity}}</div>
                </div>
                <div class="left textLabel">
                    PPA Customer <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.ppaCustomer==powerplant.commercialOperations.ppaCustomer">{{powerplant.commercialOperationsInreview.ppaCustomer}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.ppaCustomer!=powerplant.commercialOperations.ppaCustomer">{{powerplant.commercialOperationsInreview.ppaCustomer}}</div>
                </div>
                <div class="right textLabel">
                    PPA Effective Date  <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.ppaEffectiveDate==powerplant.commercialOperations.ppaEffectiveDate">{{powerplant.commercialOperationsInreview.ppaEffectiveDate}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.ppaEffectiveDate!=powerplant.commercialOperations.ppaEffectiveDate">{{powerplant.commercialOperationsInreview.ppaEffectiveDate}}</div>
                           
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    PPA Term (Years) <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.ppaTerm==powerplant.commercialOperations.ppaTerm">{{powerplant.commercialOperationsInreview.ppaTerm}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.ppaTerm!=powerplant.commercialOperations.ppaTerm">{{powerplant.commercialOperationsInreview.ppaTerm}}</div>
                </div>   
                <div class="left textLabel">
                    PPA Securitized <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.ppaSecuritized==powerplant.commercialOperations.ppaSecuritized">{{powerplant.commercialOperationsInreview.ppaSecuritized}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.ppaSecuritized!=powerplant.commercialOperations.ppaSecuritized">{{powerplant.commercialOperationsInreview.ppaSecuritized}}</div>
                </div>               
                <div class="left textLabel">
                    Avg.Load Factor (%)  <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.avgLoadFactor==powerplant.commercialOperations.avgLoadFactor">{{powerplant.commercialOperationsInreview.avgLoadFactor}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.avgLoadFactor!=powerplant.commercialOperations.avgLoadFactor">{{powerplant.commercialOperationsInreview.avgLoadFactor}}</div>
                </div>
                <div class="right textLabel">
                   Avg Capacity Availability  <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.avgCapacityAvailability==powerplant.commercialOperations.avgCapacityAvailability">{{powerplant.commercialOperationsInreview.avgCapacityAvailability}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.avgCapacityAvailability!=powerplant.commercialOperations.avgCapacityAvailability">{{powerplant.commercialOperationsInreview.avgCapacityAvailability}}</div>
                </div>
            </div>
            <div class="row"> 
                <div class="left textLabel">
                    Average NEO (kw/hr) <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.avgNEO==powerplant.commercialOperations.avgNEO">{{powerplant.commercialOperationsInreview.avgNEO}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.avgNEO!=powerplant.commercialOperations.avgNEO">{{powerplant.commercialOperationsInreview.avgNEO}}</div>
                </div>   
                <div class="left textLabel">
                    (CER) Certified Emission Reduce <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.certifiedEmissionRatio==powerplant.commercialOperations.certifiedEmissionRatio">{{powerplant.commercialOperationsInreview.certifiedEmissionRatio}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.certifiedEmissionRatio!=powerplant.commercialOperations.certifiedEmissionRatio">{{powerplant.commercialOperationsInreview.certifiedEmissionRatio}}</div>
                </div>               
                <div class="left textLabel">
                    Notes  <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.notes==powerplant.commercialOperations.notes">{{powerplant.commercialOperationsInreview.notes}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.notes!=powerplant.commercialOperations.notes">{{powerplant.commercialOperationsInreview.notes}}</div>
                </div>
                <div class="right textLabel">
                   Profile  <br />
                    <div class="green" *ngIf="powerplant.commercialOperationsInreview.profile==powerplant.commercialOperations.profile">{{powerplant.commercialOperationsInreview.profile}}</div>
                    <div class="red" *ngIf="powerplant.commercialOperationsInreview.profile!=powerplant.commercialOperations.profile">{{powerplant.commercialOperationsInreview.profile}}</div>
                </div>
            </div>
        </mat-expansion-panel>  
</div>
<div *ngIf="facility && facility.type==3" class="clearboth">
    
        <mat-expansion-panel [expanded]=true *ngIf="pipeline.basic.facilityName" class="half marginTop50px">
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="pipelineForm">
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div>{{pipeline.basic.facilityName}}</div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <div>{{pipeline.basic.projectInvestors}}</div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <div>{{pipeline.basic.projectFinancier}}</div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <div>{{pipeline.basic.classification}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <div>{{pipeline.basic.owner}}</div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <div>{{pipeline.basic.dateTransferOwner}}</div>                        
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <div>{{pipeline.basic.linkedInAccount}}</div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <div>{{pipeline.basic.companyWebsite}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <div>{{pipeline.basic.ownerType}}</div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <div>{{pipeline.basic.operator}}</div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <div>{{pipeline.basic.location}}</div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <div>{{pipeline.basic.country}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <div>{{pipeline.basic.gpslocation}}</div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <div>{{pipeline.basic.nationalRegulatoryAgency}}</div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <div>{{pipeline.basic.nationalPolicyAdmin}}</div>
                    </div>
                    <div class="right textLabel">
                        Pipeline Status <br />
                        <div>{{pipeline.basic.pipelineStatus}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="right textLabel">
                        Region <br />
                        <div>{{pipeline.basic.region}}</div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]=true class="half marginTop50px">
            <mat-expansion-panel-header>
            <mat-panel-title>
                BRIEF INFORMATION For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="pipelineForm">
                <div class="row margin">
                    <div class="left textLabel">
                        Facility Name <br />
                        <div class="green" *ngIf="pipeline.basicInreview.facilityName==pipeline.basic.facilityName">{{pipeline.basicInreview.facilityName}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.facilityName!=pipeline.basic.facilityName">{{pipeline.basicInreview.facilityName}}</div>
                    </div>
                    <div class="left textLabel">
                        Project Investors/Developers <br />
                        <div class="green" *ngIf="pipeline.basicInreview.projectInvestors==pipeline.basic.projectInvestors">{{pipeline.basicInreview.projectInvestors}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.projectInvestors!=pipeline.basic.projectInvestors">{{pipeline.basicInreview.projectInvestors}}</div>
                    </div>

                    <div class="left textLabel">
                        Project Financier <br />
                        <div class="green" *ngIf="pipeline.basicInreview.projectFinancier==pipeline.basic.projectFinancier">{{pipeline.basicInreview.projectFinancier}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.projectFinancier!=pipeline.basic.projectFinancier">{{pipeline.basicInreview.projectFinancier}}</div>
                    </div>
                    <div class="right textLabel">
                        Classification <br />
                        <div class="green" *ngIf="pipeline.basicInreview.classification==pipeline.basic.classification">{{pipeline.basicInreview.classification}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.classification!=pipeline.basic.classification">{{pipeline.basicInreview.classification}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner <br />
                        <div class="green" *ngIf="pipeline.basicInreview.owner==pipeline.basic.owner">{{pipeline.basicInreview.owner}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.owner!=pipeline.basic.owner">{{pipeline.basicInreview.owner}}</div>
                    </div>
                    <div class="left textLabel">
                        Date of Transfer to Owner  <br />
                        <div class="green" *ngIf="pipeline.basicInreview.dateTransferOwner==pipeline.basic.dateTransferOwner">{{pipeline.basicInreview.dateTransferOwner}}</div>                        
                        <div class="red" *ngIf="pipeline.basicInreview.dateTransferOwner!=pipeline.basic.dateTransferOwner">{{pipeline.basicInreview.dateTransferOwner}}</div>                        
                    </div>

                    <div class="left textLabel">
                        LinkedIn Account <br />
                        <div class="green" *ngIf="pipeline.basicInreview.linkedInAccount==pipeline.basic.linkedInAccount">{{pipeline.basicInreview.linkedInAccount}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.linkedInAccount!=pipeline.basic.linkedInAccount">{{pipeline.basicInreview.linkedInAccount}}</div>
                    </div>

                    <div class="right textLabel">
                        Company Website <br />
                        <div class="green" *ngIf="pipeline.basicInreview.companyWebsite==pipeline.basic.companyWebsite">{{pipeline.basicInreview.companyWebsite}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.companyWebsite!=pipeline.basic.companyWebsite">{{pipeline.basicInreview.companyWebsite}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        Owner Type <br />
                        <div class="green" *ngIf="pipeline.basicInreview.ownerType==pipeline.basic.ownerType">{{pipeline.basicInreview.ownerType}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.ownerType!=pipeline.basic.ownerType">{{pipeline.basicInreview.ownerType}}</div>
                    </div>
                    <div class="left textLabel">
                        Operator <br />
                        <div class="green" *ngIf="pipeline.basicInreview.operator==pipeline.basic.operator">{{pipeline.basicInreview.operator}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.operator!=pipeline.basic.operator">{{pipeline.basicInreview.operator}}</div>
                    </div>

                    <div class="left textLabel">
                        Location <br />
                        <div class="green" *ngIf="pipeline.basicInreview.location==pipeline.basic.location">{{pipeline.basicInreview.location}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.location!=pipeline.basic.location">{{pipeline.basicInreview.location}}</div>
                    </div>
                    <div class="right textLabel">
                        Country <br />
                        <div class="green" *ngIf="pipeline.basicInreview.country==pipeline.basic.country">{{pipeline.basicInreview.country}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.country!=pipeline.basic.country">{{pipeline.basicInreview.country}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="left textLabel">
                        GPS Location <br />
                        <div class="green" *ngIf="pipeline.basicInreview.gpslocation==pipeline.basic.gpslocation">{{pipeline.basicInreview.gpslocation}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.gpslocation!=pipeline.basic.gpslocation">{{pipeline.basicInreview.gpslocation}}</div>
                    </div>
                    <div class="left textLabel">
                        National Regulatory Agency <br />
                        <div class="green" *ngIf="pipeline.basicInreview.nationalRegulatoryAgency==pipeline.basic.nationalRegulatoryAgency">{{pipeline.basicInreview.nationalRegulatoryAgency}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.nationalRegulatoryAgency!=pipeline.basic.nationalRegulatoryAgency">{{pipeline.basicInreview.nationalRegulatoryAgency}}</div>
                    </div>

                    <div class="left textLabel">
                        National Policy Administrator <br />
                        <div class="green" *ngIf="pipeline.basicInreview.nationalPolicyAdmin==pipeline.basic.nationalPolicyAdmin">{{pipeline.basicInreview.nationalPolicyAdmin}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.nationalPolicyAdmin!=pipeline.basic.nationalPolicyAdmin">{{pipeline.basicInreview.nationalPolicyAdmin}}</div>
                    </div>
                    <div class="right textLabel">
                        Pipeline Status <br />
                        <div class="green" *ngIf="pipeline.basicInreview.pipelineStatus==pipeline.basic.pipelineStatus">{{pipeline.basicInreview.pipelineStatus}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.pipelineStatus!=pipeline.basic.pipelineStatus">{{pipeline.basicInreview.pipelineStatus}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="right textLabel">
                        Region <br />
                        <div class="green" *ngIf="pipeline.basicInreview.region==pipeline.basic.region">{{pipeline.basicInreview.region}}</div>
                        <div class="red" *ngIf="pipeline.basicInreview.region!=pipeline.basic.region">{{pipeline.basicInreview.region}}</div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" *ngIf="pipeline.basic.facilityName"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Size Diameter (inches) <br />
                    <div>{{pipeline.designSpecs.sizeDiameter}}</div>
                </div>
                <div class="left textLabel">
                    Maximum Velocity (m/s) <br />
                    <div>{{pipeline.designSpecs.maxVelocity}}</div>
                </div>
               
                <div class="left textLabel">
                    Capacity (MMscf) <br />
                    <div>{{pipeline.designSpecs.capacity}}</div>
                </div>
                <div class="right textLabel">
                    Material <br />
                    <div>{{pipeline.designSpecs.material}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Design Life (years) <br />
                    <div>{{pipeline.designSpecs.designLife}}</div>
                </div>
                <div class="left textLabel">
                    Wall Thickness / Schedule (inches) <br />
                    <div>{{pipeline.designSpecs.wallThicknessSchedule}}</div>
                </div>
                
                <div class="left textLabel">
                    Steel Type <br />
                    <div>{{pipeline.designSpecs.steelType}}</div>
                </div>
                <div class="right textLabel">
                    Pipe Class <br />
                    <div>{{pipeline.designSpecs.pipeClass}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Pipe Schedule <br />
                    <div>{{pipeline.designSpecs.pipeSchedule}}</div>
                </div>
                <div class="left textLabel">
                    Pipe Thickness <br />
                    <div>{{pipeline.designSpecs.pipeThickness}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                DESIGN SPECIFICATIONS For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Size Diameter (inches) <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.sizeDiameter==pipeline.designSpecs.sizeDiameter">{{pipeline.designSpecsInreview.sizeDiameter}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.sizeDiameter!=pipeline.designSpecs.sizeDiameter">{{pipeline.designSpecsInreview.sizeDiameter}}</div>
                </div>
                <div class="left textLabel">
                    Maximum Velocity (m/s) <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.maxVelocity==pipeline.designSpecs.maxVelocity">{{pipeline.designSpecsInreview.maxVelocity}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.maxVelocity!=pipeline.designSpecs.maxVelocity">{{pipeline.designSpecsInreview.maxVelocity}}</div>
                </div>
               
                <div class="left textLabel">
                    Capacity (MMscf) <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.capacity==pipeline.designSpecs.capacity">{{pipeline.designSpecsInreview.capacity}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.capacity!=pipeline.designSpecs.capacity">{{pipeline.designSpecsInreview.capacity}}</div>
                </div>
                <div class="right textLabel">
                    Material <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.material==pipeline.designSpecs.material">{{pipeline.designSpecsInreview.material}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.material!=pipeline.designSpecs.material">{{pipeline.designSpecsInreview.material}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Design Life (years) <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.designLife==pipeline.designSpecs.designLife">{{pipeline.designSpecsInreview.designLife}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.designLife!=pipeline.designSpecs.designLife">{{pipeline.designSpecsInreview.designLife}}</div>
                </div>
                <div class="left textLabel">
                    Wall Thickness / Schedule (inches) <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.wallThicknessSchedule==pipeline.designSpecs.wallThicknessSchedule">{{pipeline.designSpecsInreview.wallThicknessSchedule}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.wallThicknessSchedule!=pipeline.designSpecs.wallThicknessSchedule">{{pipeline.designSpecsInreview.wallThicknessSchedule}}</div>
                </div>
                
                <div class="left textLabel">
                    Steel Type <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.steelType==pipeline.designSpecs.steelType">{{pipeline.designSpecsInreview.steelType}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.steelType!=pipeline.designSpecs.steelType">{{pipeline.designSpecsInreview.steelType}}</div>
                </div>
                <div class="right textLabel">
                    Pipe Class <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.pipeClass==pipeline.designSpecs.pipeClass">{{pipeline.designSpecsInreview.pipeClass}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.pipeClass!=pipeline.designSpecs.pipeClass">{{pipeline.designSpecsInreview.pipeClass}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Pipe Schedule <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.pipeSchedule==pipeline.designSpecs.pipeSchedule">{{pipeline.designSpecsInreview.pipeSchedule}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.pipeSchedule!=pipeline.designSpecs.pipeSchedule">{{pipeline.designSpecsInreview.pipeSchedule}}</div>
                </div>
                <div class="left textLabel">
                    Pipe Thickness <br />
                    <div class="green" *ngIf="pipeline.designSpecsInreview.pipeThickness==pipeline.designSpecs.pipeThickness">{{pipeline.designSpecsInreview.pipeThickness}}</div>
                    <div class="red" *ngIf="pipeline.designSpecsInreview.pipeThickness!=pipeline.designSpecs.pipeThickness">{{pipeline.designSpecsInreview.pipeThickness}}</div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" *ngIf="pipeline.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OPERATING CONDITIONS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Design Pressure (barg) <br />
                    <div>{{pipeline.operatingConditions.designPressure}}</div>
                </div>
                <div class="left textLabel">
                    Inlet Pressure (barg) <br />
                    <div>{{pipeline.operatingConditions.inletPressure}}</div>
                </div>
                <div class="left textLabel">
                    Delivery Pressure (barg) <br />
                    <div>{{pipeline.operatingConditions.deliveryPressure}}</div>
                </div>
                <div class="left textLabel">
                    Operating Tempratures (&deg;C) <br />
                    <div>{{pipeline.operatingConditions.operatingTemp}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Gas Type <br />
                    <div>{{pipeline.operatingConditions.gasType}}</div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OPERATING CONDITIONS For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Design Pressure (barg) <br />
                    <div class="green" *ngIf="pipeline.operatingConditionsInreview.designPressure==pipeline.operatingConditions.designPressure">{{pipeline.operatingConditionsInreview.designPressure}}</div>
                    <div class="red" *ngIf="pipeline.operatingConditionsInreview.designPressure!=pipeline.operatingConditions.designPressure">{{pipeline.operatingConditionsInreview.designPressure}}</div>
                </div>
                <div class="left textLabel">
                    Inlet Pressure (barg) <br />
                    <div class="green" *ngIf="pipeline.operatingConditionsInreview.inletPressure==pipeline.operatingConditions.inletPressure">{{pipeline.operatingConditionsInreview.inletPressure}}</div>
                    <div class="red" *ngIf="pipeline.operatingConditionsInreview.inletPressure!=pipeline.operatingConditions.inletPressure">{{pipeline.operatingConditionsInreview.inletPressure}}</div>
                </div>
                <div class="left textLabel">
                    Delivery Pressure (barg) <br />
                    <div class="green" *ngIf="pipeline.operatingConditionsInreview.deliveryPressure==pipeline.operatingConditions.deliveryPressure">{{pipeline.operatingConditionsInreview.deliveryPressure}}</div>
                    <div class="red" *ngIf="pipeline.operatingConditionsInreview.deliveryPressure!=pipeline.operatingConditions.deliveryPressure">{{pipeline.operatingConditionsInreview.deliveryPressure}}</div>
                </div>
                <div class="left textLabel">
                    Operating Tempratures (&deg;C) <br />
                    <div class="green" *ngIf="pipeline.operatingConditionsInreview.operatingTemp==pipeline.operatingConditions.operatingTemp">{{pipeline.operatingConditionsInreview.operatingTemp}}</div>
                    <div class="red" *ngIf="pipeline.operatingConditionsInreview.operatingTemp!=pipeline.operatingConditions.operatingTemp">{{pipeline.operatingConditionsInreview.operatingTemp}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Gas Type <br />
                    <div class="green" *ngIf="pipeline.operatingConditionsInreview.gasType==pipeline.operatingConditions.gasType">{{pipeline.operatingConditionsInreview.gasType}}</div>
                    <div class="red" *ngIf="pipeline.operatingConditionsInreview.gasType!=pipeline.operatingConditions.gasType">{{pipeline.operatingConditionsInreview.gasType}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px half" *ngIf="pipeline.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ROUTING
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Tie-in-point Coordinates <br />
                    <div>{{pipeline.routing.tieInPointCoordinates}}</div>
                </div>
                <div class="left textLabel">
                    From <br />
                    <div>{{pipeline.routing.from}}</div>
                </div>
                <div class="left textLabel">
                    To <br />
                    <div>{{pipeline.routing.to}}</div>
                </div>
                <div class="left textLabel">
                    Terrain <br />
                    <div>{{pipeline.routing.terrain}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Length (kms) <br />
                    <div>{{pipeline.routing.Length}}</div>
                </div>
                <div class="left textLabel">
                    RoW Width (ft.) <br />
                    <div>{{pipeline.routing.rowWidth}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                ROUTING For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Tie-in-point Coordinates <br />
                    <div class="green" *ngIf="pipeline.routingInreview.tieInPointCoordinates==pipeline.routing.tieInPointCoordinates">{{pipeline.routingInreview.tieInPointCoordinates}}</div>
                    <div class="red" *ngIf="pipeline.routingInreview.tieInPointCoordinates!=pipeline.routing.tieInPointCoordinates">{{pipeline.routingInreview.tieInPointCoordinates}}</div>
                </div>
                <div class="left textLabel">
                    From <br />
                    <div class="green" *ngIf="pipeline.routingInreview.from==pipeline.routing.from">{{pipeline.routingInreview.from}}</div>
                    <div class="red" *ngIf="pipeline.routingInreview.from!=pipeline.routing.from">{{pipeline.routingInreview.from}}</div>
                </div>
                <div class="left textLabel">
                    To <br />
                    <div class="green" *ngIf="pipeline.routingInreview.to==pipeline.routing.to">{{pipeline.routingInreview.to}}</div>
                    <div class="red" *ngIf="pipeline.routingInreview.to!=pipeline.routing.to">{{pipeline.routingInreview.to}}</div>
                </div>
                <div class="left textLabel">
                    Terrain <br />
                    <div class="green" *ngIf="pipeline.routingInreview.terrain==pipeline.routing.terrain">{{pipeline.routingInreview.terrain}}</div>
                    <div class="red" *ngIf="pipeline.routingInreview.terrain!=pipeline.routing.terrain">{{pipeline.routingInreview.terrain}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Length (kms) <br />
                    <div class="green" *ngIf="pipeline.routingInreview.Length==pipeline.routing.Length">{{pipeline.routingInreview.Length}}</div>
                    <div class="red" *ngIf="pipeline.routingInreview.Length!=pipeline.routing.Length">{{pipeline.routingInreview.Length}}</div>
                </div>
                <div class="left textLabel">
                    RoW Width (ft.) <br />
                    <div class="green" *ngIf="pipeline.routingInreview.rowWidth==pipeline.routing.rowWidth">{{pipeline.routingInreview.rowWidth}}</div>
                    <div class="red" *ngIf="pipeline.routingInreview.rowWidth!=pipeline.routing.rowWidth">{{pipeline.routingInreview.rowWidth}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel class="marginTop50px half" *ngIf="pipeline.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT EXECUTION
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <div>{{pipeline.projectExecution.epcContractor}}</div>
                </div>
                <div class="left textLabel">
                    Construction Start Date <br />
                    <div>{{pipeline.projectExecution.constructionStart}}</div>                    
                </div>
                <div class="left textLabel">
                    Construction End Date <br />                    
                    <div>{{pipeline.projectExecution.constructionEnd}}</div>                    
                </div>
                <div class="left textLabel">
                    Operation Start Date <br />
                    <div>{{pipeline.projectExecution.operationStart}}</div>                    
                </div>                
            </div>
            <div class="row">
                <div class="left textLabel">
                    Estimated Cost (US$) <br />
                    <div>{{pipeline.projectExecution.estimatedCost}}</div>
                </div>
                <div class="left textLabel">
                    Derating (%) <br />
                    <div>{{pipeline.projectExecution.derating}}</div>
                </div>
                <div class="left textLabel">
                    Status <br />
                    <div>{{pipeline.projectExecution.status}}</div>
                </div>
                <div class="right textLabel">
                    EIA/ESIA Approval Date  <br />
                    <div>{{pipeline.projectExecution.eiaApprovalDate}}</div>
                </div>                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                PROJECT EXECUTION For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    EPC Contractor <br />
                    <div  class="green" *ngIf="pipeline.projectExecutionInreview.epcContractor==pipeline.projectExecution.epcContractor">{{pipeline.projectExecutionInreview.epcContractor}}</div>
                    <div  class="red" *ngIf="pipeline.projectExecutionInreview.epcContractor!=pipeline.projectExecution.epcContractor">{{pipeline.projectExecutionInreview.epcContractor}}</div>
                </div>
                <div class="left textLabel">
                    Construction Start Date <br />
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.constructionStart==pipeline.projectExecution.constructionStart">{{pipeline.projectExecutionInreview.constructionStart}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.constructionStart!=pipeline.projectExecution.constructionStart">{{pipeline.projectExecutionInreview.constructionStart}}</div>
                    
                </div>
                <div class="left textLabel">
                    Construction End Date <br />
                    
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.constructionEnd==pipeline.projectExecution.constructionEnd">{{pipeline.projectExecutionInreview.constructionEnd}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.constructionEnd!=pipeline.projectExecution.constructionEnd">{{pipeline.projectExecutionInreview.constructionEnd}}</div>
                    
                </div>
                <div class="left textLabel">
                    Operation Start Date <br />
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.operationStart==pipeline.projectExecution.operationStart">{{pipeline.projectExecutionInreview.operationStart}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.operationStart!=pipeline.projectExecution.operationStart">{{pipeline.projectExecutionInreview.operationStart}}</div>
                    
                </div>
                
            </div>
            <div class="row">
                <div class="left textLabel">
                    Estimated Cost (US$) <br />
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.estimatedCost==pipeline.projectExecution.estimatedCost">{{pipeline.projectExecutionInreview.estimatedCost}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.estimatedCost!=pipeline.projectExecution.estimatedCost">{{pipeline.projectExecutionInreview.estimatedCost}}</div>
                </div>
                <div class="left textLabel">
                    Derating (%) <br />
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.derating==pipeline.projectExecution.derating">{{pipeline.projectExecutionInreview.derating}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.derating!=pipeline.projectExecution.derating">{{pipeline.projectExecutionInreview.derating}}</div>
                </div>
                <div class="left textLabel">
                    Status <br />
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.status==pipeline.projectExecution.status">{{pipeline.projectExecutionInreview.status}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.status!=pipeline.projectExecution.status">{{pipeline.projectExecutionInreview.status}}</div>
                </div>
                <div class="right textLabel">
                    EIA/ESIA Approval Date  <br />
                    <div class="green" *ngIf="pipeline.projectExecutionInreview.eiaApprovalDate==pipeline.projectExecution.eiaApprovalDate">{{pipeline.projectExecutionInreview.eiaApprovalDate}}</div>
                    <div class="red" *ngIf="pipeline.projectExecutionInreview.eiaApprovalDate!=pipeline.projectExecution.eiaApprovalDate">{{pipeline.projectExecutionInreview.eiaApprovalDate}}</div>
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" *ngIf="pipeline.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OPERATING HISTORY
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Pipeline Purpose <br />
                    <div>{{pipeline.operatingHistory.pipelinePurpose}}</div>
                </div>
                <div class="left textLabel">
                    O & M <br />
                    <div>{{pipeline.operatingHistory.onm}}</div>
                </div>
                <div class="left textLabel">
                    Leaks(Date) <br />
                    <div>{{pipeline.operatingHistory.leaks}}</div>
                </div>
                <div class="right textLabel">
                    Leak Type <br />
                    <div>{{pipeline.operatingHistory.leakType}}</div>
                </div>
                
            </div>
            <div class="row">
                <div class="left textLabel">
                    Market Delivery <br />
                    <div>{{pipeline.operatingHistory.marketDelivery}}</div>
                </div>
                <div class="left textLabel">
                    Compressor Stations <br />
                    <div>{{pipeline.operatingHistory.compressorStations}}</div>
                </div>
                <div class="left textLabel">
                    Pigging Stations <br />
                    <div>{{pipeline.operatingHistory.piggingStations}}</div>
                </div>
                <div class="right textLabel">
                    Valve Stations <br />
                    <div>{{pipeline.operatingHistory.valveStations}}</div>
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OPERATING HISTORY For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Pipeline Purpose <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.pipelinePurpose==pipeline.operatingHistory.pipelinePurpose">{{pipeline.operatingHistoryInreview.pipelinePurpose}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.pipelinePurpose!=pipeline.operatingHistory.pipelinePurpose">{{pipeline.operatingHistoryInreview.pipelinePurpose}}</div>
                </div>
                <div class="left textLabel">
                    O & M <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.onm==pipeline.operatingHistory.onm">{{pipeline.operatingHistoryInreview.onm}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.onm!=pipeline.operatingHistory.onm">{{pipeline.operatingHistoryInreview.onm}}</div>
                </div>
                <div class="left textLabel">
                    Leaks(Date) <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.leaks==pipeline.operatingHistory.leaks">{{pipeline.operatingHistoryInreview.leaks}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.leaks!=pipeline.operatingHistory.leaks">{{pipeline.operatingHistoryInreview.leaks}}</div>
                </div>
                <div class="right textLabel">
                    Leak Type <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.leakType==pipeline.operatingHistory.leakType">{{pipeline.operatingHistoryInreview.leakType}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.leakType!=pipeline.operatingHistory.leakType">{{pipeline.operatingHistoryInreview.leakType}}</div>
                </div>
                
            </div>
            <div class="row">
                <div class="left textLabel">
                    Market Delivery <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.marketDelivery==pipeline.operatingHistory.marketDelivery">{{pipeline.operatingHistoryInreview.marketDelivery}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.marketDelivery!=pipeline.operatingHistory.marketDelivery">{{pipeline.operatingHistoryInreview.marketDelivery}}</div>
                </div>
                <div class="left textLabel">
                    Compressor Stations <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.compressorStations==pipeline.operatingHistory.compressorStations">{{pipeline.operatingHistoryInreview.compressorStations}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.compressorStations!=pipeline.operatingHistory.compressorStations">{{pipeline.operatingHistoryInreview.compressorStations}}</div>
                </div>
                <div class="left textLabel">
                    Pigging Stations <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.piggingStations==pipeline.operatingHistory.piggingStations">{{pipeline.operatingHistoryInreview.piggingStations}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.piggingStations!=pipeline.operatingHistory.piggingStations">{{pipeline.operatingHistoryInreview.piggingStations}}</div>
                </div>
                <div class="right textLabel">
                    Valve Stations <br />
                    <div class="green" *ngIf="pipeline.operatingHistoryInreview.valveStations==pipeline.operatingHistory.valveStations">{{pipeline.operatingHistoryInreview.valveStations}}</div>
                    <div class="red" *ngIf="pipeline.operatingHistoryInreview.valveStations!=pipeline.operatingHistory.valveStations">{{pipeline.operatingHistoryInreview.valveStations}}</div>
                </div>
                
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="marginTop50px half" *ngIf="pipeline.basic.facilityName" [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OTHERS
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Odorized <br />
                    <div>{{pipeline.others.odorized}}</div>
                </div>
                <div class="left textLabel">
                    Pipeline Markers <br />
                    <div>{{pipeline.others.pipelineMarkers}}</div>
                </div>
                <div class="left textLabel">
                    Open Access <br />
                    <div>{{pipeline.others.openAccess}}</div>
                </div>
                
                <div class="right textLabel">
                    River Crossing <br />
                    <div>{{pipeline.others.riverCrossing}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Linked To CityGate <br />
                    <div>{{pipeline.others.linkedToCityGate}}</div>
                </div>
                <div class="left textLabel">
                    Linked to Customer <br />
                    <div>{{pipeline.others.linkedToCustomer}}</div>
                </div>
                <div class="left textLabel">
                    Linked to Processing Plant <br />
                    <div>{{pipeline.others.linkedToProcessingPlant}}</div>
                </div>
                
                <div class="right textLabel">
                    Linked to Manifold <br />
                    <div>{{pipeline.others.linkedToManifold}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Interconnected to Other Pipeline <br />
                    <div>{{pipeline.others.interconnectedToOtherPipeline}}</div>
                </div>
                
                <div class="left textLabel">
                    Pipe Coating <br />
                    <div>{{pipeline.others.pipeCoating}}</div>
                </div>

                <div class="left textLabel">
                    Corrosion Management <br />
                    <div>{{pipeline.others.corrosionManagement}}</div>
                </div>
                <div class="right textLabel">
                    Type of Corrosion Management <br />
                    <div>{{pipeline.others.typeOfCorrosionManagement}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Rivers Crossed <br />
                    <div>{{pipeline.others.riversCrossed}}</div>
                </div>
                <div class="left textLabel">
                    Name of Customer <br />
                    <div>{{pipeline.others.nameOfCustomer}}</div>
                </div>
                <div class="left textLabel">
                    Name of CityGate <br />
                    <div>{{pipeline.others.nameOfCityGate}}</div>
                </div>
                <div class="right textLabel">
                    Name of Processing Plant <br />
                    <div>{{pipeline.others.nameOfProcessingPlant}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Notes <br />
                    <div>{{pipeline.others.notes}}</div>
                </div>
                <div class="left textLabel">
                    Profile <br />
                    <div>{{pipeline.others.profile}}</div>
                </div>
            </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
            <mat-expansion-panel-header>
            <mat-panel-title>
                OTHERS For Review
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row margin">
                <div class="left textLabel">
                    Odorized <br />
                    <div class="green" *ngIf="pipeline.othersInreview.odorized==pipeline.others.odorized">{{pipeline.othersInreview.odorized}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.odorized!=pipeline.others.odorized">{{pipeline.othersInreview.odorized}}</div>
                </div>
                <div class="left textLabel">
                    Pipeline Markers <br />
                    <div class="green" *ngIf="pipeline.othersInreview.pipelineMarkers==pipeline.others.pipelineMarkers">{{pipeline.othersInreview.pipelineMarkers}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.pipelineMarkers!=pipeline.others.pipelineMarkers">{{pipeline.othersInreview.pipelineMarkers}}</div>
                </div>
                <div class="left textLabel">
                    Open Access <br />
                    <div class="green" *ngIf="pipeline.othersInreview.openAccess==pipeline.others.openAccess">{{pipeline.othersInreview.openAccess}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.openAccess!=pipeline.others.openAccess">{{pipeline.othersInreview.openAccess}}</div>
                </div>
                
                <div class="right textLabel">
                    River Crossing <br />
                    <div class="green" *ngIf="pipeline.othersInreview.riverCrossing==pipeline.others.riverCrossing">{{pipeline.othersInreview.riverCrossing}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.riverCrossing!=pipeline.others.riverCrossing">{{pipeline.othersInreview.riverCrossing}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Linked To CityGate <br />
                    <div class="green" *ngIf="pipeline.othersInreview.linkedToCityGate==pipeline.others.linkedToCityGate">{{pipeline.othersInreview.linkedToCityGate}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.linkedToCityGate!=pipeline.others.linkedToCityGate">{{pipeline.othersInreview.linkedToCityGate}}</div>
                </div>
                <div class="left textLabel">
                    Linked to Customer <br />
                    <div class="green" *ngIf="pipeline.othersInreview.linkedToCustomer==pipeline.others.linkedToCustomer">{{pipeline.othersInreview.linkedToCustomer}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.linkedToCustomer!=pipeline.others.linkedToCustomer">{{pipeline.othersInreview.linkedToCustomer}}</div>
                </div>
                <div class="left textLabel">
                    Linked to Processing Plant <br />
                    <div class="green" *ngIf="pipeline.othersInreview.linkedToProcessingPlant==pipeline.others.linkedToProcessingPlant">{{pipeline.othersInreview.linkedToProcessingPlant}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.linkedToProcessingPlant!=pipeline.others.linkedToProcessingPlant">{{pipeline.othersInreview.linkedToProcessingPlant}}</div>
                </div>
                
                <div class="right textLabel">
                    Linked to Manifold <br />
                    <div class="green" *ngIf="pipeline.othersInreview.linkedToManifold==pipeline.others.linkedToManifold">{{pipeline.othersInreview.linkedToManifold}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.linkedToManifold!=pipeline.others.linkedToManifold">{{pipeline.othersInreview.linkedToManifold}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Interconnected to Other Pipeline <br />
                    <div class="green" *ngIf="pipeline.othersInreview.interconnectedToOtherPipeline==pipeline.others.interconnectedToOtherPipeline">{{pipeline.othersInreview.interconnectedToOtherPipeline}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.interconnectedToOtherPipeline!=pipeline.others.interconnectedToOtherPipeline">{{pipeline.othersInreview.interconnectedToOtherPipeline}}</div>
                </div>
                
                <div class="left textLabel">
                    Pipe Coating <br />
                    <div class="green" *ngIf="pipeline.othersInreview.pipeCoating==pipeline.others.pipeCoating">{{pipeline.othersInreview.pipeCoating}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.pipeCoating!=pipeline.others.pipeCoating">{{pipeline.othersInreview.pipeCoating}}</div>
                </div>

                <div class="left textLabel">
                    Corrosion Management <br />
                    <div class="green" *ngIf="pipeline.othersInreview.corrosionManagement==pipeline.others.corrosionManagement">{{pipeline.othersInreview.corrosionManagement}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.corrosionManagement!=pipeline.others.corrosionManagement">{{pipeline.othersInreview.corrosionManagement}}</div>
                </div>
                <div class="right textLabel">
                    Type of Corrosion Management <br />
                    <div class="green" *ngIf="pipeline.othersInreview.typeOfCorrosionManagement==pipeline.others.typeOfCorrosionManagement">{{pipeline.othersInreview.typeOfCorrosionManagement}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.typeOfCorrosionManagement!=pipeline.others.typeOfCorrosionManagement">{{pipeline.othersInreview.typeOfCorrosionManagement}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Rivers Crossed <br />
                    <div class="green" *ngIf="pipeline.othersInreview.riversCrossed==pipeline.others.riversCrossed">{{pipeline.othersInreview.riversCrossed}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.riversCrossed!=pipeline.others.riversCrossed">{{pipeline.othersInreview.riversCrossed}}</div>
                </div>
                <div class="left textLabel">
                    Name of Customer <br />
                    <div class="green" *ngIf="pipeline.othersInreview.nameOfCustomer==pipeline.others.nameOfCustomer">{{pipeline.othersInreview.nameOfCustomer}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.nameOfCustomer!=pipeline.others.nameOfCustomer">{{pipeline.othersInreview.nameOfCustomer}}</div>
                </div>
                <div class="left textLabel">
                    Name of CityGate <br />
                    <div class="green" *ngIf="pipeline.othersInreview.nameOfCityGate==pipeline.others.nameOfCityGate">{{pipeline.othersInreview.nameOfCityGate}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.nameOfCityGate!=pipeline.others.nameOfCityGate">{{pipeline.othersInreview.nameOfCityGate}}</div>
                </div>
                <div class="right textLabel">
                    Name of Processing Plant <br />
                    <div class="green" *ngIf="pipeline.othersInreview.nameOfProcessingPlant==pipeline.others.nameOfProcessingPlant">{{pipeline.othersInreview.nameOfProcessingPlant}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.nameOfProcessingPlant!=pipeline.others.nameOfProcessingPlant">{{pipeline.othersInreview.nameOfProcessingPlant}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Notes <br />
                    <div class="green" *ngIf="pipeline.othersInreview.notes==pipeline.others.notes">{{pipeline.othersInreview.notes}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.notes!=pipeline.others.notes">{{pipeline.othersInreview.notes}}</div>
                </div>
                <div class="left textLabel">
                    Profile <br />
                    <div class="green" *ngIf="pipeline.othersInreview.profile==pipeline.others.profile">{{pipeline.othersInreview.profile}}</div>
                    <div class="red" *ngIf="pipeline.othersInreview.profile!=pipeline.others.profile">{{pipeline.othersInreview.profile}}</div>
                </div>
            </div>
        </mat-expansion-panel>
</div>

<div *ngIf="facility && facility.type==4" class="clearboth">
    <mat-expansion-panel [expanded]=true *ngIf="facility.basic.facilityName" class="marginTop50px half">
        <mat-expansion-panel-header>
        <mat-panel-title>
            BRIEF INFORMATION
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="powerplantForm">
            <div class="row margin">
                <div class="left textLabel">
                    Facility Name <br />
                    <div>{{offtaker.basic.facilityName}}</div>
                </div>
                <div class="left textLabel">
                    Project Investors/Developers <br />
                    <div>{{offtaker.basic.projectInvestors}}</div>
                </div>

                <div class="left textLabel">
                    Project Financier <br />
                    <div>{{offtaker.basic.projectFinancier}}</div>
                </div>
                <div class="right textLabel">
                    Classification <br />
                    <div>{{offtaker.basic.classification}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Owner <br />
                    <div>{{offtaker.basic.owner}}</div>
                </div>
                <div class="left textLabel">
                    Date of Transfer to Owner  <br />
                    <div>{{offtaker.basic.dateTransferOwner}}</div>
                    
                </div>

                <div class="left textLabel">
                    LinkedIn Account <br />
                    <div>{{offtaker.basic.linkedInAccount}}</div>
                </div>

                <div class="right textLabel">
                    Company Website <br />
                    <div>{{offtaker.basic.companyWebsite}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Owner Type <br />
                    <div>{{offtaker.basic.ownerType}}</div>
                </div>
                <div class="left textLabel">
                    Operator <br />
                    <div>{{offtaker.basic.operator}}</div>
                </div>

                <div class="left textLabel">
                    Location <br />
                    <div>{{offtaker.basic.location}}</div>
                </div>
                <div class="right textLabel">
                    Country <br />
                    <div>{{offtaker.basic.country}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    GPS Location <br />
                    <div>{{offtaker.basic.gpslocation}}</div>
                </div>
                <div class="left textLabel">
                    National Regulatory Agency <br />
                    <div>{{offtaker.basic.nationalRegulatoryAgency}}</div>
                </div>

                <div class="left textLabel">
                    National Policy Administrator <br />
                    <div>{{offtaker.basic.nationalPolicyAdmin}}</div>
                </div>
                <div class="left textLabel">
                    Type <br />
                    <div>{{offtaker.basic.powerPlantType}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant Status <br />
                    <div>{{offtaker.basic.plantStatus}}</div>
                </div>
                <div class="left textLabel">
                    Region <br />
                    <div>{{offtaker.basic.region}}</div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]=true class="marginTop50px half" >
        <mat-expansion-panel-header>
        <mat-panel-title>
            BRIEF INFORMATION For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="powerplantForm">
            <div class="row margin">
                <div class="left textLabel">
                    Facility Name <br />
                    <div class="green" *ngIf="offtaker.basicInreview.facilityName == offtaker.basic.facilityName">{{offtaker.basicInreview.facilityName}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.facilityName != offtaker.basic.facilityName">{{offtaker.basicInreview.facilityName}}</div>
                </div>
                <div class="left textLabel">
                    Project Investors/Developers <br />
                    <div class="green" *ngIf="offtaker.basicInreview.projectInvestors==offtaker.basic.projectInvestors">{{offtaker.basicInreview.projectInvestors}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.projectInvestors!=offtaker.basic.projectInvestors">{{offtaker.basicInreview.projectInvestors}}</div>
                </div>

                <div class="left textLabel">
                    Project Financier <br />
                    <div class="green" *ngIf="offtaker.basicInreview.projectFinancier==offtaker.basic.projectFinancier">{{offtaker.basicInreview.projectFinancier}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.projectFinancier!=offtaker.basic.projectFinancier">{{offtaker.basicInreview.projectFinancier}}</div>
                </div>
                <div class="right textLabel">
                    Classification <br />
                    <div *ngIf="offtaker.basicInreview.classification==offtaker.basic.classification" class="green">{{offtaker.basicInreview.classification}}</div>
                    <div *ngIf="offtaker.basicInreview.classification!=offtaker.basic.classification" class="red">{{offtaker.basicInreview.classification}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Owner <br />
                    <div class="green" *ngIf="offtaker.basicInreview.owner==offtaker.basic.owner">{{offtaker.basicInreview.owner}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.owner!=offtaker.basic.owner">{{offtaker.basicInreview.owner}}</div>
                </div>
                <div class="left textLabel">
                    Date of Transfer to Owner  <br />
                    <div class="green" *ngIf="offtaker.basicInreview.dateTransferOwner==offtaker.basic.dateTransferOwner">{{offtaker.basicInreview.dateTransferOwner}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.dateTransferOwner!=offtaker.basic.dateTransferOwner">{{offtaker.basicInreview.dateTransferOwner}}</div>
                </div>

                <div class="left textLabel">
                    LinkedIn Account <br />
                    <div class="green" *ngIf="offtaker.basicInreview.linkedInAccount==offtaker.basic.linkedInAccount">{{offtaker.basicInreview.linkedInAccount}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.linkedInAccount!=offtaker.basic.linkedInAccount">{{offtaker.basicInreview.linkedInAccount}}</div>
                </div>

                <div class="right textLabel">
                    Company Website <br />
                    <div *ngIf="offtaker.basicInreview.companyWebsite==offtaker.basic.companyWebsite" class="green">{{offtaker.basicInreview.companyWebsite}}</div>
                    <div *ngIf="offtaker.basicInreview.companyWebsite!=offtaker.basic.companyWebsite" class="red">{{offtaker.basicInreview.companyWebsite}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Owner Type <br />
                    <div *ngIf="offtaker.basicInreview.ownerType==offtaker.basic.ownerType" class="green">{{offtaker.basicInreview.ownerType}}</div>
                    <div *ngIf="offtaker.basicInreview.ownerType!=offtaker.basic.ownerType" class="red">{{offtaker.basicInreview.ownerType}}</div>
                </div>
                <div class="left textLabel">
                    Operator <br />
                    <div class="green" *ngIf="offtaker.basicInreview.operator==offtaker.basic.operator">{{offtaker.basicInreview.operator}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.operator!=offtaker.basic.operator">{{offtaker.basicInreview.operator}}</div>
                </div>

                <div class="left textLabel">
                    Location <br />
                    <div class="green" *ngIf="offtaker.basicInreview.location==offtaker.basic.location">{{offtaker.basicInreview.location}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.location!=offtaker.basic.location">{{offtaker.basicInreview.location}}</div>
                </div>
                <div class="right textLabel">
                    Country <br />
                    <div class="green" *ngIf="offtaker.basicInreview.country==offtaker.basic.country">{{offtaker.basicInreview.country}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.country!=offtaker.basic.country">{{offtaker.basicInreview.country}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    GPS Location <br />
                    <div class="green" *ngIf="offtaker.basicInreview.gpslocation==offtaker.basic.gpslocation">{{offtaker.basicInreview.gpslocation}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.gpslocation!=offtaker.basic.gpslocation">{{offtaker.basicInreview.gpslocation}}</div>
                </div>
                <div class="left textLabel">
                    National Regulatory Agency <br />
                    <div class="green" *ngIf="offtaker.basicInreview.nationalRegulatoryAgency==offtaker.basic.nationalRegulatoryAgency">{{offtaker.basicInreview.nationalRegulatoryAgency}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.nationalRegulatoryAgency!=offtaker.basic.nationalRegulatoryAgency">{{offtaker.basicInreview.nationalRegulatoryAgency}}</div>
                </div>

                <div class="left textLabel">
                    National Policy Administrator <br />
                    <div class="green" *ngIf="offtaker.basicInreview.nationalPolicyAdmin==offtaker.basic.nationalPolicyAdmin">{{offtaker.basicInreview.nationalPolicyAdmin}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.nationalPolicyAdmin!=offtaker.basic.nationalPolicyAdmin">{{offtaker.basicInreview.nationalPolicyAdmin}}</div>
                </div>
                <div class="left textLabel">
                    Type <br />
                    <div *ngIf="offtaker.basicInreview.powerPlantType==offtaker.basic.powerPlantType" class="green">{{offtaker.basicInreview.powerPlantType}}</div>
                    <div *ngIf="offtaker.basicInreview.powerPlantType!=offtaker.basic.powerPlantType" class="red">{{offtaker.basicInreview.powerPlantType}}</div>
                </div>
            </div>
            <div class="row">
                <div class="left textLabel">
                    Plant Status <br />
                    <div class="green" *ngIf="offtaker.basicInreview.plantStatus==offtaker.basic.plantStatus">{{offtaker.basicInreview.plantStatus}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.plantStatus!=offtaker.basic.plantStatus">{{offtaker.basicInreview.plantStatus}}</div>
                </div>
                <div class="left textLabel">
                    Region <br />
                    <div class="green" *ngIf="offtaker.basicInreview.region==offtaker.basic.region">{{offtaker.basicInreview.region}}</div>
                    <div class="red" *ngIf="offtaker.basicInreview.region!=offtaker.basic.region">{{offtaker.basicInreview.region}}</div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>


   <mat-expansion-panel class="marginTop50px half" *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            DESIGN SPECIFICATIONS
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                Capacity <br />
                <div>{{offtaker.designSpecs.capacity}}</div>
            </div>
            <div class="left textLabel">
                Net Eletrical Out <br />
                <div>{{offtaker.designSpecs.netElectricalOut}}</div>
            </div>
            <div class="left textLabel">
                No. of Plants <br />
                <div>{{offtaker.designSpecs.nosOfplants}}</div>
            </div>
            <div class="left textLabel">
                Gas Requirements (MMscf) <br />
                <div>{{offtaker.designSpecs.gasRequirements}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                No. of Plant Type <br />
                <div>{{offtaker.designSpecs.nosOfPlantType}}</div>
            </div>
            <div class="left textLabel">
                Capacity of Plant Type <br />
                <div>{{offtaker.designSpecs.capacityOfPlantType}}</div>
            </div>

            <div class="left textLabel">
                Capacity of Plant Type 2 <br />
                <div>{{offtaker.designSpecs.capacityOfPlantType2}}</div>
            </div>
            <div class="left textLabel">
                Plant OEM <br />
                <div>{{offtaker.designSpecs.plantOEM}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Plant 2 OEM <br />
                <div>{{offtaker.designSpecs.plant2OEM}}</div>
            </div>
            <div class="left textLabel">
                Capacity of Black Start Generator  <br />
                <div>{{offtaker.designSpecs.capacityOfBlackStart}}</div>
            </div>

            <div class="left textLabel">
                Black Start Fuel  <br />
                <div>{{offtaker.designSpecs.blackStartFuel}}</div>            
            </div>

            <div class="right textLabel">
                Regulatory License Date  <br />
                <div>{{offtaker.designSpecs.regulatoryLicenseDate}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
               Target Customer <br />
                <div>{{offtaker.designSpecs.targetCustomer}}</div>               
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            DESIGN SPECIFICATIONS For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                Capacity <br />
                <div *ngIf="offtaker.designSpecsInreview.capacity==offtaker.designSpecs.capacity" class="green">{{offtaker.designSpecsInreview.capacity}}</div>
                <div *ngIf="offtaker.designSpecsInreview.capacity!=offtaker.designSpecs.capacity" class="red">{{offtaker.designSpecsInreview.capacity}}</div>
            </div>
            <div class="left textLabel">
                Net Capacity<br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.netCapacity==offtaker.designSpecs.netCapacity">{{offtaker.designSpecsInreview.netCapacity}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.netCapacity!=offtaker.designSpecs.netCapacity">{{offtaker.designSpecsInreview.netCapacity}}</div>
            </div>
            <div class="left textLabel">
                No. of Plants <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.nosOfPlants==offtaker.designSpecs.nosOfPlants">{{offtaker.designSpecsInreview.nosOfPlants}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.nosOfPlants!=offtaker.designSpecs.nosOfPlants">{{offtaker.designSpecsInreview.nosOfPlants}}</div>
            </div>

            <div class="right textLabel">
                Gas Requirements (MMscf) <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.gasRequirements==offtaker.designSpecs.gasRequirements">{{offtaker.designSpecsInreview.gasRequirements}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.gasRequirements!=offtaker.designSpecs.gasRequirements">{{offtaker.designSpecsInreview.gasRequirements}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                No. of Plant Type <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.nosOfPlantType==offtaker.designSpecs.nosOfPlantType">{{offtaker.designSpecsInreview.nosOfPlantType}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.nosOfPlantType!=offtaker.designSpecs.nosOfPlantType">{{offtaker.designSpecsInreview.nosOfPlantType}}</div>
            </div>
            <div class="left textLabel">
                Capacity of Plant Type <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.capacityOfPlantType==offtaker.designSpecs.capacityOfPlantType">{{offtaker.designSpecsInreview.capacityOfPlantType}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.capacityOfPlantType!=offtaker.designSpecs.capacityOfPlantType">{{offtaker.designSpecsInreview.capacityOfPlantType}}</div>
            </div>

            <div class="left textLabel">
                Capacity of Plant Type 2 <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.capacityOfPlantType2==offtaker.designSpecs.capacityOfPlantType2">{{offtaker.designSpecsInreview.capacityOfPlantType2}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.capacityOfPlantType2!=offtaker.designSpecs.capacityOfPlantType2">{{offtaker.designSpecsInreview.capacityOfPlantType2}}</div>
            </div>
            <div class="right textLabel">
                Plant OEM <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.plantOEM==offtaker.designSpecs.plantOEM">{{offtaker.designSpecsInreview.plantOEM}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.plantOEM!=offtaker.designSpecs.plantOEM">{{offtaker.designSpecsInreview.plantOEM}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Plant 2 OEM <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.plant2OEM==offtaker.designSpecs.plant2OEM">{{offtaker.designSpecsInreview.plant2OEM}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.plant2OEM!=offtaker.designSpecs.plant2OEM">{{offtaker.designSpecsInreview.plant2OEM}}</div>
            </div>
            <div class="left textLabel">
                Capacity of Black Start Generator  <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.capacityOfBlackStart==offtaker.designSpecs.capacityOfBlackStart">{{offtaker.designSpecsInreview.capacityOfBlackStart}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.capacityOfBlackStart!=offtaker.designSpecs.capacityOfBlackStart">{{offtaker.designSpecsInreview.capacityOfBlackStart}}</div>
            </div>

            <div class="left textLabel">
                Black Start Fuel  <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.blackStartFuel==offtaker.designSpecs.blackStartFuel">{{offtaker.designSpecsInreview.blackStartFuel}}</div>            
                <div class="red" *ngIf="offtaker.designSpecsInreview.blackStartFuel!=offtaker.designSpecs.blackStartFuel">{{offtaker.designSpecsInreview.blackStartFuel}}</div>            
            </div>

            <div class="right textLabel">
                Regulatory License Date  <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.regulatoryLicenseDate==offtaker.designSpecs.regulatoryLicenseDate">{{offtaker.designSpecsInreview.regulatoryLicenseDate}}</div>
                <div class="red" *ngIf="offtaker.designSpecsInreview.regulatoryLicenseDate!=offtaker.designSpecs.regulatoryLicenseDate">{{offtaker.designSpecsInreview.regulatoryLicenseDate}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
               Target Customer <br />
                <div class="green" *ngIf="offtaker.designSpecsInreview.targetCustomer==offtaker.designSpecs.targetCustomer">{{offtaker.designSpecsInreview.targetCustomer}}</div>               
                <div class="red" *ngIf="offtaker.designSpecsInreview.targetCustomer!=offtaker.designSpecs.targetCustomer">{{offtaker.designSpecsInreview.targetCustomer}}</div>               
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="marginTop50px half" *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            PROJECT MANAGEMENT
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                EPC Contractor <br />
                <div>{{offtaker.projectManagement.epcContractor}}</div>
            </div>

            <div class="left textLabel">
                Project Execution Consultant <br />
                <div>{{offtaker.projectManagement.projectExecutionConsultant}}</div>
            </div>

            <div class="left textLabel">
                Estimated Project Cost (US$) <br />
                <div>{{offtaker.projectManagement.estimatedProjCost}}</div>
            </div>
            <div class="right textLabel">
                Project Start Date  <br />
                <div>{{offtaker.projectManagement.projectStartDate}}</div>      
            </div>
        </div>
        <div class="row margin">
            <div class="left textLabel">
                Project End Date  <br />
                <div>{{offtaker.projectManagement.projectEndDate}}</div>
            </div>
            <div class="left textLabel">
                Project Commissioning Date  <br />
                <div>{{offtaker.projectManagement.projectCommissioningDate}}</div>     
            </div>
            <div class="left textLabel">
                EIA/ESIA Approval Date  <br />
                <div>{{offtaker.projectManagement.eiaApprovalDate}}</div>  
            </div>
            
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="marginTop50px half" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            PROJECT MANAGEMENT For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                EPC Contractor <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.epcContractor==offtaker.projectManagement.epcContractor">{{offtaker.projectManagementInreview.epcContractor}}</div>
                <div class="red" *ngIf="offtaker.projectManagementInreview.epcContractor!=offtaker.projectManagement.epcContractor">{{offtaker.projectManagementInreview.epcContractor}}</div>
            </div>

            <div class="left textLabel">
                Project Execution Consultant <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.projectExecutionConsultant==offtaker.projectManagement.projectExecutionConsultant">{{offtaker.projectManagementInreview.projectExecutionConsultant}}</div>
                <div class="red" *ngIf="offtaker.projectManagementInreview.projectExecutionConsultant!=offtaker.projectManagement.projectExecutionConsultant">{{offtaker.projectManagementInreview.projectExecutionConsultant}}</div>
            </div>

            <div class="left textLabel">
                Estimated Project Cost (US$) <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.estimatedProjCost==offtaker.projectManagement.estimatedProjCost">{{offtaker.projectManagementInreview.estimatedProjCost}}</div>
                <div class="red" *ngIf="offtaker.projectManagementInreview.estimatedProjCost!=offtaker.projectManagement.estimatedProjCost">{{offtaker.projectManagementInreview.estimatedProjCost}}</div>
            </div>
            <div class="right textLabel">
                Project Start Date  <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.projectStartDate==offtaker.projectManagement.projectStartDate">{{offtaker.projectManagementInreview.projectStartDate}}</div>      
                <div class="red" *ngIf="offtaker.projectManagementInreview.projectStartDate!=offtaker.projectManagement.projectStartDate">{{offtaker.projectManagementInreview.projectStartDate}}</div>      
            </div>
        </div>
        <div class="row margin">
            <div class="left textLabel">
                Project End Date  <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.projectEndDate==offtaker.projectManagement.projectEndDate">{{offtaker.projectManagementInreview.projectEndDate}}</div>
                <div class="red" *ngIf="offtaker.projectManagementInreview.projectEndDate!=offtaker.projectManagement.projectEndDate">{{offtaker.projectManagementInreview.projectEndDate}}</div>
            </div>
            <div class="left textLabel">
                Project Commissioning Date  <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.projectCommissioningDate==offtaker.projectManagement.projectCommissioningDate">{{offtaker.projectManagementInreview.projectCommissioningDate}}</div>     
                <div class="red" *ngIf="offtaker.projectManagementInreview.projectCommissioningDate!=offtaker.projectManagement.projectCommissioningDate">{{offtaker.projectManagementInreview.projectCommissioningDate}}</div>     
            </div>
            <div class="left textLabel">
                EIA/ESIA Approval Date  <br />
                <div class="green" *ngIf="offtaker.projectManagementInreview.eiaApprovalDate==offtaker.projectManagement.eiaApprovalDate">{{offtaker.projectManagementInreview.eiaApprovalDate}}</div>  
                <div class="red" *ngIf="offtaker.projectManagementInreview.eiaApprovalDate!=offtaker.projectManagement.eiaApprovalDate">{{offtaker.projectManagementInreview.eiaApprovalDate}}</div>  
            </div>
            
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="marginTop50px half" *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            COMMISSIONING
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                Certificate of Readiness <br />
                <div>{{offtaker.commissioning.certificateOfReadiness}}</div>
            </div>

            <div class="left textLabel">
                Project Commissioning Date  <br />
                <div>{{offtaker.commissioning.commissionDate}}</div>
            </div>

            <div class="left textLabel">
                Commissioning Test Certificate <br />
                <div>{{offtaker.commissioning.commissionTestCertificate}}</div>
            </div>

            <div class="right textLabel">
                Commissioning Certificate for Sync <br />
                <div>{{offtaker.commissioning.commissionCertificateSync}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Performance Test Certificate <br />
                <div>{{offtaker.commissioning.performanceTestCertificate}}</div>
            </div>

            <div class="left textLabel">
                Reliability Run Test Certificate <br />
                <div>{{offtaker.commissioning.reliabilityRunTestCertificate}}</div>
            </div>

            <div class="right textLabel">
                Capacity Test Certificate <br />
                <div>{{offtaker.commissioning.capacityTestCertificate}}</div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="marginTop50px half" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            COMMISSIONING For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                Certificate of Readiness <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.certificateOfReadiness==offtaker.commissioning.certificateOfReadiness">{{offtaker.commissioningInreview.certificateOfReadiness}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.certificateOfReadiness!=offtaker.commissioning.certificateOfReadiness">{{offtaker.commissioningInreview.certificateOfReadiness}}</div>
            </div>

            <div class="left textLabel">
                Project Commissioning Date  <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.commissionDate==offtaker.commissioning.commissionDate">{{offtaker.commissioningInreview.commissionDate}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.commissionDate!=offtaker.commissioning.commissionDate">{{offtaker.commissioningInreview.commissionDate}}</div>
            </div>

            <div class="left textLabel">
                Commissioning Test Certificate <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.commissionTestCertificate==offtaker.commissioning.commissionTestCertificate">{{offtaker.commissioningInreview.commissionTestCertificate}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.commissionTestCertificate!=offtaker.commissioning.commissionTestCertificate">{{offtaker.commissioningInreview.commissionTestCertificate}}</div>
            </div>

            <div class="right textLabel">
                Commissioning Certificate for Sync <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.commissionCertificateSync==offtaker.commissioning.commissionCertificateSync">{{offtaker.commissioningInreview.commissionCertificateSync}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.commissionCertificateSync!=offtaker.commissioning.commissionCertificateSync">{{offtaker.commissioningInreview.commissionCertificateSync}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Performance Test Certificate <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.performanceTestCertificate==offtaker.commissioning.performanceTestCertificate">{{offtaker.commissioningInreview.performanceTestCertificate}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.performanceTestCertificate!=offtaker.commissioning.performanceTestCertificate">{{offtaker.commissioningInreview.performanceTestCertificate}}</div>
            </div>

            <div class="left textLabel">
                Reliability Run Test Certificate <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.reliabilityRunTestCertificate==offtaker.commissioning.reliabilityRunTestCertificate">{{offtaker.commissioningInreview.reliabilityRunTestCertificate}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.reliabilityRunTestCertificate!=offtaker.commissioning.reliabilityRunTestCertificate">{{offtaker.commissioningInreview.reliabilityRunTestCertificate}}</div>
            </div>

            <div class="right textLabel">
                Capacity Test Certificate <br />
                <div class="green" *ngIf="offtaker.commissioningInreview.capacityTestCertificate==offtaker.commissioning.capacityTestCertificate">{{offtaker.commissioningInreview.capacityTestCertificate}}</div>
                <div class="red" *ngIf="offtaker.commissioningInreview.capacityTestCertificate!=offtaker.commissioning.capacityTestCertificate">{{offtaker.commissioningInreview.capacityTestCertificate}}</div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="marginTop50px half" *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            PLANT OPERATIONS
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                O&M Contractor Name<br />
                <div>{{offtaker.plantOperations.onmContractor}}</div>
            </div>
            <div class="left textLabel">
                Operations Start Date  <br />
                <div>{{offtaker.plantOperations.operationStart}}</div>                       
            </div>
            <div class="left textLabel">
                Name of LTSA Contractor <br />
                <div>{{offtaker.plantOperations.nameOfLTSAContractor}}</div>
            </div>
            <div class="right textLabel">
                Plant Inspections <br />
                <div>{{offtaker.plantOperations.plantInspections}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                CMMS Computerized Mtce Mgr<br />
                <div>{{offtaker.plantOperations.cmmsComputerizedMtceMgr}}</div>
            </div>
            <div class="left textLabel">
                Plant Availability Capacity <br />
                <div>{{offtaker.plantOperations.plantAvailabilityCapacity}}</div>
            </div>
                
            <div class="left textLabel">
                Unit Efficiency (%) <br />
                <div>{{offtaker.plantOperations.unitEfficiency}}</div>
            </div>
            <div class="left textLabel">
                Reactive Power Requirement (MW) <br />
                <div>{{offtaker.plantOperations.reactivePowerRequirement}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Short Circuit Ratio <br />
                <div>{{offtaker.plantOperations.shortCircuitRatio}}</div>
            </div>
            <div class="right textLabel">
                Auxiliary Power Requirement (MW) <br /> 
                <div>{{offtaker.plantOperations.auxilliaryPowerRequirement}}</div>
            </div>
        
            <div class="left textLabel">
                Transformer Rated Capacity (MVA) <br />
                <div>{{offtaker.plantOperations.trasnformerRatedCapacity}}</div>
            </div>
            <div class="left textLabel">
                Transformer Voltage Load Ratio(HV/LV) <br />
                <div>{{offtaker.plantOperations.transformerVoltageLoadRatio}}</div>
            </div>
        </div>
        <div class="row">    
            <div class="left textLabel">
                Plant Pressure (PSI) <br />
                <div>{{offtaker.plantOperations.plantPressure}}</div>
            </div>
            <div class="right textLabel">
                Steam Turbine Temperature <br /> 
                <div>{{offtaker.plantOperations.steamTurbineTemprature}}</div>
            </div>
            <div class="left textLabel">
                Plant Noise Level (dBA) <br />
                <div>{{offtaker.plantOperations.plantNoiseLevel}}</div>
            </div>
       
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="marginTop50px half" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            PLANT OPERATIONS For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row margin">
            <div class="left textLabel">
                O&M Contractor Name<br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.onmContractor==offtaker.plantOperations.onmContractor">{{offtaker.plantOperationsInreview.onmContractor}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.onmContractor!=offtaker.plantOperations.onmContractor">{{offtaker.plantOperationsInreview.onmContractor}}</div>
            </div>
            <div class="left textLabel">
                Operations Start Date  <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.operationStart==offtaker.plantOperations.operationStart">{{offtaker.plantOperationsInreview.operationStart}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.operationStart!=offtaker.plantOperations.operationStart">{{offtaker.plantOperationsInreview.operationStart}}</div>
                       
            </div>

            <div class="left textLabel">
                Name of LTSA Contractor <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.nameOfLTSAContractor==offtaker.plantOperations.nameOfLTSAContractor">{{offtaker.plantOperationsInreview.nameOfLTSAContractor}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.nameOfLTSAContractor!=offtaker.plantOperations.nameOfLTSAContractor">{{offtaker.plantOperationsInreview.nameOfLTSAContractor}}</div>
            </div>
            <div class="right textLabel">
                Plant Inspections <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.plantInspections==offtaker.plantOperations.plantInspections">{{offtaker.plantOperationsInreview.plantInspections}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.plantInspections!=offtaker.plantOperations.plantInspections">{{offtaker.plantOperationsInreview.plantInspections}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                CMMS Computerized Mtce Mgr<br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.cmmsComputerizedMtceMgr==offtaker.plantOperations.cmmsComputerizedMtceMgr">{{offtaker.plantOperationsInreview.cmmsComputerizedMtceMgr}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.cmmsComputerizedMtceMgr!=offtaker.plantOperations.cmmsComputerizedMtceMgr">{{offtaker.plantOperationsInreview.cmmsComputerizedMtceMgr}}</div>
            </div>
            <div class="left textLabel">
                Plant Availability Capacity <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.plantAvailabilityCapacity==offtaker.plantOperations.plantAvailabilityCapacity">{{offtaker.plantOperationsInreview.plantAvailabilityCapacity}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.plantAvailabilityCapacity!=offtaker.plantOperations.plantAvailabilityCapacity">{{offtaker.plantOperationsInreview.plantAvailabilityCapacity}}</div>
            </div>
       
            <div class="left textLabel">
                Unit Efficiency (%) <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.unitEfficiency==offtaker.plantOperations.unitEfficiency">{{offtaker.plantOperationsInreview.unitEfficiency}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.unitEfficiency!=offtaker.plantOperations.unitEfficiency">{{offtaker.plantOperationsInreview.unitEfficiency}}</div>
            </div>
            <div class="left textLabel">
                Reactive Power Requirement (MW) <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.reactivePowerRequirement==offtaker.plantOperations.reactivePowerRequirement">{{offtaker.plantOperationsInreview.reactivePowerRequirement}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.reactivePowerRequirement!=offtaker.plantOperations.reactivePowerRequirement">{{offtaker.plantOperationsInreview.reactivePowerRequirement}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Short Circuit Ratio <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.shortCircuitRatio==offtaker.plantOperations.shortCircuitRatio">{{offtaker.plantOperationsInreview.shortCircuitRatio}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.shortCircuitRatio!=offtaker.plantOperations.shortCircuitRatio">{{offtaker.plantOperationsInreview.shortCircuitRatio}}</div>
            </div>
            <div class="right textLabel">
                Auxiliary Power Requirement (MW) <br /> 
                <div class="green" *ngIf="offtaker.plantOperationsInreview.auxilliaryPowerRequirement==offtaker.plantOperations.auxilliaryPowerRequirement">{{offtaker.plantOperationsInreview.auxilliaryPowerRequirement}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.auxilliaryPowerRequirement!=offtaker.plantOperations.auxilliaryPowerRequirement">{{offtaker.plantOperationsInreview.auxilliaryPowerRequirement}}</div>
            </div>
       
            <div class="left textLabel">
                Transformer Rated Capacity (MVA) <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.trasnformerRatedCapacity==offtaker.plantOperations.trasnformerRatedCapacity">{{offtaker.plantOperationsInreview.trasnformerRatedCapacity}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.trasnformerRatedCapacity!=offtaker.plantOperations.trasnformerRatedCapacity">{{offtaker.plantOperationsInreview.trasnformerRatedCapacity}}</div>
            </div>
            <div class="left textLabel">
                Transformer Voltage Load Ratio(HV/LV) <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.transformerVoltageLoadRatio==offtaker.plantOperations.transformerVoltageLoadRatio">{{offtaker.plantOperationsInreview.transformerVoltageLoadRatio}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.transformerVoltageLoadRatio!=offtaker.plantOperations.transformerVoltageLoadRatio">{{offtaker.plantOperationsInreview.transformerVoltageLoadRatio}}</div>
            </div>
        </div>
        <div class="row">
            <div class="left textLabel">
                Plant Pressure (PSI) <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.plantPressure==offtaker.plantOperations.plantPressure">{{offtaker.plantOperationsInreview.plantPressure}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.plantPressure!=offtaker.plantOperations.plantPressure">{{offtaker.plantOperationsInreview.plantPressure}}</div>
            </div>
            <div class="right textLabel">
                Plant Temperature <br /> 
                <div class="green" *ngIf="offtaker.plantOperationsInreview.plantTemprature==offtaker.plantOperations.plantTemprature">{{offtaker.plantOperationsInreview.plantTemprature}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.plantTemprature!=offtaker.plantOperations.plantTemprature">{{offtaker.plantOperationsInreview.plantTemprature}}</div>
            </div>
        
            <div class="left textLabel">
                Plant Noise Level (dBA) <br />
                <div class="green" *ngIf="offtaker.plantOperationsInreview.plantNoiseLevel==offtaker.plantOperations.plantNoiseLevel">{{offtaker.plantOperationsInreview.plantNoiseLevel}}</div>
                <div class="red" *ngIf="offtaker.plantOperationsInreview.plantNoiseLevel!=offtaker.plantOperations.plantNoiseLevel">{{offtaker.plantOperationsInreview.plantNoiseLevel}}</div>
            </div>
       
        </div>
    </mat-expansion-panel>
    
    <mat-expansion-panel class="marginTop50px half" *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            COMMERCIAL OPERATIONS
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>        
        
        <div class="row margin">    
            <div class="left textLabel">
                Natural Gas Supplier <br />
                <div>{{offtaker.commercialOperations.naturalGasSupplier}}</div>
                
            </div>
            <div class="left textLabel">
                Gas Contract Quantity (MMscf) <br />
                <div>{{offtaker.commercialOperations.gasContractQuantity}}</div>
            </div>
            <div class="left textLabel">
                Average Daily Gas Consumption <br />
                <div>{{offtaker.commercialOperations.avgDailyGasConsumption}}</div>
            </div>
            <div class="right textLabel">
                Gas Transportation Agreement <br />
                <div>{{offtaker.commercialOperations.gasTransportationAgreement}}</div>
            </div>
        </div>
        <div class="row">    
            <div class="left textLabel">
                PPA for Power Expert <br />
                <div>{{offtaker.commercialOperations.ppaForPowerExport}}</div>
            </div>
            <div class="left textLabel">
                PPA Contract Capacity(MW) <br />
                <div>{{offtaker.commercialOperations.ppaContractCapacity}}</div>
            </div>
            <div class="left textLabel">
                PPA Customer <br />
                <div>{{offtaker.commercialOperations.ppaCustomer}}</div>
            </div>
            <div class="right textLabel">
                PPA Effective Date  <br />
                <div>{{offtaker.commercialOperations.ppaEffectiveDate}}</div>
                       
            </div>
        </div>
        <div class="row"> 
            <div class="left textLabel">
                PPA Term (Years) <br />
                <div>{{offtaker.commercialOperations.ppaTerm}}</div>
            </div>   
            <div class="left textLabel">
                PPA Securitized <br />
                <div>{{offtaker.commercialOperations.ppaSecuritized}}</div>
            </div>               
            <div class="left textLabel">
                Avg.Load Factor (%)  <br />
                <div>{{offtaker.commercialOperations.avgLoadFactor}}</div>
            </div>
            <div class="right textLabel">
               Avg Capacity Availability  <br />
                <div>{{offtaker.commercialOperations.avgCapacityAvailability}}</div>
            </div>
        </div>
        <div class="row"> 
            <div class="left textLabel">
                Average NEO (kw/hr) <br />
                <div>{{offtaker.commercialOperations.avgNEO}}</div>
            </div>   
            <div class="left textLabel">
                (CER) Certified Emission Reduce <br />
                <div>{{offtaker.commercialOperations.certifiedEmissionRatio}}</div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            COMMERCIAL OPERATIONS For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>        
        
        <div class="row margin">    
            <div class="left textLabel">
                Natural Gas Supplier <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.naturalGasSupplier==offtaker.commercialOperations.naturalGasSupplier">{{offtaker.commercialOperationsInreview.naturalGasSupplier}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.naturalGasSupplier!=offtaker.commercialOperations.naturalGasSupplier">{{offtaker.commercialOperationsInreview.naturalGasSupplier}}</div>
                
            </div>
            <div class="left textLabel">
                Gas Contract Quantity (MMscf) <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.gasContractQuantity==offtaker.commercialOperations.gasContractQuantity">{{offtaker.commercialOperationsInreview.gasContractQuantity}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.gasContractQuantity!=offtaker.commercialOperations.gasContractQuantity">{{offtaker.commercialOperationsInreview.gasContractQuantity}}</div>
            </div>
            <div class="left textLabel">
                Average Daily Gas Consumption <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.avgDailyGasConsumption==offtaker.commercialOperations.avgDailyGasConsumption">{{offtaker.commercialOperationsInreview.avgDailyGasConsumption}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.avgDailyGasConsumption!=offtaker.commercialOperations.avgDailyGasConsumption">{{offtaker.commercialOperationsInreview.avgDailyGasConsumption}}</div>
            </div>
            <div class="right textLabel">
                Gas Transportation Agreement <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.gasTransportationAgreement==offtaker.commercialOperations.gasTransportationAgreement">{{offtaker.commercialOperationsInreview.gasTransportationAgreement}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.gasTransportationAgreement!=offtaker.commercialOperations.gasTransportationAgreement">{{offtaker.commercialOperationsInreview.gasTransportationAgreement}}</div>
            </div>
        </div>
        <div class="row">    
            <div class="left textLabel">
                PPA for Power Expert <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.ppaForPowerExport==offtaker.commercialOperations.ppaForPowerExport">{{offtaker.commercialOperationsInreview.ppaForPowerExport}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.ppaForPowerExport!=offtaker.commercialOperations.ppaForPowerExport">{{offtaker.commercialOperationsInreview.ppaForPowerExport}}</div>
            </div>
            <div class="left textLabel">
                PPA Contract Capacity(MW) <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.ppaContractCapacity==offtaker.commercialOperations.ppaContractCapacity">{{offtaker.commercialOperationsInreview.ppaContractCapacity}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.ppaContractCapacity!=offtaker.commercialOperations.ppaContractCapacity">{{offtaker.commercialOperationsInreview.ppaContractCapacity}}</div>
            </div>
            <div class="left textLabel">
                PPA Customer <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.ppaCustomer==offtaker.commercialOperations.ppaCustomer">{{offtaker.commercialOperationsInreview.ppaCustomer}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.ppaCustomer!=offtaker.commercialOperations.ppaCustomer">{{offtaker.commercialOperationsInreview.ppaCustomer}}</div>
            </div>
            <div class="right textLabel">
                PPA Effective Date  <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.ppaEffectiveDate==offtaker.commercialOperations.ppaEffectiveDate">{{offtaker.commercialOperationsInreview.ppaEffectiveDate}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.ppaEffectiveDate!=offtaker.commercialOperations.ppaEffectiveDate">{{offtaker.commercialOperationsInreview.ppaEffectiveDate}}</div>
                       
            </div>
        </div>
        <div class="row"> 
            <div class="left textLabel">
                PPA Term (Years) <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.ppaTerm==offtaker.commercialOperations.ppaTerm">{{offtaker.commercialOperationsInreview.ppaTerm}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.ppaTerm!=offtaker.commercialOperations.ppaTerm">{{offtaker.commercialOperationsInreview.ppaTerm}}</div>
            </div>   
            <div class="left textLabel">
                PPA Securitized <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.ppaSecuritized==offtaker.commercialOperations.ppaSecuritized">{{offtaker.commercialOperationsInreview.ppaSecuritized}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.ppaSecuritized!=offtaker.commercialOperations.ppaSecuritized">{{offtaker.commercialOperationsInreview.ppaSecuritized}}</div>
            </div>               
            <div class="left textLabel">
                Avg.Load Factor (%)  <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.avgLoadFactor==offtaker.commercialOperations.avgLoadFactor">{{offtaker.commercialOperationsInreview.avgLoadFactor}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.avgLoadFactor!=offtaker.commercialOperations.avgLoadFactor">{{offtaker.commercialOperationsInreview.avgLoadFactor}}</div>
            </div>
            <div class="right textLabel">
               Avg Capacity Availability  <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.avgCapacityAvailability==offtaker.commercialOperations.avgCapacityAvailability">{{offtaker.commercialOperationsInreview.avgCapacityAvailability}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.avgCapacityAvailability!=offtaker.commercialOperations.avgCapacityAvailability">{{offtaker.commercialOperationsInreview.avgCapacityAvailability}}</div>
            </div>
        </div>
        <div class="row"> 
            <div class="left textLabel">
                Average NEO (kw/hr) <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.avgNEO==offtaker.commercialOperations.avgNEO">{{offtaker.commercialOperationsInreview.avgNEO}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.avgNEO!=offtaker.commercialOperations.avgNEO">{{offtaker.commercialOperationsInreview.avgNEO}}</div>
            </div>   
            <div class="left textLabel">
                (CER) Certified Emission Reduce <br />
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.certifiedEmissionRatio==offtaker.commercialOperations.certifiedEmissionRatio">{{offtaker.commercialOperationsInreview.certifiedEmissionRatio}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.certifiedEmissionRatio!=offtaker.commercialOperations.certifiedEmissionRatio">{{offtaker.commercialOperationsInreview.certifiedEmissionRatio}}</div>
            </div>               
            
        </div>
    </mat-expansion-panel>  
    <mat-expansion-panel class="marginTop50px half"  *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            Notes
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row"> 
            <div class="textLabel">
                <div>{{offtaker.commercialOperations.notes}}</div>
            </div>
        </div>
    </mat-expansion-panel>


    <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            Notes For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row"> 
            <div class="textLabel">
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.notes==offtaker.commercialOperations.notes">{{offtaker.commercialOperationsInreview.notes}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.notes!=offtaker.commercialOperations.notes">{{offtaker.commercialOperationsInreview.notes}}</div>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="marginTop50px half"  *ngIf="offtaker.basic.facilityName" [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            Profile
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row"> 
            <div class="textLabel">
                <div>{{offtaker.commercialOperations.profile}}</div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="marginTop50px half"  [expanded]=true>
        <mat-expansion-panel-header>
        <mat-panel-title>
            Profile For Review
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row"> 
            <div class="textLabel">
                <div class="green" *ngIf="offtaker.commercialOperationsInreview.profile==offtaker.commercialOperations.profile">{{offtaker.commercialOperationsInreview.profile}}</div>
                <div class="red" *ngIf="offtaker.commercialOperationsInreview.profile!=offtaker.commercialOperations.profile">{{offtaker.commercialOperationsInreview.profile}}</div>
            </div>
        </div>
    </mat-expansion-panel>
</div>
