<h1 id="title" class="title">Forgot Password</h1>
<p class="sub-title">Enter your email address and we’ll send a link to reset your password</p>


<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Enter your email address:</label>
    <input nbInput
           [(ngModel)]="user.email"
           #email="ngModel"
           id="input-email"
           name="email"
           pattern=".+@.+\..+"
           placeholder="Email address"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="true"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Email is required!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Email should be the real one!
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          class="appearance-filled full-width size-large shape-rectangle status-primary btn-disabled nb-transition" 
          [disabled]="submitted || !requestPassForm.valid">
    Request password
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Back to Log In</a></p>
</section>