import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Router } from "@angular/router";
import { NbToastrService, NbComponentStatus, NbGlobalPosition } from '@nebular/theme';
import { Observable } from 'rxjs';
import { user, UserRecordMetadata } from 'firebase-functions/lib/providers/auth';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth,
    private router: Router,
    private httpClient:HttpClient,
    private toastrService: NbToastrService
  ) { }

  async SignOut() {    
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('uid');
    await this.afAuth.signOut(); 
    this.router.navigate(['auth/login'])
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      this.showError('Password reset email sent, check your inbox.', 'green')
      return true;
    }).catch((error) => {
      if(error.message=='There is no user record corresponding to this identifier. The user may have been deleted.'){
        this.showError('Password reset email sent, check your inbox.', 'green')
      } else {
        this.showError(error.message)
        return error.message;
      }
    })
  }

  async SignIn(email, password) {
    let result;
    try{
      result= await this.afAuth.signInWithEmailAndPassword(email, password);
      //if(result.user.emailVerified){
      this.afs.collection("admins").doc(result.user.uid).get().subscribe(async (userRec)=>{
        if(userRec.exists){
          let userRecord:any = userRec.data();
          if(userRecord.isDeleted){
            await this.afAuth.signOut();
            this.showError("User dosen't exists!");
          } else {
            /*await result.user.updateProfile({
              displayName:userRecord.firstName
            })*/
            localStorage.setItem('role',userRecord.role);
            localStorage.setItem('user',JSON.stringify(userRecord));
            localStorage.setItem('uid',userRecord.uid);
            this.router.navigate(['/pages/dashboard']);
          }
          
        }else{
          await this.afAuth.signOut();
          this.showError("User dosen't exists!");
        }        
      });
      /*} else {
        await this.afAuth.signOut();
        this.showError("Email Address not verified");
        return 'Email Address not verified';
      }*/
    } catch (e){
      this.showError(e.message)
      return e.message;
    }
  }

  async checkAdmin(email, id?){
    let adminQuery;
    if(!id)
      adminQuery = await this.afs.collection('admins').ref.where('email','==',email).get();
    else
      adminQuery = await this.afs.collection('admins').ref.where(firebase.firestore.FieldPath.documentId(),'!=', id).where('email','==',email).get();
    
    if(adminQuery.empty){
      return true;
    } else {
      return false;
    }
    
  }

  async checkUser(email, id?){
    let userQuery;
    if(!id)
      userQuery = await this.afs.collection('users').ref.where('email','==',email).get();
    else
      userQuery = await this.afs.collection('users').ref.where(firebase.firestore.FieldPath.documentId(),'!=', id).where('email','==',email).get();
    
    if(userQuery.empty){
      return true;
    } else {
      return false;
    }
  }

  showError(msg,color?){
    if(color){
      this.toastrService.success(
        '',
        msg
      )
    } else {
      this.toastrService.danger(
        '',
        msg
      )
    }
  }

  async fetchAllFacilities(nonNull?){
    let facilitiesQuery;
    if(!nonNull)
      facilitiesQuery = await this.afs.collection('facilities',ref=>ref.orderBy('basic.facilityName')).get().toPromise();
    else 
      facilitiesQuery = await this.afs.collection('facilities',ref=>ref.where('basic.facilityName','!=', null).orderBy('basic.facilityName')).get().toPromise();

    if(!facilitiesQuery.empty){
      let facilities=[];
      facilitiesQuery.forEach((dat)=>{
        facilities.push(dat.data());
        facilities[facilities.length-1].id=dat.id;
      })
      return facilities;
    } else {
      return false;
    }
  }

  createAdmin(record):Observable<any>{
    let url = environment.apiUrl+'users';
    let data:any = record;
    return this.httpClient.post(url, data);
  }

  async fetchReviewFacilities(){
    let facilitiesQuery = await this.afs.collection('facilities',ref=>ref.where('inReview','==', 0)).get().toPromise();
    
    if(!facilitiesQuery.empty){
      let facilities=[];
      facilitiesQuery.forEach((dat)=>{
        facilities.push(dat.data());
        facilities[facilities.length-1].id=dat.id;
      })
      return facilities;
    } else {
      return false;
    }
  }

  async fetchFacility(id){
    let facilityQuery = await this.afs.collection('facilities').doc(id).get().toPromise();
    if(facilityQuery.exists){
      let facility;
      facility = facilityQuery.data();
      facility.id = facilityQuery.id;
      return facility;
    } else {
      return false;
    }
  }

  async saveFacility(record, isFile?){
    let val;
    if(!record.id){
      let facilities = await this.afs.collection('facilities').ref.where('facilityName', '==', record.basic.facilityName).where('type','==',record.type).where("country",'==',record.basic.country).get();
      if (facilities.empty) {
        val = await this.afs.collection('facilities').add(record);
        this.showError('Facility added successfully!', 'green')
        return val;
      } else {
        this.showError('Facility Name already exists in this country!')
        return false;
      }
    } else {
      let facilities = await this.afs.collection('facilities').ref.where(firebase.firestore.FieldPath.documentId(),'!=', record.id).where('facilityName', '==', record.basic.facilityName).where('type','==',record.type).where("country",'==',record.basic.country).get();
      if (facilities.empty) {
        val = await this.afs.doc(`facilities/${record.id}`).set(record,{merge:true});
        if(isFile){
          this.showError('File uploaded successfully!', 'green')
        } else {
          this.showError('Facility updated successfully!', 'green')
        }
        return val;
      } else {
        this.showError('Facility Name already exists in this country!')
        return false;
      }
    }
  }

  async saveFacilityForReview(record){
    let facilities = await this.afs.collection('facilities').ref.where(firebase.firestore.FieldPath.documentId(),'!=', record.id).where('facilityName', '==', record.basic.facilityName).where('type','==',record.type).where("country",'==',record.basic.country).get();
    if (facilities.empty) {
      await this.afs.doc(`facilities/${record.id}`).set(record);
      this.showError('Facility updated successfully!', 'green')
      return true;
    } else {
      this.showError('Facility Name already exists in this country!')
      return false;
    }
  }
  async daleteFacility(facilityId){
    await this.afs.collection('facilities').doc(facilityId).delete();
    this.showError('Facility removed!', 'green')
  }

  async fetchAllUsers(){
    let usersQuery = await this.afs.collection('users', ref=>ref).get().toPromise();
    
    if(!usersQuery.empty){
      let users = [];
      usersQuery.forEach((doc:any)=>{
        users.push(doc.data());
        users[users.length-1].id = doc.id;
      })
      return users;
    }
    return false;
  }

  async fetchAllAdmins(){
    let usersQuery = await this.afs.collection('admins', ref=>ref).get().toPromise();
    
    if(!usersQuery.empty){
      let users = [];
      usersQuery.forEach((doc:any)=>{
        users.push(doc.data());
        users[users.length-1].id = doc.id;
      })
      return users;
    }
    return false;
  }

  async updateAdmin(record, message?){
    await this.afs.collection('admins').doc(record.id).set(record,{merge:true});
    if(message){
      this.showError('Admin updated sucessfully!', 'green');
    }
    return true;
  }

  async updateUser(record){
    await this.afs.collection('users').doc(record.id).set(record,{merge:true});
    return true;
  }

  async fetchUser(uid){
    let userQuery = await this.afs.collection('users').doc(uid).get().toPromise();
    let user;
    if(userQuery.exists){
      user = userQuery.data();
      user.id = userQuery.id;
      return user
    }
  }

  async fetchAdmin(uid){
    let userQuery = await this.afs.collection('admins').doc(uid).get().toPromise();
    let user;
    if(userQuery.exists){
      user = userQuery.data();
      user.id = userQuery.id;
      return user
    }
  }
  

  fetchGrievances(uid):Observable<any>{
    return this.afs.collection('grievances', ref=>ref.where('forUser', '==', uid).orderBy('createdAt')).valueChanges({idField:'id'});
  }

  async saveGrievances(record){
    await this.afs.collection('grievances').add(record);
  }

  fetchAdminUsers(){

  }

  async fetchCountries(){
    let countryQuery = await this.afs.collection('countries', ref=>ref.limit(1)).get().toPromise();
    let countries=[];
    if(!countryQuery.empty){
      countryQuery.forEach((doc:any)=>{
        countries = doc.data().countries;
      })
    }
    return countries;
  }

  fetchNews(id?):Observable<any>{
    if(!id)
      return this.afs.collection('news', ref=>ref.orderBy('createdAt')).valueChanges({idField:'id'});
    else
      return this.afs.collection('news').doc(id).valueChanges({idField:'id'});
  }

  async saveNews(record){
    let newsQuery;
    if(!record.id){
      newsQuery = await this.afs.collection('news').ref.where('newsTitle','==', record.newsTitle).get();
    } else {
      newsQuery = await this.afs.collection('news').ref.where(firebase.firestore.FieldPath.documentId(),'!=', record.id).where('newsTitle','==', record.newsTitle).get()
    }
    if(newsQuery.empty){
      if(!record.id){
        await this.afs.collection('news').add(record);
        this.showError('News added successfully!', 'green')
        return true;
      } else {
        await this.afs.collection('news').doc(record.id).set(record, {merge:true});
        this.showError('News updated successfully!', 'green')
        return true;
      }
    } else {
      this.showError('News title already exists!')
      return false;
    }
  }

  fetchAdvts(id?):Observable<any>{
    if(!id)
      return this.afs.collection('advertisements', ref=>ref.orderBy('createdAt')).valueChanges({idField:'id'});
    else
      return this.afs.collection('advertisements').doc(id).valueChanges({idField:'id'});
  }

  async saveAdvts(record){
    if(!record.id){
      await this.afs.collection('advertisements').add(record);
      this.showError('Advertisement added successfully!', 'green')
      return true;
    } else {
      await this.afs.collection('advertisements').doc(record.id).set(record, {merge:true});
      this.showError('Advertisement updated successfully!', 'green')
      return true;
    }
    
  }

  fetchSubscriptionPlans(id?):Observable<any>{
    if(!id)
      return this.afs.collection('subscriptionPlans').valueChanges({idField:'id'});
    else
      return this.afs.collection('subscriptionPlans').doc(id).valueChanges({idField:'id'});
  }

  fetchCms(id?):Observable<any>{
    if(id){
      return this.afs.collection('cms').doc(id).valueChanges({idField:'id'})
    } else {
      return this.afs.collection('cms').valueChanges({idField:'id'});
    }
  }

  fetchLookups(id?, type?):Observable<any>{
    if(id){
      return this.afs.collection('lookups').doc(id).valueChanges({idField:'id'})
    } else if(type){
      return this.afs.collection('lookups', ref=> ref.where('type','in',type)).valueChanges({idField:'id'});
    } else {
      return this.afs.collection('lookups').valueChanges({idField:'id'});
    }
  }

  async saveCms(record){
    await this.afs.collection('cms').doc(record.id).set(record,{merge:true});
    this.showError('CMS Content updated successfully!', 'green');
  }

  async saveLookup(record){
    await this.afs.collection('lookups').doc(record.id).set(record,{merge:true});
    this.showError('Lookup Content updated successfully!', 'green');
  }

  createSubscriptionPlanStripe(record){
    let url = environment.apiUrl+'createproduct';
    let data:any = record;
    return this.httpClient.post(url, data);
  }

  async saveSubscriptionPlanInfo(record){
    if(record.id){
      await this.afs.collection('subscriptionPlans').doc(record.id).set(record, {merge:true});
    } else {
      await this.afs.collection('subscriptionPlans').add(record);
    }
  }

  async fetchSubscriptionConfig(){
    let configQuery = await this.afs.collection('config').ref.where('type','==', 'trialPeriod').get();
    let config:any;
    if(!configQuery.empty){      
      configQuery.forEach(doc=>{
        config = doc.data();
        config.id=doc.id;        
      })
      return config;
    } else {
      return false;
    }
  }

  async updateConfig(record){
    await this.afs.collection('config').doc(record.id).set(record,{merge:true});
    this.showError("Config updated successfully!", 'green')
    return true;
  }
}


